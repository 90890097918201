import { ATTENDANCE_REQUEST, ATTENDANCE_IMPORTED, ATTENDANCE_ERR,
         PROGRESS_LOAD_START, PROGRESS_LOAD_COMPLETE, PROGRESS_LOAD_ERR } from '../../actions/actionTypes';
import * as progress from '../../api/courses/progress';
import stateConfig from '../../config/state';
import errors from '../../config/errors';

const transcriptsKey = stateConfig.keys.PROGRESS_TRANSCRIPTS;
const asssessmentsKey = stateConfig.keys.PROGRESS_ASSESSMENTS;

export function importCourseAttendance(slug, lectureId, importSlug, fileData) {
  return async dispatch => {
    dispatch({ type: ATTENDANCE_REQUEST });
    try {
      let result = await progress.importCourseAttendance(slug, lectureId, importSlug, fileData);
      if(result.data.success) {
        dispatch({ type: ATTENDANCE_IMPORTED, data: result.data });
      } else {
        dispatch({ type: ATTENDANCE_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: ATTENDANCE_ERR, error: err });
    }
  };
}

export function getCourseTranscripts(email) {
  return async dispatch => {
    const objectId = `${email}.all`;
    dispatch({ type: PROGRESS_LOAD_START, key: transcriptsKey, objectId });
    try {
      let result = await progress.getCourseTranscripts(email);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: transcriptsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      if(err.code === errors.ResourceNotFoundError.code) {
        dispatch({ type: PROGRESS_LOAD_COMPLETE, data: { transcripts: [] }, key: transcriptsKey, objectId });
      } else {
        dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: transcriptsKey, objectId });
      }
    }
  };
}

export function updateTranscriptStatus(data) {
  return async dispatch => {
    try {
      let result = await progress.updateTranscriptStatus(data);
      if(result.data.success) {
        return true;
      } else {
        throw new Error('Malformed result')
      }
    } catch(error) {
      throw Error(`Transcript update failed: ${error.message}`);
    }
  };
}

export function getProgressSummary(email) {
  return async dispatch => {
    const objectId = `${email}.progress`;
    dispatch({ type: PROGRESS_LOAD_START, key: transcriptsKey, objectId });
    try {
      let result = await progress.getProgressSummary(email);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: transcriptsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: transcriptsKey, objectId });
    }
  };
}

export function getCourseProgress(email, courseId) {
  return async dispatch => {
    const objectId = `${email}.${courseId}`;
    dispatch({ type: PROGRESS_LOAD_START, key: transcriptsKey, objectId });
    try {
      let result = await progress.getCourseProgress(email, courseId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: transcriptsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: transcriptsKey, objectId });
    }
  };
}

export function getAssessmentSummary(email, courseId) {
  return async dispatch => {
    const objectId = `${email}.${courseId}`;
    dispatch({ type: PROGRESS_LOAD_START, key: asssessmentsKey, objectId });
    try {
      let result = await progress.getAssessmentSummary(email, courseId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: asssessmentsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: asssessmentsKey, objectId });
    }
  };
}

export function updateCourseProgress(data) {
  return async dispatch => {
    try {
      let result = await progress.updateCourseProgress(data);
      if(result.data.success) {
        return true;
      } else {
        throw new Error('Malformed result')
      }
    } catch(error) {
      throw Error(`Progress update failed: ${error.message}`);
    }
  };
}

export function resetCourseProgress(data) {
  return async dispatch => {
    try {
      let result = await progress.resetCourseProgress(data);
      if(result.data.success) {
        return true;
      } else {
        throw new Error('Malformed result')
      }
    } catch(error) {
      throw Error(`Progress reset failed: ${error.message}`);
    }
  };
}

export function deleteCourseProgress(data) {
  return async dispatch => {
    try {
      let result = await progress.deleteCourseProgress(data);
      if(result.data.success) {
        return true;
      } else {
        throw new Error('Malformed result')
      }
    } catch(error) {
      throw Error(`Progress deletion failed: ${error.message}`);
    }
  };
}
