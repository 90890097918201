import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;


export async function getMember(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile/find`, { email });
}

export async function memberSearch(type, values) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile/search`, { type, values });
}

export async function registerMember(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/registration`, data);
}

export async function activateMember(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/registration/activate`, { email });
}

export async function updatePassword(email, password) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile/password`, { email, password });
}

export async function updateEmail(oldEmail, newEmail, merge) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile/email`, { oldEmail, newEmail, merge });
}

export async function getDonationHistory(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/donations`, { email });
}

export async function notateDonation(email, donationId, amount, note) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/donations/${donationId}/note`, { email, amount, note });
}

export async function updateProfile(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile`, data);
}

export async function deleteMember(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/member/profile/delete`, { email });
}