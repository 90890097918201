import { JOB_REQUEST, JOB_ERR, JOB_STATUS_RETRIEVED, JOB_DATA_RETRIEVED, EXPORT_REQUEST, 
         EXPORT_STARTED, EXPORT_ERR, EXPORT_STATUS_REQUEST, EXPORT_STATUS_RETRIEVED, 
         EXPORT_STATUS_ERR } from '../actions/actionTypes';
import * as jobs from '../api/jobs';
import errors from '../config/errors';
import stateConfig from '../config/state';

const exportKey = stateConfig.keys.EXPORT;
const jobsKey = stateConfig.keys.JOBS;

export function checkJobStatus(jobId) {
  return async dispatch => {
    dispatch({ type: JOB_REQUEST, key: jobsKey, objectId: jobId });
    try {
      let result = await jobs.checkJobStatus(jobId);
      if(result.data.jobId) {
        dispatch({ type: JOB_STATUS_RETRIEVED, key: jobsKey, objectId: jobId, data: result.data });
      } else {
        dispatch({ type: JOB_ERR, key: jobsKey, objectId: jobId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: JOB_ERR, key: jobsKey, objectId: jobId, error: errors.RequestFailed });
    }
  };
}

export function retrieveJobData(jobId) {
  return async dispatch => {
    dispatch({ type: JOB_REQUEST, key: jobsKey, objectId: jobId });
    try {
      let result = await jobs.retrieveJobData(jobId);
      if(result.data.jobId) {
        dispatch({ type: JOB_DATA_RETRIEVED, key: jobsKey, objectId: jobId, data: result.data });
      } else {
        dispatch({ type: JOB_ERR, key: jobsKey, objectId: jobId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: JOB_ERR, key: jobsKey, objectId: jobId, error: errors.RequestFailed });
    }
  };
}

export function checkExportStatus(jobId) {
  return async dispatch => {
    dispatch({ type: EXPORT_STATUS_REQUEST, key: exportKey, objectId: jobId });
    try {
      let result = await jobs.checkExportStatus(jobId);
      if(result.data.jobId) {
        dispatch({ type: EXPORT_STATUS_RETRIEVED, key: exportKey, objectId: jobId, data: result.data });
      } else {
        dispatch({ type: EXPORT_STATUS_ERR, key: exportKey, objectId: jobId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: EXPORT_STATUS_ERR, key: exportKey, objectId: jobId, error: errors.RequestFailed });
    }
  };
}

export function startCourseProgressExport(courseFilter, statusFilter, trackFilter) {
  const objectId = 'courseProgress';
  return async dispatch => {
    dispatch({ type: EXPORT_REQUEST, key: exportKey, objectId });
    try {
      let result = await jobs.startCourseProgressExport(courseFilter, statusFilter, trackFilter);
      if(result.data.jobId) {
        dispatch({ type: EXPORT_STARTED, key: exportKey, objectId, data: result.data });
      } else {
        dispatch({ type: EXPORT_ERR, key: exportKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: EXPORT_ERR, key: exportKey, objectId, error: errors.RequestFailed });
    }
  };
}

export function startStudentTranscriptExport() {
  const objectId = 'courseTranscripts';
  return async dispatch => {
    dispatch({ type: EXPORT_REQUEST, key: exportKey, objectId });
    try {
      let result = await jobs.startStudentTranscriptExport();
      if(result.data.jobId) {
        dispatch({ type: EXPORT_STARTED, key: exportKey, objectId, data: result.data });
      } else {
        dispatch({ type: EXPORT_ERR, key: exportKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: EXPORT_ERR, key: exportKey, objectId, error: errors.RequestFailed });
    }
  };
}
