import React, { useState } from 'react';
import MemberSearch from '../../common/member/MemberSearch';
import CourseSelection from './CourseSelection';
import CompleteRegistration from './CompleteRegistration';
import RegistrationDisplay from './RegistrationDisplay';
import validator from '../../../utils/validator';


function CourseRegistration() {
  const [profile, setProfile] = useState(null);
  const [course, setCourse] = useState(null);
  const [result, setResult] = useState(null);

  const changeMember = () => {
    setProfile(null);
  };

  const changeCourse = () => {
    setCourse(null);
  };

  const changeTrack = () => {
    const { track } = course;
    const cObj = Object.assign({}, course, { track: track === 'audit'? 'certificate' : 'audit' });
    setCourse(cObj);
  };

  const onSearchComplete = profile => {
    setProfile(profile);
  };

  const onCourseSelection = course => {
    setCourse(course);
  };

  const onRegistrationComplete = data => {
    setResult(data);
  };

  return (
    <div className="CourseRegistration">
      { !validator.isDefined(profile) &&
        <MemberSearch onComplete={onSearchComplete} />
      }
      { validator.isDefined(profile) && !validator.isDefined(course) &&
        <CourseSelection profile={profile} changeMember={changeMember} 
          onComplete={onCourseSelection} />
      }
      { validator.isDefined(profile) && validator.isDefined(course) && !validator.isDefined(result) &&
        <CompleteRegistration profile={profile} course={course}  
          changeMember={changeMember} changeCourse={changeCourse} 
          changeTrack={changeTrack} onComplete={onRegistrationComplete} />
      }
      { validator.isDefined(profile) && validator.isDefined(course) && validator.isDefined(result) &&
        <RegistrationDisplay result={result} />
      }
    </div>
  );
}

export default CourseRegistration;