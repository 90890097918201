import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


function UpdateEmail({ profile: { email }, error, onSubmit, onCancel }) {
  const [newEmail, setNewEmail] = useState('');
  const [submission, setSubmission] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if(error && submission) {
      setSubmission(false);
    }
  }, [error, submission]);

  const changeEmail = event => {
    event.preventDefault();
    setSubmission(true);
    onSubmit({ email, newEmail, merge: false });
  };

  const onFieldChange = ({ target: { value } }) => {
    setNewEmail(value.trim());
    setDisabled(!isValid(value.trim()));
  };

  const isValid = value => {
    const validationErrors = validator.validate({ email: value }, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: false }
    ]);
    return validationErrors? false : true;
  };

  return (
    <div className="UpdateEmail">
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateEmail-form' onSubmit={changeEmail} onClickSecondary={onCancel}
          submitLabel="Update Email" submission={submission} autoComplete="off" disabled={disabled}>
          <FormField fieldName="newEmail" label="New Email">
            <Input name="newEmail" value={newEmail} onChange={onFieldChange} />
          </FormField>
        </Form>
      </FormWrapper>
    </div>
  );
}

UpdateEmail.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateEmail;
