import { COURSE_LOAD_START, COURSE_LOAD_COMPLETE, COURSE_LOAD_ERR } from '../actionTypes';
import * as courses from '../../api/content/courses';
import stateConfig from '../../config/state';

const courseListKey = stateConfig.keys.COURSE_LIST;
const courseMapsKey = stateConfig.keys.COURSE_MAPS;

export function getCourses() {
  return async dispatch => {
    const objectId = 'summary';
    dispatch({ type: COURSE_LOAD_START, key: courseListKey, objectId });
    try {
      let result = await courses.getCourses();
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: courseListKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: courseListKey, objectId });
    }
  };
}

export function getCourseById(courseId) {
  return async dispatch => {
    dispatch({ type: COURSE_LOAD_START, key: courseListKey, objectId: courseId });
    try {
      let result = await courses.getCourseById(courseId);
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: courseListKey, objectId: courseId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: courseListKey, objectId: courseId });
    }
  };
}

export function getCourseMaps() {
  return async dispatch => {
    const objectId = 'all';
    dispatch({ type: COURSE_LOAD_START, key: courseMapsKey, objectId });
    try {
      let result = await courses.getCourseMaps();
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: courseMapsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: courseMapsKey, objectId });
    }
  };
}