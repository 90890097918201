import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './App.scss';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Header from './components/layout/Header';
import PageMessage from './components/layout/PageMessage';
import Theme from './styles/Theme';
import UserContext from './context/UserContext';
import PrivateRoute from './components/route/PrivateRoute';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/authentication/Login';
import CacheClear from './components/content/CacheClear';
import ManageMarketingTags from './components/content/ManageMarketingTags';
import CourseMaps from './components/course/maps/CourseMaps';
import CourseRegistration from './components/course/registration/CourseRegistration';
import StudentTranscript from './components/course/transcript/StudentTranscript';
import MagdalaRegistration from './components/magdala/MagdalaRegistration';
import CommunityRegistration from './components/magdala/CommunityRegistration';
import ManageCommunity from './components/magdala/ManageCommunity';
import ManageMember from './components/member/ManageMember';
import MemberDonations from './components/member/MemberDonations';
import MemberRegistration from './components/member/MemberRegistration';
import ManageEmailCategories from './components/notifications/ManageEmailCategories';
import SendNotification from './components/notifications/SendNotification';
import DownloadReport from './components/reports/DownloadReport';
import ReportSelection from './components/reports/ReportSelection';
import CourseProgressReport from './components/reports/course/CourseProgressReport';
import StudentTranscriptReport from './components/reports/course/StudentTranscriptReport';
import Monitor from './components/Monitor';
import NotFound from './components/NotFound';
import urls from './config/urls';


class App extends Component {
  render() {
    return (
      <div className="App">
        <UserContext.Provider value={this.props.user}>
          <MuiThemeProvider theme={Theme}>
            <Header />
            <div id="HeaderOffset"></div>
            <PageMessage />
            <main>
              <Switch>
                <Redirect exact path={urls.root} to={urls.login} />
                <Route exact path={urls.login} component={Login} />
                <PrivateRoute exact path={urls.dashboard} component={Dashboard} />
                {/* Course */}
                <PrivateRoute exact path={urls.courseMaps} component={CourseMaps} />
                <PrivateRoute exact path={urls.courseRegistration} component={CourseRegistration} />
                <PrivateRoute exact path={urls.studentTranscript} component={StudentTranscript} />
                {/* Magdala */}
                <PrivateRoute exact path={urls.magdalaRegistration} component={MagdalaRegistration} />
                <PrivateRoute exact path={urls.magdalaCommunityRegistration} component={CommunityRegistration} />
                <PrivateRoute exact path={urls.magdalaCommunityManage} component={ManageCommunity} />
                {/* Member */}
                <PrivateRoute exact path={urls.memberManage} component={ManageMember} />
                <PrivateRoute exact path={urls.memberDonations} component={MemberDonations} />
                <PrivateRoute exact path={urls.memberRegistration} component={MemberRegistration} />
                {/* Content Management */}
                <PrivateRoute exact path={urls.cacheClear} component={CacheClear} />
                <PrivateRoute exact path={urls.manageMarketingTags} component={ManageMarketingTags} />
                {/* Notifications */}
                <PrivateRoute exact path={urls.manageEmailCategories} component={ManageEmailCategories} />
                <PrivateRoute exact path={urls.sendNotifications} component={SendNotification} />
                {/* Reports */}
                <PrivateRoute exact path={urls.reportDownload} component={DownloadReport} />
                <PrivateRoute exact path={urls.courseReports} component={ReportSelection} />
                <PrivateRoute exact path={urls.courseProgressReport} component={CourseProgressReport} />
                <PrivateRoute exact path={urls.courseTranscriptReport} component={StudentTranscriptReport} />
                {/* Open - for monitoring availability */}
                <Route exact path={urls.monitor} component={Monitor} />
                {/* Fallback */}
                <Route component={NotFound} />
              </Switch>
            </main>
          </MuiThemeProvider>
        </UserContext.Provider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    user: state.user
  };
}

export default withRouter(connect(mapStateToProps)(App));
