import React from 'react';
import Icon, { IconTypes } from './common/Icon';


function NotFound(props) {
  return (
    <div id="NotFound">
      <div className="NotFound-aesthetic">
        <Icon type={IconTypes.Surprise} size="6x" />
        <div className="NotFound-aesthetic-content">
          <p className="NotFound-404">404</p>
          <p className="NotFound-message">Not Found</p>
        </div>
      </div>
      <p className="NotFound-descriptive">
        <strong>Uh Oh!</strong> The page you are looking for could not be located or has moved.
      </p>
    </div>
  );
}

export default NotFound;