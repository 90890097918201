import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import querystring from 'qs';
import * as profileAPI from '../../actions/profile';
import * as appDisplay from '../../actions/appDisplay';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import MemberSearch from '../common/member/MemberSearch';
import MemberDetails from './manage/MemberDetails';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';


class ManageMember extends React.Component {
  constructor(props) {
    super(props);

    const params = querystring.parse(this.props.location.search.substring(1));
    this.state = {
      loading: false,
      processing: false,
      newMember: params.newMember === 'true',
      email: params.email || '',
      refresh: false,
      profile: null,
      error: null
    };

    this.onBackSelection = this.onBackSelection.bind(this);
    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.refreshProfile = this.refreshProfile.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidMount() {
    const self = this;
    const { newMember, email } = this.state;

    if(newMember) {
      this.props.appDisplay.displayPageMessage({
        message: 'Registration Complete!',
        type: stateConfig.messageTypes.SUCCESS,
        time: 5
      });
    }

    if(validator.isNotEmpty(email)) {
      this.setState({ loading: true }, () => {
        self.props.profileAPI.getMember(email);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.profiles;
    const prevIds = prevProps.profiles.ids;
    const profile = byId[`${stateConfig.keys.PROFILES}.${this.state.email}`];

    if(ids !== prevIds && profile) {
      if(profile.error) {
        this.setState({ loading: false, error: profile.error });
      } else {
        this.setState({ loading: false, profile });
      }
    }
  }

  onBackSelection() {
    this.setState({ profile: null, email: '', refresh: false });
  }

  onSearchComplete(profile) {
    this.setState({ profile, email: profile.email });
  }

  refreshProfile(newEmail) {
    const self = this;
    const { email: prevEmail } = this.state.profile;
    const email = newEmail || prevEmail;
    this.setState({ loading: true, refresh: true, email, profile: null }, () => {
      self.props.profileAPI.getMember(email);
    });
  }

  clearPopMessage() {
    this.setState({ error: null });
  }

  render() {
    const { loading, processing, refresh, profile, error } = this.state;
    return (
      <div className="ManageMember">
        { loading && <LoadingMessage message='Loading...' /> }
        { processing && <LoadingMessage message='Processing...' /> }
        { !validator.isDefined(profile) && !refresh &&
          <MemberSearch onComplete={this.onSearchComplete} />
        }
        { validator.isDefined(profile) &&
          <MemberDetails profile={profile} onBack={this.onBackSelection} onUpdate={this.refreshProfile} />
        }
        { error !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>An error occurred while attempting to retrieve the requested member.</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: state.profiles
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMember);