import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import logo from '../../images/icc-logo.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import NavContainer from './header/NavContainer';
import urls from '../../config/urls';


function Header(props) {
  return (
    <AppBar position="fixed" className="AppHeader">
      <Toolbar className="AppHeader-toolbar">
        <Link to={urls.root} className="AppHeader-logo">
          <img src={logo} alt="Institute of Catholic Culture Logo" />
        </Link>
        <NavContainer />
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(Header);