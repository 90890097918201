import axios from 'axios';
import urls from '../../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getCourseTranscripts(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/transcripts`, { email });
}

export async function updateTranscriptStatus(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/transcript/status`, data);
}

export async function getProgressSummary(email) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress`, { email });
}

export async function getCourseProgress(email, courseId) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/${courseId}`, { email });
}

export async function getAssessmentSummary(email, courseId) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/${courseId}/assessments`, { email });
}

export async function updateCourseProgress(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/update`, data);
}

export async function resetCourseProgress(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/reset`, data);
}

export async function deleteCourseProgress(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/progress/delete`, data);
}

export async function importCourseAttendance(slug, lectureId, importSlug, fileData) {
  const config = { timeout: 300000, headers: { 'Content-Type': 'multipart/form-data' } }; // allow max time of 5m
  return await axios.post(`${urls.adminHubUrl}/ui/api/import/attendees/${slug}/${lectureId}/${importSlug}`, fileData, config);
}
