import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdateEnrolled extends React.Component {
  constructor(props) {
    super(props);
    const { semester, year } = props.community.firstEnrolled || {};
    this.state = {
      submission: false,
      fields: {
        semester: semester || '',
        year: year || ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_INTEGER, prop: 'year' }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateEnrolled-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

          <FormField fieldName="semester" label="Semester">
            <Select name="semester" value={fields['semester']} onChange={this.onFieldChange}>
              <MenuItem value="">Select the semester</MenuItem>
              <MenuItem value="Spring">Spring</MenuItem>
              <MenuItem value="Summer">Summer</MenuItem>
              <MenuItem value="Fall">Fall</MenuItem>
              <MenuItem value="Winter">Winter</MenuItem>
            </Select>
          </FormField>

          <FormField fieldName="year" label="Enrolled Year"
            error={validator.message(errors['year'], 'year')}>
            <Input name="year" placeholder='Enrolled Year' value={fields.year}
              onChange={this.onFieldChange} error={validator.isDefined(errors['year'])} />
          </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdateEnrolled.propTypes = {
  community: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateEnrolled;