import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as magdalaAPI from '../../../actions/magdala';
import * as appDisplay from '../../../actions/appDisplay';
import PopMessage from '../../common/messages/PopMessage';
import CommunitySelection from '../common/CommunitySelection';
import stateConfig from '../../../config/state';


class CompleteRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: false,
      stateId: `${stateConfig.keys.MAGDALA_REG}.${props.profile.email}`,
      registrationError: null
    };

    this.registerParticipant = this.registerParticipant.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.registrations;
    const prevIds = prevProps.registrations.ids;
    const registration = byId[this.state.stateId];

    if(ids !== prevIds && registration) {
      if(registration.error) {
        this.setState({ submission: false, registrationError: registration.error });
      } else if(registration.updated) {
        this.props.appDisplay.displayPageMessage({
          message: 'Registration Complete!',
          type: stateConfig.messageTypes.SUCCESS
        });
        this.setState({ completed: true });
        this.props.onComplete();
      }
    }
  }

  registerParticipant(communityId) {
    const { email } = this.props.profile;
    this.props.magdalaAPI.updateRegistration({ email, communityId });
  }

  clearPopMessage() {
    this.setState({ registrationError: null });
  }

  render() {
    const { completed, registrationError } = this.state;
    return (
      <div className="CompleteRegistration">
        { !completed &&
          <CommunitySelection 
            title={ this.props.profile.communityId? 'Update Registration' : 'Register Participant' } 
            submitLabel="Register" 
            onComplete={this.registerParticipant} 
          />
        }
        { registrationError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>The registration failed with the following message: {registrationError.code} - {registrationError.message}</p>
          </PopMessage>
        }
      </div>
    );
  }
}

CompleteRegistration.propTypes = {
  profile: PropTypes.object,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    registrations: state.magdalaRegistrations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRegistration);