import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import querystring from 'qs';
import * as magdalaAPI from '../../actions/magdala';
import * as appDisplay from '../../actions/appDisplay';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import CommunitySelection from './common/CommunitySelection';
import CommunityDetails from './manage/CommunityDetails';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';


class ManageCommunity extends React.Component {
  constructor(props) {
    super(props);

    const params = querystring.parse(this.props.location.search.substring(1));
    this.state = {
      loading: false,
      newCommunity: params.newCommunity === 'true',
      communityId: params.communityId || '',
      community: null,
      refresh: false,
      error: null
    };

    this.onBackSelection = this.onBackSelection.bind(this);
    this.onCommunitySelection = this.onCommunitySelection.bind(this);
    this.refreshCommunity = this.refreshCommunity.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidMount() {
    const self = this;
    const { newCommunity, communityId } = this.state;

    if(newCommunity) {
      this.props.appDisplay.displayPageMessage({
        message: 'Registration Complete!',
        type: stateConfig.messageTypes.SUCCESS,
        time: 5
      });
    }

    if(validator.isNotEmpty(communityId)) {
      this.setState({ loading: true }, () => {
        self.props.magdalaAPI.getCommunity(communityId);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.communities;
    const prevIds = prevProps.communities.ids;
    const community = byId[`${stateConfig.keys.MAGDALA_COMM}.${this.state.communityId}`];

    if(ids !== prevIds && community) {
      if(community.error) {
        this.setState({ loading: false, error: community.error });
      } else {
        this.setState({ loading: false, community });
      }
    }
  }

  onBackSelection() {
    this.setState({ communityId: '', community: null });
  }

  onCommunitySelection(communityId) {
    const self = this;
    this.setState({ communityId }, () => {
      self.props.magdalaAPI.getCommunity(communityId);
    });
  }

  refreshCommunity() {
    const self = this;
    this.setState({ loading: true, refresh: true, community: null }, () => {
      self.props.magdalaAPI.getCommunity(this.state.communityId);
    });
  }

  clearPopMessage() {
    this.setState({ error: null });
  }

  render() {
    const { loading, communityId, community, error } = this.state;
    return (
      <div className="ManageCommunity">
        { loading && <LoadingMessage message='Loading...' /> }
        { validator.isEmpty(communityId) &&
          <CommunitySelection 
            title="Select Community" 
            submitLabel="Continue" 
            onComplete={this.onCommunitySelection} 
          />
        }
        { community !== null &&
          <div className="CommunityDetails">
            <CommunityDetails community={community} onBack={this.onBackSelection} onUpdate={this.refreshCommunity} />
          </div>
        }
        { error !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>An error occurred while attempting to retrieve the requested community.</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    communities: state.magdalaCommunities
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCommunity);