import React, { useState } from 'react';
import MemberSearch from '../../common/member/MemberSearch';
import TranscriptContent from './TranscriptContent';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function StudentTranscript() {
  const [profile, setProfile] = useState(null);

  const onSearchComplete = profile => {
    setProfile(profile);
  };

  const changeMember = () => {
    setProfile(null);
  };

  return (
    <div className="StudentTranscript">
      { isNotDefined(profile) &&
        <MemberSearch onComplete={onSearchComplete} />
      }
      { isDefined(profile) &&
        <TranscriptContent profile={profile} onReset={changeMember} />
      }
    </div>
  );
}

export default StudentTranscript;