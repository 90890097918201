import { AUTH_START, AUTH_COMPLETE, AUTH_ERR, AUTH_STATUS_RECEIVED, AUTH_STATUS_ERR } from '../actions/actionTypes';
import * as auth from '../api/authentication';
import errors from '../config/errors';


export function pingAuthStatus() {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.pingAuthStatus();
      if(result.data.email) {
        dispatch({ type: AUTH_STATUS_RECEIVED, data: result.data });
      } else {
        dispatch({ type: AUTH_STATUS_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: AUTH_STATUS_ERR, error: errors.RequestFailed });
    }
  };
}

export function login(data) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.login(data);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_COMPLETE, data: result.data.user });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    } catch(error) {
      if(error.response && error.response.data) {
        if(error.response.data.code === errors.CredentialFailure.code) {
          dispatch({ type: AUTH_ERR, error: errors.CredentialFailure });
        } else {
          dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
        }
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    }
  };
}
