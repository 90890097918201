import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Icon, { IconTypes } from '../../common/Icon';
import NavMenu from '../menus/NavMenu';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


class NavContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.handleUserMenuOpen = this.handleUserMenuOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  handleUserMenuOpen() {
    this.setState({ isOpen: true });
  }

  onClose(event) {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ isOpen: false });
  }

  render() {
    const { data: { email, firstName, lastName } } = this.props.user;
    return (
      <div className="AppHeader-navigation">
        <div className="AppHeader-user">
          { !isEmpty(email) &&
            <Fragment>
              <div className="title">Hub Administration</div>
              <div className="user">{firstName} {lastName}</div>
            </Fragment>
          }
        </div>
        <div className="AppHeader-userNav">
          { !isEmpty(email) &&
            <div className="AppHeader-menuButton" onClick={this.handleUserMenuOpen}>
              <Icon type={IconTypes.UserCircle} />
            </div>
          }
        </div>
        
        <NavMenu isOpen={this.state.isOpen} onClose={this.onClose} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(NavContainer);