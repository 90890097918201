import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as registrationAPI from '../../../actions/courses/registration';
import * as appDisplay from '../../../actions/appDisplay';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import CheckField from '../../common/form/CheckField';
import PopMessage from '../../common/messages/PopMessage';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';


const initFields = {
  salutation: '',
  firstName: '',
  lastName: '',
  waitlisted: false
};

function CompleteRegistration({ course, profile, changeMember, changeCourse, changeTrack, onComplete, registrations, registrationAPI, appDisplay }) {
  const [init, setInit] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [stateId, setStateId] = useState(null);
  const [fields, setFields] = useState(initFields);
  const [registrationError, setRegistrationError] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if(!init) {
      const { email, salutation, firstName, lastName } = profile;
      setStateId(`${stateConfig.keys.COURSE_REG_NEW}.${course.slug}.${email}`);
      const fObj = { ...fields };
      fObj.salutation = salutation || '';
      fObj.firstName = firstName || '';
      fObj.lastName = lastName || '';
      setFields(fObj);
      setInit(true);
    }
  }, [init, fields, course.slug, profile]);

  useEffect(() => {
    if(submission && registrations.ids.includes(stateId)) {
      const data = registrations.byId[stateId];
      if(validator.isNotDefined(data) || data.error) {
        setRegistrationError(data.error);
        setSubmission(false);
      } else if(data.courseId) {
        appDisplay.displayPageMessage({
          message: 'Registration Complete!',
          type: stateConfig.messageTypes.SUCCESS
        });
        setCompleted(true);
        onComplete({ ...data, ...fields, email: profile.email });
      }
    }
  }, [submission, fields, stateId, profile, onComplete, registrations.ids, registrations.byId, appDisplay]);

  const onFieldChange = event => {
    let { name, value } = event.target;
    let fObj = { ...fields };
    fObj[name] = value;
    setFields(fObj);
  };

  const onCheckFieldChange = event => {
    let { name, checked } = event.target;
    let fObj = { ...fields };
    fObj[name] = checked;
    setFields(fObj);
  };

  const registerStudent = event => {
    event.preventDefault();
    if(!validate()) {
      return;
    }

    const { email } = profile;
    const { salutation, firstName, lastName, waitlisted } = fields;
    let data = { email, salutation, firstName, lastName };
    if(course.type === 'live') {
      data.waitlisted = waitlisted;
    }
    data = Object.assign(data, course);
    setSubmission(true);
    setFormErrors({});
    registrationAPI.createRegistration(course.type, data);
  }

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'firstName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'lastName' }
    ];
    
    const validationErrors = validator.validate(fields, rules);
    if(validationErrors) {
      setFormErrors(validationErrors);
      return false;
    }

    return true;
  }

  const clearPopMessage = () => {
    setRegistrationError(null);
  }

  return (
    <div className="CompleteCourseRegistration">
      { !completed &&
        <Fragment>
          <div className="current-selections">
            <p className="field">
              <span className="label">Selected Member:</span>
              <span className="value">
                {`${profile.firstName} ${profile.lastName}`}
                <Button variant="text" color="secondary" onClick={changeMember}>
                  Change Member
                </Button>
              </span>
            </p>
            <p className="field">
              <span className="label">Selected Course:</span>
              <span className="value">
                {course.title}
                <Button variant="text" color="secondary" onClick={changeCourse}>
                  Change Course
                </Button>
              </span>
            </p>
            <p className="field">
              <span className="label">Selected Track:</span>
              <span className="value">
                {`${course.track === 'audit'? 'Audit' : 'Certificate'}`}
                <Button variant="text" color="secondary" onClick={changeTrack}>
                  Change Track
                </Button>
              </span>
            </p>
          </div>
          <FormWrapper squared={true} raised={true}>
            <h2>Register Student</h2>
            <Form id='CourseRegistration-form' onSubmit={registerStudent} autoComplete="off"
              submitLabel="Register" submission={submission}>

              <FormField fieldName="salutation" label="Salutation"
                error={validator.message(formErrors['salutation'], 'salutation')}>
                <Input name="salutation" value={fields.salutation} onChange={onFieldChange}
                  error={validator.isDefined(formErrors['salutation'])} />
              </FormField>

              <FormField fieldName="firstName" label="First Name"
                error={validator.message(formErrors['firstName'], 'first name')}>
                <Input name="firstName" value={fields.firstName} onChange={onFieldChange}
                  error={validator.isDefined(formErrors['firstName'])} />
              </FormField>

              <FormField fieldName="lastName" label="Last Name"
                error={validator.message(formErrors['lastName'], 'last name')}>
                <Input name="lastName" value={fields.lastName} onChange={onFieldChange}
                  error={validator.isDefined(formErrors['lastName'])} />
              </FormField>

              { course.type === 'live' &&
                <div className="checkboxes">
                  <CheckField name="waitlisted" label="This registration should be waitlisted"
                    onChange={onCheckFieldChange} />
                </div>
              }

            </Form>
          </FormWrapper>
        </Fragment>
      }
      { validator.isDefined(registrationError) &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>
            { registrationError.duplicate? (
                <span>The selected member is already registered for this course.</span>
              ) : (
                <span>
                  The registration failed with the following message: {registrationError.code} - {registrationError.message}
                </span>
              )
            }
          </p>
        </PopMessage>
      }
    </div>
  );
}

CompleteRegistration.propTypes = {
  course: PropTypes.object,
  profile: PropTypes.object,
  changeMember: PropTypes.func,
  changeCourse: PropTypes.func,
  changeTrack: PropTypes.func,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    registrations: state.courseRegistrations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    registrationAPI: bindActionCreators(registrationAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRegistration);
