import { NOTIFICATION_REQUEST, NOTIFICATION_SENT, NOTIFICATION_EMAIL_CATG_REQUEST, NOTIFICATION_EMAIL_CATG_RETRIEVED, 
         NOTIFICATION_CATG_TAGS_REQUEST, NOTIFICATION_CATG_TAGS_RETRIEVED, NOTIFICATION_ERR, NOTIFICATION_EMAIL_CATG_UPDATED } from '../actions/actionTypes';
import * as notifications from '../api/notifications';
import errors from '../config/errors';


export function sendPushNotification(pushData) {
  return async dispatch => {
    dispatch({ type: NOTIFICATION_REQUEST });
    try {
      let result = await notifications.sendPushNotification(pushData);
      if(result.data.success) {
        dispatch({ type: NOTIFICATION_SENT, data: result.data });
      } else {
        dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function schedulePushNotification(pushData) {
  return async dispatch => {
    dispatch({ type: NOTIFICATION_REQUEST });
    try {
      let result = await notifications.schedulePushNotification(pushData);
      if(result.data.success) {
        dispatch({ type: NOTIFICATION_SENT });
      } else {
        dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function getEmailCategories() {
  return async dispatch => {
    dispatch({ type: NOTIFICATION_EMAIL_CATG_REQUEST });
    try {
      let result = await notifications.getEmailCategories();
      if(result.data.categories) {
        dispatch({ type: NOTIFICATION_EMAIL_CATG_RETRIEVED, data: result.data });
      } else {
        dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function updateEmailCategories(data) {
  return async dispatch => {
    dispatch({ type: NOTIFICATION_EMAIL_CATG_REQUEST });
    try {
      let result = await notifications.updateEmailCategories(data);
      if(result.data.success && result.data.categories) {
        dispatch({ type: NOTIFICATION_EMAIL_CATG_UPDATED, data: result.data });
      } else {
        dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function getEmailCategoryTags() {
  return async dispatch => {
    dispatch({ type: NOTIFICATION_CATG_TAGS_REQUEST });
    try {
      let result = await notifications.getEmailCategoryTags();
      if(result.data.tags) {
        dispatch({ type: NOTIFICATION_CATG_TAGS_RETRIEVED, data: result.data });
      } else {
        dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: NOTIFICATION_ERR, error: errors.RequestFailed });
    }
  };
}