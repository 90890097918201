import { PROFILE_UPDATE_REQUEST, PROFILE_UPDATED, PROFILE_UPDATE_ERR,
         PROFILE_DELETE_REQUEST, PROFILE_DELETED, PROFILE_DELETE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function profileUpdatesReducer(state = initialState.profileUpdates, action) {
  const updates = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.email}`;
  
  switch(action.type) {
    case PROFILE_UPDATE_REQUEST:
    case PROFILE_DELETE_REQUEST:
      clearStateById(updates.ids, updates.byId, stateId);
      return updates;
    case PROFILE_UPDATED:
      updates.ids.push(stateId);
      updates.byId[stateId] = action.data;
      return updates;
    case PROFILE_DELETED:
      updates.ids.push(stateId);
      updates.byId[stateId] = { deleted: true };
      return updates;
    case PROFILE_UPDATE_ERR:
    case PROFILE_DELETE_ERR:
      updates.ids.push(stateId);
      updates.byId[stateId] = { error: action.error };
      return updates;
    default:
      return state;
  }
}

export default profileUpdatesReducer;