import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import RecordAssessments from './RecordAssessments';
import * as progressAPI from '../../../actions/courses/progress';
import * as registrationAPI from '../../../actions/courses/registration';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import config from '../../../config/config';
import { formatDateTimeEST } from '../../../utils/format';
import { generateCertificatePage1, getImageData } from '../../../utils/pdf';
import validator from '../../../utils/validator';
const { isDefined } = validator;


function RecordGeneral({ record, profile, onUpdate, onDeletion, progressAPI, registrationAPI }) {
  const [popup, setPopup] = useState({});
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { email, firstName, lastName } = profile;
  const { slug, courseId, type, status, track, started, completed, lastUpdated, waitlisted, supportedTracks, certificate, details: { title } } = record;

  const updateCourseTrack = () => {
    const newTrack = track === 'certificate'? 'audit' : 'certificate';
    setProcessing(true);
    registrationAPI.updateRegistrationTrack({ email, slug, type, newTrack })
      .then(() => {
        setProcessing(false);
        onUpdate();
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const removeWaitlistingStatus = () => {
    setProcessing(true);
    registrationAPI.removeWaitlistingStatus({ email, slug })
      .then(() => {
        setProcessing(false);
        onUpdate();
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const downloadCertificate = async () => {
    const { page1, page2 } = certificate;
    const data1 = await getImageData(page1);
    const data2 = await getImageData(page2);
    const dt = formatDateTimeEST(completed);
    const dtArr = dt.split(' ');
    generateCertificatePage1({
      image1: data1,
      image2: data2,
      completionDate: dtArr[0],
      name: `${firstName} ${lastName}`,
      fileName: `${slug}-certificate`
    });
  };

  const removeCoursePrompt = () => {
    setPopup({ display: true, remove: true, fn: removeCourseRecord });
  };

  const removeCourseRecord = () => {
    setPopup({ display: false });
    setProcessing(true);
    progressAPI.deleteCourseProgress({ email, courseId, type, track, status })
      .then(() => {
        setProcessing(false);
        onDeletion();
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const archiveRecordPrompt = () => {
    setPopup({ display: true, archive: true, fn: updateTranscriptStatus('archived-recorded') });
  };

  const updateTranscriptStatus = newStatus => () => {
    setProcessing(true);
    progressAPI.updateTranscriptStatus({ email, courseId, status, newStatus })
      .then(() => {
        setProcessing(false);
        onUpdate();
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const closePopup = () => {
    setPopup({ display: false });
  };

  const clearPopMessage = () => {
    setError({ display: false });
  };

  return (
    <div className="TranscriptRecordDetails-general">
      <div className="field">
        <span>Course Name:</span>
        <p>{title}</p>
      </div>
      <div className="field">
        <span>Course Type:</span>
        <p>{type === 'live'? 'Live' : 'Self-Paced'} {track === 'audit'? 'Audit' : 'Certificate'} Course</p>
      </div>
      <div className="field">
        <span>Status:</span>
        <p>{waitlisted? 'Waitlisted Registration' : config.courseStatus[status]}</p>
      </div>
      { isDefined(started) &&
        <div className="field">
          <span>Started Date:</span>
          <p>{formatDateTimeEST(started)}</p>
        </div>
      }
      { isDefined(completed) &&
        <div className="field">
          <span>Completed Date:</span>
          <p>{formatDateTimeEST(completed)}</p>
        </div>
      }
      <div className="field">
        <span>Last Updated:</span>
        <p>{formatDateTimeEST(lastUpdated)}</p>
      </div>
      { track !== 'audit' &&
        <RecordAssessments record={record} profile={profile} />
      }
      <div className="actions">
        { config.courseNotComplete.includes(status) && supportedTracks === 'cert-audit' &&
          <Button variant="outlined" onClick={updateCourseTrack} color="primary">
            Change Track
          </Button>
        }
        { config.courseNotComplete.includes(status) && waitlisted &&
          <Button variant="outlined" onClick={removeWaitlistingStatus} color="primary">
            Remove Waitlisting
          </Button>
        }
        { config.courseNotComplete.includes(status) && !waitlisted &&
          <Button variant="outlined" onClick={updateTranscriptStatus('complete')} color="primary">
            Mark As Complete
          </Button>
        }
        { config.courseComplete.includes(status) &&
          <Button variant="outlined" onClick={downloadCertificate} color="primary">
            Download Certificate
          </Button>
        }
        { config.courseComplete.includes(status) && status !== config.courseRecordOnly &&
          <Button variant="outlined" onClick={updateTranscriptStatus('started')} color="primary">
            Mark As In Progress
          </Button>
        }
        { config.courseComplete.includes(status) && status !== config.courseRecordOnly &&
          <Button variant="outlined" onClick={archiveRecordPrompt} color="primary">
            Mark As Record Only
          </Button>
        }
        <Button variant="outlined" onClick={removeCoursePrompt} color="secondary">
          Delete Record
        </Button>
      </div>
      { processing &&
        <LoadingMessage message="Processing..." />
      }
      { isDefined(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>This request could not be processed.</p>
        </PopMessage>
      }
      { isDefined(popup) && popup.display &&
        <StandardSystemPopup 
          className="TranscriptRecordDetails-popup"
          open={popup.display} 
          title="Warning" 
          onClose={closePopup}
          actions={(
            <Fragment>
              <Button className="keep" variant="text" onClick={closePopup}>
                {popup.remove? 'Keep this course' : 'Cancel'}
              </Button>
              <Button className="remove" variant="text" color="secondary" onClick={popup.fn}>
                {popup.remove? 'Remove this course' : 'Continue'}
              </Button>
            </Fragment>
          )}
        >
          { popup.remove &&
            <Fragment>
              <p className="what">You are about to delete all course records for:</p>
              <p className="course-title">{title}</p>
              <p className="consequences">This action will remove all of the progress data for this course, and this action cannot be reversed.</p>
              <p className="sure">Are you sure you wish to remove this course record?</p>
            </Fragment>
          }
          { popup.archive &&
            <Fragment>
              <p className="what">You are about to archive the following course:</p>
              <p className="course-title">{title}</p>
              <p className="consequences">The transcript record will be retained, but the student will no longer be able to access the course content. This action cannot be reversed.</p>
              <p className="sure">Are you sure you wish to continue?</p>
            </Fragment>
          }
        </StandardSystemPopup>
      }
    </div>
  );
}

RecordGeneral.propTypes = {
  record: PropTypes.object,
  profile: PropTypes.object,
  onUpdate: PropTypes.func,
  onDeletion: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    progressAPI: bindActionCreators(progressAPI, dispatch),
    registrationAPI: bindActionCreators(registrationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordGeneral);
