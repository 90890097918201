import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export default createMuiTheme({
  palette: {
    primary: { main: '#3E4F7D' },
    secondary: { main: '#98272b' },
    default: { main: '#F9F4E8' },
    error: red
  },
  typography: {
    useNextVariants: true,
    fontSize: 16
  }
});
