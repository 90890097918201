import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdateName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      fields: {
        salutation: props.profile.salutation || '',
        firstName: props.profile.firstName || '',
        legalFirstName: props.profile.legalFirstName || '',
        lastName: props.profile.lastName || ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'legalFirstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fields, errors } = this.state;
    const { onCancel } = this.props;
    const { type } = this.props.profile;

    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateName-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

          <FormField fieldName="salutation" label="Salutation" 
            error={validator.message(errors['salutation'], 'salutation')}>
            <Input name="salutation" placeholder='Salutation' value={fields.salutation} 
              onChange={this.onFieldChange} error={validator.isDefined(errors['salutation'])} />
          </FormField>

          {(type === 'magdala-member') && 
          <div>
            <FormField fieldName="firstName" label="Religious Name" 
              error={validator.message(errors['firstName'], 'first name')}>
              <Input name="firstName" placeholder='First Name' value={fields.firstName}
                onChange={this.onFieldChange} error={validator.isDefined(errors['firstName'])} />
            </FormField>

            <FormField fieldName="legalFirstName" label="Legal First Name" 
              error={validator.message(errors['legalFirstName'], 'legal first name')}>
              <Input name="legalFirstName" placeholder='Legal First Name' value={fields.legalFirstName}
                onChange={this.onFieldChange} error={validator.isDefined(errors['legalFirstName'])} />
            </FormField>
          </div>
          }

          {(type !== 'magdala-member') &&
          <FormField fieldName="firstName" label="First Name" 
            error={validator.message(errors['firstName'], 'first name')}>
            <Input name="firstName" placeholder='First Name' value={fields.firstName}
              onChange={this.onFieldChange} error={validator.isDefined(errors['firstName'])} />
          </FormField> 
          }

          <FormField fieldName="lastName" label="Last Name" 
            error={validator.message(errors['lastName'], 'last name')}>
            <Input name="lastName" placeholder='Last Name' value={fields.lastName}
              onChange={this.onFieldChange} error={validator.isDefined(errors['lastName'])} />
          </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdateName.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateName;