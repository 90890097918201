import { MAGDALA_COMM_REQUEST, MAGDALA_RETRIEVED_COMM, MAGDALA_UPDATED_COMM,
         MAGDALA_COMM_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function magdalaCommunitiesReducer(state = initialState.magdalaCommunities, action) {
  const communities = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case MAGDALA_COMM_REQUEST:
      clearStateById(communities.ids, communities.byId, stateId);
      return communities;
    case MAGDALA_RETRIEVED_COMM:
    case MAGDALA_UPDATED_COMM:
      communities.ids.push(stateId);
      communities.byId[stateId] = action.data;
      return communities;
    case MAGDALA_COMM_ERR:
      communities.ids.push(stateId);
      communities.byId[stateId] = { error: action.error };
      return communities;
    default:
      return state;
  }
}

export default magdalaCommunitiesReducer;