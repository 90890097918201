import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as progressAPI from '../../../actions/courses/progress';
import PopMessage from '../../common/messages/PopMessage';
import LoadingMessage from '../../common/messages/LoadingMessage';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import Icon, { IconTypes } from '../../common/Icon';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function RecordProgress({ record, profile, onUpdate, courses, courseProgress, progressAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const [progress, setProgress] = useState(null);
  const [changes, setChanges] = useState([]);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(null);

  const { email } = profile;
  const { courseId } = record;

  useEffect(() => {
    if(!init) {
      progressAPI.getCourseProgress(email, courseId);
      setLoading(true);
      setInit(true);
    }
  }, [init, email, courseId, progressAPI]);

  useEffect(() => {
    if(loading && isNotDefined(course) && isNotDefined(progress)) {
      const progressId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.${email}.${courseId}`;
      const coursesId = `${stateConfig.keys.COURSE_LIST}.${courseId}`;
      let pData = courseProgress.ids.includes(progressId)? courseProgress.byId[progressId] : null;
      let cData = courses.ids.includes(coursesId)? courses.byId[coursesId] : null;
      if(pData && cData) {
        if(pData.error || cData.error) {
          setError({ display: true });
        } else {
          pData = pData.progress? pData.progress : [];
          setCourse(cData);
          setProgress(pData);
          setLoading(false);
        }
      }
    }
  }, [loading, email, courseId, course, progress, courseProgress.ids, courseProgress.byId, courses.ids, courses.byId]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  const resetProgressPrompt = () => {
    setReset(true);
  };

  const resetProgress = () => {
    setReset(false);
    progressAPI.resetCourseProgress({ email, courseId })
      .then(() => {
        onUpdate();
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const closePopup = () => {
    setReset(false);
  };

  const handleSelection = (part, status) => () => {
    const cArr = changes.slice();
    const cIndex = cArr.findIndex(item => item.id === part.id);
    if(cIndex === -1) {
      cArr.push({ id: part.id, type: part.type, action: status === 'complete'? 'delete' : 'complete' });
    } else {
      cArr.splice(cIndex, 1);
    }
    setChanges(cArr);
  };

  const updateProgress = () => {
    progressAPI.updateCourseProgress({ email, courseId, items: changes })
      .then(() => {
        onUpdate();
        setChanges([]);
      })
      .catch(() => {
        setError({ display: true });
      });
  };

  const renderLesson = lesson => {
    let started = false, complete = false, statuses = [];

    if(lesson.parts && lesson.parts.length > 0) {
      statuses = lesson.parts.map(part => {
        const notRequired = (part.type !== 'assessment' && part.required === false);
        const notApplicable = (part.track !== 'cert-audit' && record.track !== part.track);
        if(notRequired || notApplicable) {
          return 'ignore';
        }

        let match = progress.find(item => item.partId === part.id);
        if(match) {
          return match.status;
        }
        return '';
      });

      started = (statuses.includes('started') || statuses.includes('complete'));
      complete = statuses.map(status => (status === 'complete' || status === 'ignore'));
      complete = !complete.includes(false);
    }

    return (
      <Fragment>
        <div className="lesson-heading">
          { !started && !complete && <Icon type={IconTypes.NotStarted} /> }
          { started && !complete && <Icon type={IconTypes.Incomplete} /> }
          { complete && <Icon type={IconTypes.CompleteFilled} /> }
          <h4>{lesson.title}</h4>
        </div>
        <ul className="lesson-parts">
          {
            lesson.parts.map(part => {
              return (
                <li key={part.id}>{renderPart(part)}</li>
              );
            })
          }
        </ul>
      </Fragment>
    );
  };

  const renderPart = part => {
    const required = part.type === 'assessment' || part.required;
    if(!required || (part.track !== 'cert-audit' && record.track !== part.track)) {
      // this part doesn't pertain to the track the student is registered in
      return (<Fragment></Fragment>);
    }

    const partProgress = progress.find(item => item.partId === part.id);
    let status = partProgress? partProgress.status : 'not-started';
    let changed = changes.find(item => item.id === part.id);

    return (
      <Fragment>
        { status === 'started' && <Icon type={IconTypes.Incomplete} /> }
        { status === 'complete' && <Icon type={IconTypes.CompleteFilled} /> }
        { status !== 'started' && status !== 'complete' && <Icon type={IconTypes.NotStarted} /> }
        <span>
          <Button variant="text" onClick={handleSelection(part, status)} color="primary">
            {part.title}
          </Button>
          { isDefined(changed) && `(Changed, ${changed.action === 'complete'? 'Completed' : 'Removed'})`}
        </span>
      </Fragment>
    );
  };

  const renderExam = exam => {
    if(exam.track !== 'cert-audit' && record.track !== exam.track) {
      // this part doesn't pertain to the track the student is registered in
      return (<Fragment></Fragment>);
    }

    const partProgress = progress.find(item => item.partId === exam.id);
    let status = partProgress? partProgress.status : 'not-started';
    let changed = changes.find(item => item.id === exam.id);

    return (
      <Fragment>
        { status === 'started' && <Icon type={IconTypes.Incomplete} /> }
        { status === 'complete' && <Icon type={IconTypes.CompleteFilled} /> }
        { status !== 'started' && status !== 'complete' && <Icon type={IconTypes.NotStarted} /> }
        <span>
          <Button variant="text" onClick={handleSelection(exam, status)} color="primary">
            {exam.title}
          </Button>
          { isDefined(changed) && `(Changed, ${changed.action === 'complete'? 'Completed' : 'Removed'})`}
        </span>
      </Fragment>
    );
  };

  return (
    <div className="TranscriptRecordProgress">
      { loading && isNotDefined(error) &&
        <LoadingMessage message="Loading Progress..." />
      }
      { !loading && isDefined(error) &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load the course progress.</p>
        </PopMessage>
      }
      { !loading && isDefined(course) && isDefined(progress) &&
        <div className="TranscriptRecordProgress-details">
          <h3>Course Progress - {`${record.progress.percent}% (${record.progress.complete} of ${record.progress.steps} steps)`}</h3>
          <div className="outline">
            { isDefined(course.lessons) && course.lessons.length > 0 &&
              course.lessons.map(lesson => {
                return (
                  <div key={`lesson-${lesson.id}`} className="lesson">
                    {renderLesson(lesson)}
                  </div>
                );
              })
            }
            { isDefined(course.exams) && course.exams.length > 0 &&
              <div className="exams">
                {
                  course.exams.map(exam => {
                    return (
                      <div key={`exam-${exam.id}`} className="exam">
                        {renderExam(exam)}
                      </div>
                    );
                  })
                }
              </div>
            }
          </div>
          <div className="actions">
            { changes.length > 0 && 
              <div className="changes">
                {`${changes.length} Change${changes.length > 1? 's' : ''} queued for updating`}
              </div>
            }
            <div className="buttons">
              <Button variant="contained" onClick={updateProgress} color="primary" disabled={changes.length === 0}>
                Update
              </Button>
              { record.progress.complete !== 0 &&
                <Button variant="outlined" onClick={resetProgressPrompt} color="primary">
                  Reset Progress
                </Button>
              }
            </div>
          </div>
        </div>
      }
      { reset &&
        <StandardSystemPopup 
          className="TranscriptRecordProgress-popup"
          open={reset} 
          title="Warning" 
          onClose={closePopup}
          actions={(
            <Fragment>
              <Button className="keep" variant="text" onClick={closePopup}>
                Cancel
              </Button>
              <Button className="remove" variant="text" color="secondary" onClick={resetProgress}>
                Reset Progress
              </Button>
            </Fragment>
          )}
        >
          <p>This step will clear all of the progress for this course. Do you wish to continue?</p>
        </StandardSystemPopup>
      }
    </div>
  );
}

RecordProgress.propTypes = {
  record: PropTypes.object,
  profile: PropTypes.object,
  onUpdate: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordProgress);
