import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../common/form/FormWrapper';
import PopMessage from '../common/messages/PopMessage';
import JobStatusMessage from '../common/messages/JobStatusMessage';
import * as notificationsAPI from '../../actions/notifications';
import * as appDisplay from '../../actions/appDisplay';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';
import urls from '../../config/urls';
import config from '../../config/config';
import { truncate } from '../../utils/utils';


class SendNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        category: 'account',
        type: 'standard',
        title: '',
        message: '',
        eventSlug: '',
        linkURL: ''
      },
      messageLength: 0,
      errors: {},
      submission: false,
      sent: false,
      sendFailed: false,
      jobCompleted: false,
      jobId: null
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayJobResults = this.displayJobResults.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { notifications } = this.props;
    const { notifications: prevNotifications } = prevProps;

    if(notifications.responseStatus !== prevNotifications.responseStatus) {
      if(notifications.responseStatus === stateConfig.responseStatus.COMPLETE && notifications.jobId) {
        this.setState({ sent: true, jobId: notifications.jobId });
      } else if(notifications.responseStatus === stateConfig.responseStatus.ERROR) {
        this.setState({ submission: false, sendFailed: true });
      }
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target, { messageLength } = this.state;
    let fields = { ...this.state.fields };
    fields[name] = value;

    if(name === 'message') {
      messageLength = value.length;
    }
    
    this.setState({ fields, messageLength });
  }

  handleSubmit(event) {
    event.preventDefault();
    if(!this.validate()) {
      return;
    }
    
    const { fields } = this.state;
    const { category, type, title, message } = fields;
    let data = { category, type, title, message };
    switch(fields.type) {
      case 'event':
        data['eventSlug'] = fields.eventSlug;
        break;
      case 'link':
        data['linkURL'] = fields.linkURL;
        break;
      default: // no special fields
    }

    if(data.message.length > config.notificationMessageLimit) {
      data.message = truncate(data.message, config.notificationMessageLimit);
    }

    this.setState({ submission: true, errors: {}, sendFailed: false });
    this.props.notificationsAPI.sendPushNotification(data);
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'category' },
      { rule: validator.rules.IS_DEFINED, prop: 'type' },
      { rule: validator.rules.IS_DEFINED, prop: 'message' }
    ];

    switch(data.type) {
      case 'event':
        rules.push({ rule: validator.rules.MATCHES_PATTERN, prop: 'eventSlug', against: validator.patterns.SLUG, empty: true });
        rules.push({ rule: validator.rules.IS_DEFINED, prop: 'eventSlug' });
        break;
      case 'link':
        rules.push({ rule: validator.rules.MATCHES_PATTERN, prop: 'linkURL', against: validator.patterns.URL, empty: true });
        rules.push({ rule: validator.rules.IS_DEFINED, prop: 'linkURL' });
        break;
      default: // no special fields
    }
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  displayJobResults(result) {
    if(result.success) {
      this.props.appDisplay.displayPageMessage({
        message: `${result.data.progress.processed} push notifications processed. 100% complete.`,
        type: stateConfig.messageTypes.SUCCESS
      });
    } else {
      this.props.appDisplay.displayPageMessage({
        message: `The push notification job failed: ${result.message}`,
        type: stateConfig.messageTypes.ERROR
      });
    }
    this.setState({ jobCompleted: true });
  }

  clearPopMessage() {
    this.setState({ sendFailed: false });
  }

  render() {
    const { submission, fields, messageLength, errors, sent, sendFailed, jobId, jobCompleted } = this.state;
    const limit = config.notificationMessageLimit;

    return (
      <div className="SendNotifications">
        <div className="SendNotifications-content">
          { !sent &&
            <div id="SendNotificationsForm" className="panel">
              <FormWrapper squared={true} raised={true}>
                <h2>Send Push Notification</h2>
                <Form id='MemberRegistrationForm-form' onSubmit={this.handleSubmit} autoComplete="off"
                  submitLabel="Send" submission={submission}>

                  <FormField className="first" fieldName="category" label="Category">
                    <Select name="category" value={fields.category} onChange={this.onFieldChange}>
                      <MenuItem value="account">Account</MenuItem>
                      <MenuItem value="admin-test">Admin Test</MenuItem>
                      <MenuItem value="dev-test">Developer Test</MenuItem>
                    </Select>
                  </FormField>
                  { (fields.category === 'admin-test' || fields.category === 'dev-test') &&
                    <p className="category-help">
                      { fields.category === 'admin-test'? 
                        'This category is used to test messages with administrators (staff) only.' :
                        'This category is only used for developer testing/validation.'
                      }
                    </p>
                  }

                  <FormField fieldName="type" label="Type">
                    <Select name="type" value={fields.type} onChange={this.onFieldChange}>
                      <MenuItem value="standard">Standard Message</MenuItem>
                      <MenuItem value="event">Event</MenuItem>
                      <MenuItem value="link">Link</MenuItem>
                      <MenuItem value="donate">Donate</MenuItem>
                    </Select>
                  </FormField>

                  <FormField fieldName="title" label="Title"
                    error={validator.message(errors['title'], 'title')}>
                    <Input name="title" placeholder="Title" value={fields.title} onChange={this.onFieldChange}
                      error={validator.isDefined(errors['title'])} />
                  </FormField>

                  <p className="message-label">Message</p>
                  <FormField fieldName="message" 
                    error={validator.message(errors['message'], 'message')}>
                    <TextField name="message" placeholder="Message (plain text, no HTML)" value={fields.message} onChange={this.onFieldChange}
                      error={validator.isDefined(errors['message'])} multiline />
                  </FormField>
                  <p className={`message-length ${messageLength > limit? 'long' : 'normal'}`}>
                    Message length: {messageLength}. { messageLength > limit? `Limited to ${limit} characters.` : '' }
                  </p>

                  { fields.type === 'event' && 
                    <FormField fieldName="eventSlug" label="Event Slug"
                      error={validator.message(errors['eventSlug'], 'event slug')}>
                      <Input name="eventSlug" value={fields.eventSlug} onChange={this.onFieldChange}
                        placeholder="e.g., some-event-slug"
                        error={validator.isDefined(errors['eventSlug'])} />
                    </FormField>
                  }

                  { fields.type === 'link' && 
                    <FormField fieldName="linkURL" label="Link URL"
                      error={validator.message(errors['linkURL'], 'link url')}>
                      <Input name="linkURL" value={fields.linkURL} onChange={this.onFieldChange}
                        placeholder="e.g., https://instituteofcatholicculture.org/some-announcement"
                        error={validator.isDefined(errors['linkURL'])} />
                    </FormField>
                  }
                </Form>
              </FormWrapper>
            </div>
          }
          { sent && !jobCompleted &&
            <JobStatusMessage 
              jobId={jobId} 
              type="job"
              heading="Processing push notifications"
              onComplete={this.displayJobResults} 
            />
          }
          { jobCompleted &&
            <Link className="dashboard-link" to={urls.dashboard}>Return to the dashboard</Link>
          }
          { sendFailed &&
            <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
              type={stateConfig.messageTypes.ERROR}>
              <p>
                This push notification could not be sent. Please do not attempt to re-send a failed 
                notification without verifying the cause of the failure with the web administrator.
              </p>
            </PopMessage>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    notifications: state.notifications
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    notificationsAPI: bindActionCreators(notificationsAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendNotification));