import { MAGDALA_REG_REQUEST, MAGDALA_RETRIEVED_REG, MAGDALA_UPDATED_REG, 
         MAGDALA_REG_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function magdalaRegistrationsReducer(state = initialState.magdalaRegistrations, action) {
  const registrations = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.email}`;
  
  switch(action.type) {
    case MAGDALA_REG_REQUEST:
      clearStateById(registrations.ids, registrations.byId, stateId);
      return registrations;
    case MAGDALA_RETRIEVED_REG:
    case MAGDALA_UPDATED_REG:
      registrations.ids.push(stateId);
      registrations.byId[stateId] = action.data;
      return registrations;
    case MAGDALA_REG_ERR:
      registrations.ids.push(stateId);
      registrations.byId[stateId] = { error: action.error };
      return registrations;
    default:
      return state;
  }
}

export default magdalaRegistrationsReducer;