import { MAGDALA_COMM_UPDATE_REQUEST, MAGDALA_COMM_UPDATED, MAGDALA_COMM_UPDATE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function magdalaCommunityUpdatesReducer(state = initialState.magdalaCommunityUpdates, action) {
  const updates = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.communityId}`;
  
  switch(action.type) {
    case MAGDALA_COMM_UPDATE_REQUEST:
      clearStateById(updates.ids, updates.byId, stateId);
      return updates;
    case MAGDALA_COMM_UPDATED:
      updates.ids.push(stateId);
      updates.byId[stateId] = action.data;
      return updates;
    case MAGDALA_COMM_UPDATE_ERR:
      updates.ids.push(stateId);
      updates.byId[stateId] = { error: action.error };
      return updates;
    default:
      return state;
  }
}

export default magdalaCommunityUpdatesReducer;