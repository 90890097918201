import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdateField extends React.Component {
  constructor(props) {
    super(props);
    const prefilled = prefillFieldValues(props);
    this.state = {
      submission: false,
      fieldLabel: prefilled.fieldLabel || '',
      fields: {
        field: prefilled.field || ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      let data = {};
      data[this.props.field] = this.state.fields.field;
      self.props.onSubmit(data);
    });
  }

  validate() {
    if(!this.props.required) {
      return true;
    }

    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'field' }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fieldLabel, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateField-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

            <FormField fieldName="field" label={fieldLabel} 
              error={validator.message(errors['field'], fieldLabel.toLowerCase())}>
              <Input name="field" placeholder={fieldLabel} value={fields.field} 
                onChange={this.onFieldChange} error={validator.isDefined(errors['field'])} />
            </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

function prefillFieldValues(props) {
  const { field, community } = props; 
  const communityContact = community.communityContact || {}
  switch(field) {
    case 'communityName':
      return { field: community.communityName, fieldLabel: 'Community Name' };
    case 'superior':
      return { field: communityContact.superior, fieldLabel: 'Superior' };
    case 'formationDirectress':
      return { field: communityContact.formationDirectress, fieldLabel: 'Formation Directress' };
    default:
      return { field: '', fieldLabel: '' };
  }
}

UpdateField.propTypes = {
  field: PropTypes.string,
  required: PropTypes.bool,
  community: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateField;