import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../common/form/FormWrapper';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import urls from '../../config/urls';


function ReportSelection({ history, location }) {
  const [init, setInit] = useState(false);
  const [reports, setReports] = useState([]);
  const [report, setReport] = useState('default');

  useEffect(() => {
    if(!init) {
      const reportType = location.pathname.replace('/report/', '');
      const options = urls.reportOptions[reportType] || [];
      setReports(options);
      setInit(true);
    }
  }, [init, location]);

  const onFieldChange = ({ target: { value } }) => {
    setReport(value);
  };

  const onSubmit = event => {
    event.preventDefault();
    if(report === 'default') return;
    history.push(report);
  };

  return (
    <div className="Report ReportSelection">
      <FormWrapper squared={true} raised={true}>
        <Form id='ReportSelection-form' onSubmit={onSubmit} submitLabel="Continue" autoComplete="off">
          <FormField fieldName="report" label="Report">
            <Select name="report-select" value={report} onChange={onFieldChange}>
              <MenuItem value="default">Select the report</MenuItem>
              { reports.map((item, index) => (<MenuItem key={`report-${index}`} value={item.url}>{item.display}</MenuItem>)) }
            </Select>
          </FormField>
        </Form>
      </FormWrapper>
    </div>
  );
}

export default withRouter(ReportSelection);