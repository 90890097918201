import { ATTENDANCE_REQUEST, ATTENDANCE_IMPORTED, ATTENDANCE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function attendanceReducer(state = initialState.attendance, action) {
  const { type, error = {} } = action;
  let status = stateConfig.responseStatus;
    
  switch(type) {
    case ATTENDANCE_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseErr: null, jobId: null });
    case ATTENDANCE_IMPORTED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, jobId: action.data.jobId });
    case ATTENDANCE_ERR:
      return Object.assign({}, state, { responseStatus: status.ERROR, responseErr: error });
    default:
      return state;
  }
}

export default attendanceReducer;