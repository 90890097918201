import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as magdalaAPI from '../../../actions/magdala';
import stateConfig from '../../../config/state';
import Button from '@material-ui/core/Button';
import validator from '../../../utils/validator';
const { isDefined } = validator; 


class CommunityDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateId: `${stateConfig.keys.MAGDALA_REG}.${props.profile.email}`,
      communityName: null
    };
  }

  componentDidMount() {
    const { email, communityId } = this.props.profile;
    if(validator.isEmpty(communityId)) {
      this.props.onComplete();
    } else {
      this.props.magdalaAPI.getRegistration({ email });
    }
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.registrations;
    const prevIds = prevProps.registrations.ids;
    const registration = byId[this.state.stateId];

    if(ids !== prevIds && registration) {
      if(!registration.error && registration.community) {
        this.setState({ communityName: registration.community.communityName });
        this.props.onComplete();
      } else {
        this.props.onComplete(); // fail silently, not a fatal error
      }
    }
  }

  render() {
    const { communityName } = this.state;
    const { email, firstName, legalFirstName, lastName} = this.props.profile; 
    const { changeMember } = this.props; 
    return (
      <div className="CommunityDisplay">
        { isDefined(email) &&
          <div className="current-selections">
            <p className="field">
              <span className="label">Selected Member:</span>
              <span className="value">
                {`${firstName} ${legalFirstName? legalFirstName : ''} ${lastName}`}
                <Button variant="text" color="secondary" onClick={changeMember}>
                  Change Member
                </Button>
              </span>
            </p>
          </div>

        }
        { communityName !== null &&
          <div className="community-name">
            <p>
              This member is currently registered under the following community:<br /> 
              <strong>{communityName}</strong>
            </p>
          </div>
        }
      </div>
    );
  }
}

CommunityDisplay.propTypes = {
  profile: PropTypes.object,
  onComplete: PropTypes.func,
  changeMember: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    registrations: state.magdalaRegistrations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityDisplay);