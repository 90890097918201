import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdateContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      fields: {
        contactId: props.profile.contactId || '',
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'contactId' },
      { rule: validator.rules.IS_INTEGER, prop: 'contactId' }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateContact-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

            <FormField fieldName="contactId" label="Contact ID" 
              error={validator.message(errors['contactId'], 'contact ID')}>
              <Input name="contactId" placeholder='Contact ID' value={fields.contactId}
                onChange={this.onFieldChange} error={validator.isDefined(errors['contactId'])} />
            </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdateContact.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateContact;