import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from '../../common/Icon';
import config from '../../../config/config';
import validator from '../../../utils/validator';
const { isEmpty } = validator;

function TranscriptHeading({ profile, transcripts }) {
  const [certificates, setCertificates] = useState(-1);

  useEffect(() => {
    if(certificates === -1) {
      const n = transcripts.reduce((n, transcript) => {
        return (transcript.track === 'certificate' && config.courseComplete.includes(transcript.status))? n+1 : n;
      }, 0);
      setCertificates(n);
    }
  }, [transcripts, certificates]);

  return (
    <div className="TranscriptHeading">
      { isEmpty(profile.firstName) && isEmpty(profile.firstName) &&
        <p className="no-name">
          We do not have a name on record for this member.
        </p>
      }
      { (!isEmpty(profile.firstName) || !isEmpty(profile.firstName)) &&
        <p className="name">{profile.firstName} {profile.lastName}</p>
      }
      <p className="email"><span>Email:</span> {profile.email}</p>
      { certificates > 0 &&
        <div className="certificates">
          <Icon type={IconTypes.Certificate} />
          {`${certificates} ${certificates > 1? 'Certificates' : 'Certificate'} Earned`}
        </div>
      }
    </div>
  );
}

TranscriptHeading.propTypes = {
  profile: PropTypes.object,
  transcripts: PropTypes.array
};

export default TranscriptHeading;
