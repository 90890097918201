import { PAGE_MESSAGE_PUBLISHED } from './actionTypes';
import state from '../config/state';

export function displayPageMessage(messageData) {
  const data = Object.assign({
    type: state.messageTypes.INFO,
    time: 30
  }, messageData);
  return dispatch => {
    dispatch({ type: PAGE_MESSAGE_PUBLISHED, data });
  };
}

