import { COURSE_REG_REQUEST, COURSE_RETRIEVED_REG, COURSE_REG_ERR, COURSE_CREATED_REG,
         COURSE_DELETED_REG, COURSE_UPDATED_REG } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function courseRegistrationsReducer(state = initialState.courseRegistrations, action) {
  const registrations = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case COURSE_REG_REQUEST:
      clearStateById(registrations.ids, registrations.byId, stateId);
      return registrations;
    case COURSE_RETRIEVED_REG:
    case COURSE_DELETED_REG:
    case COURSE_CREATED_REG:
    case COURSE_UPDATED_REG:
      registrations.ids.push(stateId);
      registrations.byId[stateId] = action.data;
      return registrations;
    case COURSE_REG_ERR:
      registrations.ids.push(stateId);
      registrations.byId[stateId] = { error: action.error };
      return registrations;
    default:
      return state;
  }
}

export default courseRegistrationsReducer;