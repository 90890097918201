import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ContactIcon from '@material-ui/icons/ContactSupportOutlined';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import LockIcon from '@material-ui/icons/LockOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from '@material-ui/icons/Share';
import DonateMenuIcon from '@material-ui/icons/CardGiftcardOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaCore from '@fortawesome/pro-solid-svg-icons';
import * as FaDuotone from '@fortawesome/pro-duotone-svg-icons';
import { faAddressCard, faAward, faBars, faBookOpen, faBooks, faCalendarAlt, faCheckCircle, faChevronDown, faChevronUp,
         faChevronRight, faChevronLeft, faCircle, faClipboardListCheck, faClock, faCommentAltLines, faConstruction, 
         faCopy, faDownload, faEdit, faExclamationCircle, faExternalLink, faFileAlt, faFileCheck, faFilter, faHeadphonesAlt, 
         faHeadset, faHeart, faInfoCircle, faKey, faListAlt, faMicrophone, faMousePointer, faPlay, faPlayCircle, faPlus, faRadio, faSearch, 
         faSignOut, faStar, faSurprise, faTimes, faTimesSquare, faTvAlt, faUser, faUserPlus, faUsers, faUsersClass, 
         faVideo } from '@fortawesome/pro-light-svg-icons';
const { faAlarmClock, faEnvelope, faPhone } = FaCore;
const faHeartFilled = FaCore.faHeart;
const faHeartCircle = FaCore.faHeartCircle;
const faQuoteLeft = FaCore.faQuoteLeft;
const faQuoteRight = FaCore.faQuoteRight;
const faHeartCircleFilled = FaDuotone.faHeartCircle;
const faStarSolid = FaCore.faStar;
const faPlayCircleFilled = FaCore.faPlayCircle;
const faCheckCircleFilled = FaCore.faCheckCircle;
const faCircleHalf = FaDuotone.faCircleNotch;
const faUserCircle = FaCore.faUserCircle;
const faLockFilled = FaCore.faLockAlt;
const faChevronCircleRight = FaCore.faChevronCircleRight;
const faInfoCircleFilled = FaCore.faInfoCircle;


export const IconTypes = Object.freeze({
  Add: 'Add',
  Audio: 'Audio',
  ArrowBack: 'ArrowBack',
  ArrowDown: 'ArrowDown',
  ArrowForward: 'ArrowForward',
  BookOpen: 'BookOpen',
  Cancel: 'Cancel',
  Certificate: 'Certificate',
  Class: 'Class',
  Click: 'Click',
  Close: 'Close',
  ClosePlain: 'ClosePlain',
  Complete: 'Complete',
  CompleteFilled: 'CompleteFilled',
  Construction: 'Construction',
  Contact: 'Contact',
  ContentList: 'ContentList',
  Copy: 'Copy',
  Discussion: 'Discussion',
  Document: 'Document',
  Download: 'Download',
  Edit: 'Edit',
  EditContent: 'EditContent',
  Email: 'Email',
  EventRunning: 'EventRunning',
  Events: 'Events',
  ExpandMenuDown: 'ExpandMenuDown',
  ExpandMenuUp: 'ExpandMenuUp',
  ExpandSideMenu: 'ExpandSideMenu',
  Exit: 'Exit',
  ExternalLink: 'ExternalLink',
  Favorite: 'Favorite',
  FavoriteFilled: 'FavoriteFilled',
  FavoriteMenu: 'FavoriteMenu',
  FavoriteMenuFilled: 'FavoriteMenuFilled',
  Filter: 'Filter',
  Gift: 'Gift',
  Group: 'Group',
  Incomplete: 'Incomplete',
  InfoCircle: 'InfoCircle',
  InfoCircleFilled: 'InfoCircleFilled',
  Listen: 'Listen',
  Lock: 'Lock',
  LockFilled: 'LockFilled',
  Menu: 'Menu',
  NotStarted: 'NotStarted',
  OnDemand: 'OnDemand',
  OutlineSelected: 'OutlineSelected',
  Password: 'Password',
  Phone: 'Phone',
  Play: 'Play',
  Playing: 'Playing',
  Quiz: 'Quiz',
  QuoteLeft: 'QuoteLeft',
  QuoteRight: 'QuoteRight',
  Radio: 'Radio',
  Rating: 'Rating',
  RatingFilled: 'RatingFilled',
  Register: 'Register',
  Search: 'Search',
  Settings: 'Settings',
  Share: 'Share',
  Surprise: 'Surprise',
  TaskList: 'TaskList',
  Time: 'Time',
  User: 'User',
  UserCircle: 'UserCircle',
  UserMenu: 'UserMenu',
  UserInfo: 'UserInfo',
  Video: 'Video',
  Warning: 'Warning',
  WatchedContent: 'WatchedContent',
  Webinar: 'Webinar',
  WebinarParticipate: 'WebinarParticipate',
  Written: 'Written'
});

export default function Icon(props) {
  const icon = makeIcon(props);
  const component = props.avatar? <Avatar className="avatar">{icon}</Avatar> : icon;
  
  return (
    <div className={`Icon ${props.inline? 'inline' : 'centered'} spacing-${props.spacing} ${props.className || ''}`}>
      <div className={`size-${props.size} color-${props.color}`}>
        {component}
      </div>
    </div>
  ); 
};

Icon.defaultProps = {
  avatar: false,
  size: '1x',
  spacing: '',
  inline: false,
  color: 'primary'
};

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};

function makeIcon(props) {
  switch(props.type) {
    case IconTypes.Add: return faIcon(faPlus, props.size);
    case IconTypes.Audio: return faIcon(faMicrophone, props.size);
    case IconTypes.ArrowBack: return faIcon(faChevronLeft, props.size);
    case IconTypes.ArrowDown: return faIcon(faChevronDown, props.size);
    case IconTypes.ArrowForward: return faIcon(faChevronRight, props.size);
    case IconTypes.BookOpen: return faIcon(faBookOpen, props.size);
    case IconTypes.Certificate: return faIcon(faAward, props.size);
    case IconTypes.Cancel: return <CloseIcon className="Icon-mui" />;
    case IconTypes.Class: return faIcon(faUsersClass, props.size);
    case IconTypes.Click: return faIcon(faMousePointer, props.size);
    case IconTypes.Close: return faIcon(faTimesSquare, props.size);
    case IconTypes.ClosePlain: return faIcon(faTimes, props.size);
    case IconTypes.Complete: return faIcon(faCheckCircle, props.size);
    case IconTypes.CompleteFilled: return faIcon(faCheckCircleFilled, props.size);
    case IconTypes.Construction: return faIcon(faConstruction, props.size);
    case IconTypes.Contact: return (<ContactIcon className="Icon-mui" />);
    case IconTypes.ContentList: return faIcon(faListAlt, props.size);
    case IconTypes.Copy: return faIcon(faCopy, props.size);
    case IconTypes.Discussion: return faIcon(faCommentAltLines, props.size);
    case IconTypes.Document: return faIcon(faFileAlt, props.size);
    case IconTypes.Download: return faIcon(faDownload, props.size);
    case IconTypes.Edit: return <EditIcon className="Icon-mui" />;
    case IconTypes.EditContent: return faIcon(faEdit, props.size);
    case IconTypes.Email: return faIcon(faEnvelope, props.size);
    case IconTypes.EventRunning: return faIcon(faAlarmClock, props.size);
    case IconTypes.Events: return faIcon(faCalendarAlt, props.size);
    case IconTypes.ExpandMenuDown: return faIcon(faChevronDown, props.size);
    case IconTypes.ExpandMenuUp: return faIcon(faChevronUp, props.size);
    case IconTypes.ExpandSideMenu: return (<MenuOpenIcon className="Icon-mui" />);
    case IconTypes.Exit: return faIcon(faSignOut, props.size);
    case IconTypes.ExternalLink: return faIcon(faExternalLink, props.size);
    case IconTypes.Favorite: return faIcon(faHeartCircle, props.size);
    case IconTypes.FavoriteFilled: return faIcon(faHeartCircleFilled, props.size);
    case IconTypes.FavoriteMenu: return faIcon(faHeart, props.size);
    case IconTypes.FavoriteMenuFilled: return faIcon(faHeartFilled, props.size);
    case IconTypes.Filter: return faIcon(faFilter, props.size);
    case IconTypes.Gift: return (<DonateMenuIcon className="Icon-mui" />);
    case IconTypes.Group: return faIcon(faUsers, props.size);
    case IconTypes.Incomplete: return faIcon(faCircleHalf, props.size);
    case IconTypes.InfoCircle: return faIcon(faInfoCircle, props.size);
    case IconTypes.InfoCircleFilled: return faIcon(faInfoCircleFilled, props.size);
    case IconTypes.Listen: return faIcon(faHeadphonesAlt, props.size);
    case IconTypes.Lock: return (<LockIcon className="Icon-mui" />);
    case IconTypes.LockFilled: return faIcon(faLockFilled, props.size);
    case IconTypes.Menu: return faIcon(faBars, props.size);
    case IconTypes.NotStarted: return faIcon(faCircle, props.size);
    case IconTypes.OnDemand: return faIcon(faPlayCircle, props.size);
    case IconTypes.OutlineSelected: return faIcon(faChevronCircleRight, props.size);
    case IconTypes.Password: return faIcon(faKey, props.size);
    case IconTypes.Phone: return faIcon(faPhone, props.size);
    case IconTypes.Play: return faIcon(faPlay, props.size);
    case IconTypes.Playing: return faIcon(faPlayCircleFilled, props.size);
    case IconTypes.Quiz: return faIcon(faFileCheck, props.size);
    case IconTypes.QuoteLeft: return faIcon(faQuoteLeft, props.size);
    case IconTypes.QuoteRight: return faIcon(faQuoteRight, props.size);
    case IconTypes.Radio: return faIcon(faRadio, props.size);
    case IconTypes.Rating: return faIcon(faStar, props.size);
    case IconTypes.RatingFilled: return faIcon(faStarSolid, props.size);
    case IconTypes.Register: return faIcon(faUserPlus, props.size);
    case IconTypes.Search: return faIcon(faSearch, props.size);
    case IconTypes.Settings: return <SettingsIcon className="Icon-mui" />;
    case IconTypes.Share: return <ShareIcon className="Icon-mui" />;
    case IconTypes.Surprise: return faIcon(faSurprise, props.size);
    case IconTypes.TaskList: return faIcon(faClipboardListCheck, props.size);
    case IconTypes.Time: return faIcon(faClock, props.size);
    case IconTypes.User: return faIcon(faUser, props.size);
    case IconTypes.UserCircle: return faIcon(faUserCircle, props.size);
    case IconTypes.UserMenu: return <PermIdentityIcon className="Icon-mui" />;
    case IconTypes.UserInfo: return faIcon(faAddressCard, props.size);
    case IconTypes.Video: return faIcon(faVideo, props.size);
    case IconTypes.Warning: return faIcon(faExclamationCircle, props.size);
    case IconTypes.WatchedContent: return faIcon(faTvAlt, props.size);
    case IconTypes.Webinar: return faIcon(faVideo, props.size);
    case IconTypes.WebinarParticipate: return faIcon(faHeadset, props.size);
    case IconTypes.Written: return faIcon(faBooks, props.size);
    default: return null;
  }
}

function faIcon(icon, size) {
  return <FontAwesomeIcon className="Icon-fa" icon={icon} size={size} />
}

