import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import validator from '../../../utils/validator';

export default function FormField(props) {
  return (
    <FormControl className={`FormField-control ${props.className || ''}`} fullWidth={props.fullWidth}>
      { props.label &&
        <InputLabel className="FormField-label" htmlFor={props.fieldName}>{props.label}</InputLabel>
      }
      { props.children }
      { (props.help || props.error) &&
        <FormHelperText error={validator.isDefined(props.error)}>{props.error || props.help}</FormHelperText>
      }
    </FormControl>
  );
}

FormField.defaultProps = {
  fullWidth: true
};

FormField.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string
};
