import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as profileAPI from '../../../actions/profile';
import Icon, { IconTypes} from '../../common/Icon';


function MemberSelection({ users, limited, onSelect, onCancel }) {
  return (
    <div className="MemberSelection">
      <div className="back">
        <Button className="back" variant="text" onClick={onCancel}>
          <Icon type={IconTypes.ArrowBack} /> New search
        </Button>
      </div>
      <div className="heading">
        <div className="results">Results: {users.length}</div>
        { limited &&
          <div className="results-limited">Note: these results were limited due to a large number of matches.</div>
        }
      </div>
      {
        users.map((user, index) => {
          const { email, firstName, legalFirstName, lastName } = user;
          return (
            <div key={`member-${index}`} className="member">
              <span className="email" onClick={() => onSelect(email)}>{email}</span>
              <span className="name">{`${firstName}${legalFirstName? ', '+legalFirstName : ''} ${lastName}`}</span>
            </div>
          );
        })
      }
    </div>
  );
}

MemberSelection.propTypes = {
  users: PropTypes.array,
  limited: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    profiles: state.profiles
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberSelection);