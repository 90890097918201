import { PROFILE_REQUEST, PROFILE_RETRIEVED, PROFILE_ERR,
         PROFILE_UPDATE_REQUEST, PROFILE_UPDATED, PROFILE_UPDATE_ERR,
         PROFILE_DELETE_REQUEST, PROFILE_DELETED, PROFILE_DELETE_ERR } from '../actions/actionTypes';
import * as profile from '../api/profile';
import errors from '../config/errors';
import stateConfig from '../config/state';


const profilesKey = stateConfig.keys.PROFILES;
const updatesKey = stateConfig.keys.PROFILE_UPDATES;
const donationsKey = stateConfig.keys.PROFILE_DONATIONS;
const deleteKey = stateConfig.keys.PROFILE_DELETE;

export function getMember(email) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST, key: profilesKey, email });
    try {
      let result = await profile.getMember(email);
      if(result.data.email) {
        dispatch({ type: PROFILE_RETRIEVED, key: profilesKey, email, data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, key: profilesKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: PROFILE_ERR, key: profilesKey, email, error: errors.RequestFailed });
    }
  };
}

export function memberSearch(type, values) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST, key: profilesKey, operation: 'search' });
    try {
      let result = await profile.memberSearch(type, values);
      if(result.data.email || result.data.users) {
        dispatch({ type: PROFILE_RETRIEVED, key: profilesKey, operation: 'search', data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, key: profilesKey, operation: 'search', error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: PROFILE_ERR, key: profilesKey, operation: 'search', error: errors.RequestFailed });
    }
  };
}

export function registerMember(data) {
  const { email } = data;
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: updatesKey, email });
    try {
      let result = await profile.registerMember(data);
      if(result.data.trackingId) {
        dispatch({ type: PROFILE_UPDATED, key: updatesKey, email, data: { created: true, ...result.data } });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: err });
    }
  };
}

export function deleteMember(email) {
  return async dispatch => {
    dispatch({ type: PROFILE_DELETE_REQUEST, key: deleteKey, email });
    try {
      let result = await profile.deleteMember(email);
      if(result.data.success) {
        dispatch({ type: PROFILE_DELETED, key: deleteKey, email });
      } else {
        dispatch({ type: PROFILE_DELETE_ERR, key: deleteKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_DELETE_ERR, key: deleteKey, email, error: err });
    }
  };
}

export function activateMember(email) {
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: updatesKey, email });
    try {
      let result = await profile.activateMember(email);
      if(result.data.success) {
        dispatch({ type: PROFILE_UPDATED, key: updatesKey, email, data: { activated: true } });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: err });
    }
  };
}

export function updatePassword(email, password) {
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: updatesKey, email });
    try {
      let result = await profile.updatePassword(email, password);
      if(result.data.success) {
        dispatch({ type: PROFILE_UPDATED, key: updatesKey, email, data: { updatedPassword: true } });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: err });
    }
  };
}

export function updateEmail(oldEmail, newEmail, merge) {
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: updatesKey, email: newEmail });
    try {
      let result = await profile.updateEmail(oldEmail, newEmail, merge);
      if(result.data.success) {
        dispatch({ type: PROFILE_UPDATED, key: updatesKey, email: newEmail, data: { updatedEmail: true, ...result.data } });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email: newEmail, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email: newEmail, error: { failedEmail: true, ...err } });
    }
  };
}

export function getDonationHistory(email) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST, key: donationsKey, email });
    try {
      let result = await profile.getDonationHistory(email);
      if(result.data.success) {
        dispatch({ type: PROFILE_RETRIEVED, key: donationsKey, email, data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, key: donationsKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_ERR, key: donationsKey, email, error: err });
    }
  };
}

export function notateDonation(email, donationId, amount, note) {
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: donationsKey, email });
    try {
      let result = await profile.notateDonation(email, donationId, amount, note);
      if(result.data.success) {
        dispatch({ type: PROFILE_UPDATED, key: donationsKey, email, data: result.data });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: donationsKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: donationsKey, email, error: err });
    }
  };
}

export function updateProfile(data) {
  const { email } = data;
  return async dispatch => {
    dispatch({ type: PROFILE_UPDATE_REQUEST, key: updatesKey, email });
    try {
      let result = await profile.updateProfile(data);
      if(result.data.success) {
        dispatch({ type: PROFILE_UPDATED, key: updatesKey, email, data: { updatedProfile: true } });
      } else {
        dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: PROFILE_UPDATE_ERR, key: updatesKey, email, error: err });
    }
  };
}
