import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../form/FormWrapper';
import Form from '../form/Form';
import FormField from '../form/FormField';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import countries from '../../../config/data/countries';
import states from '../../../config/data/states';
import validator from '../../../utils/validator';


class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    const { hasAddress, country, isForeign, addressLine1, addressLine2, addressLine3, city, state, postalCode } = addressFromProfile(props.profile);
    this.state = {
      hasAddress,
      submission: false,
      confirmDeletion: false,
      fields: {
        country,
        isForeign, 
        addressLine1, 
        addressLine2, 
        addressLine3,
        city, 
        state, 
        postalCode
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    if(name === 'country') {
      fields.isForeign = (value !== 'United States');
    }

    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    const { fields } = this.state;
    const data = fields.isForeign? {
        isForeign: true,
        addressLine1: fields.addressLine1,
        addressLine2: fields.addressLine2,
        addressLine3: fields.addressLine3,
        country: fields.country
      } : {
        isForeign: false,
        addressLine1: fields.addressLine1,
        addressLine2: fields.addressLine2,
        city: fields.city,
        state: fields.state,
        postalCode: fields.postalCode,
        country: fields.country
      };

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(data);
    });
  }

  onDelete() {
    this.setState({ confirmDeletion: true });
  }

  onDeleteConfirm(confirmed) {
    if(confirmed) {
      const self = this;
      this.setState({ submission: true, errors: {} }, () => {
        self.props.onSubmit(null);
      });
    } else {
      this.setState({ confirmDeletion: false });
    }
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'country' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine1', against: validator.patterns.ADDRESS_LINE, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'addressLine1' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine2', against: validator.patterns.ADDRESS_LINE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'city', against: validator.patterns.CITY, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'state', against: validator.patterns.STATE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'postalCode', against: validator.patterns.ZIP_CODE, empty: true }
    ];
  
    if(data.country === 'United States') {
      rules = rules.concat([
        { rule: validator.rules.IS_DEFINED, prop: 'city' },
        { rule: validator.rules.IS_DEFINED, prop: 'state' },
        { rule: validator.rules.IS_DEFINED, prop: 'postalCode' }
      ]);
    } else {
      rules = rules.concat([
        { rule: validator.rules.IS_DEFINED, prop: 'addressLine2' },
        { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine3', against: validator.patterns.ADDRESS_LINE, empty: true }
      ]);
    }
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { hasAddress, submission, confirmDeletion, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <Fragment>
        { confirmDeletion &&
          <ConfirmationDialog message="Are you sure you want to delete this address?" onClose={this.onDeleteConfirm} />
        }
        <FormWrapper squared={true} raised={true}>
          <Form id='UpdateAddress-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
            submitLabel="Update" submission={submission} autoComplete="off">

            <FormField fieldName="country" label="Country" error={validator.message(errors['country'], 'country')}>
              <Select name="country" value={fields.country} onChange={this.onFieldChange} error={validator.isDefined(errors['country'])}>
                { 
                  countries.data.map(item => {
                    return (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>);
                  }) 
                }
              </Select>
            </FormField>
            <FormField className="addressLine1" fieldName="addressLine1" label="Address Line 1"
              error={validator.message(errors['addressLine1'], 'address line 1')}>
              <Input name="addressLine1" placeholder="Address Line 1" value={fields.addressLine1} 
                onChange={this.onFieldChange} error={validator.isDefined(errors['addressLine1'])} />
            </FormField>
            <FormField className="addressLine2" fieldName="addressLine2" label="Address Line 2"
              error={validator.message(errors['addressLine2'], 'address line 2')}>
              <Input name="addressLine2" placeholder="Address Line 2" value={fields.addressLine2} 
                onChange={this.onFieldChange} error={validator.isDefined(errors['addressLine2'])} />
            </FormField>
            { fields.isForeign &&
              <FormField className="addressLine3" fieldName="addressLine3" label="Address Line 3"
                error={validator.message(errors['addressLine3'], 'address line 3')}>
                <Input name="addressLine3" placeholder="Address Line 3" value={fields.addressLine3} 
                  onChange={this.onFieldChange} error={validator.isDefined(errors['addressLine3'])} />
              </FormField>
            }
            { !fields.isForeign &&
              <div>
                <FormField className="city" fieldName="city" label="City"
                  error={validator.message(errors['city'], 'city')}>
                  <Input name="city" placeholder="City" value={fields.city} onChange={this.onFieldChange} 
                    error={validator.isDefined(errors['city'])} />
                </FormField>
                <FormField className="state" fieldName="state" label="State"
                  error={validator.message(errors['state'], 'state')}>
                  <Select name="state" value={fields.state} onChange={this.onFieldChange}
                    error={validator.isDefined(errors['state'])}>
                    { 
                      states.data.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>);
                      }) 
                    }
                  </Select>
                </FormField>
                <FormField className="postalCode" fieldName="postalCode" label="Zip Code"
                  error={validator.message(errors['postalCode'], 'zip code')}>
                  <Input name="postalCode" placeholder="Zip Code" value={fields.postalCode} 
                    onChange={this.onFieldChange} error={validator.isDefined(errors['postalCode'])} />
                </FormField>
              </div>
            }
          </Form>
        </FormWrapper>
        { hasAddress &&
          <div className="delete">
            <Button className="back" variant="text" color="secondary" onClick={this.onDelete}>
              Delete this address
            </Button>
          </div>
        }
      </Fragment>
    );
  }
}

export function addressFromProfile(data) {
  const address = (data.addresses && data.addresses.length > 0)? data.addresses[0] : {};
  return {
    hasAddress: (data.addresses && data.addresses.length > 0),
    isForeign: validator.isNotDefined(address.isForeign)? false : address.isForeign,
    country: address.country || 'United States',
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    addressLine3: address.addressLine3 || '',
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || ''
  };
}

EditAddress.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default EditAddress;