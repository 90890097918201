import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon, { IconTypes} from '../../common/Icon';
import DonationNotation from './DonationNotation';
import { formatDateForDisplay } from '../../../utils/format';
import validator from '../../../utils/validator';


function DonationsDisplay({ email, donations, recurring, scheduled, onBack, onUpdate }) {
  const [editing, setEditing] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedNotation, setSelectedNotation] = useState(null);

  const startNotation = donation => () => {
    setEditing(true);
    setSelectedId(donation.donationId);
    setSelectedAmount(`${donation.amount}`);
    setSelectedNotation(donation.notation);
  };

  const stopNotation = result => {
    setEditing(false);
    setSelectedId(null);
    setSelectedAmount(null);
    setSelectedNotation(null);
    if(!result.cancelled) {
      onUpdate(result);
    }
  };

  return (
    <div className="DonationsDisplay">
      { !editing && 
        <div className="back">
          <Button className="back" variant="text" onClick={onBack}>
            <Icon type={IconTypes.ArrowBack} /> Select a different member
          </Button>
        </div>
      }
      { !editing && <h2>Member Donations</h2> }
      { recurring.length > 0 && !editing &&
        <div className="recurring-list">
          <h3>Recurring Donations</h3>
          {
            recurring.map((donation, index) => {
              const card = donation.card;
              return (
                <div key={`recurring-donation-${index}`} className="recurring-donation">
                  <div className="amount">
                    ${donation.amount}.00/{donation.frequency}
                    {card? ` - ${card.last4} (exp: ${card.expires.month}/${card.expires.year})` : ''}
                  </div>
                  <div className="dates">
                    <div className="created">
                      <span>Created On:</span>
                      {formatDateForDisplay(new Date(donation.createdOn))}
                    </div>
                    { validator.isNotEmpty(donation.lastOccurrence) &&
                      <div className="last-charge">
                        <span>Last Charged:</span>
                        {formatDateForDisplay(new Date(donation.lastOccurrence))}
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { recurring.length === 0 && !editing &&
        <div className="recurring-list">
          <h3>Recurring Donations</h3>
          <span className="none">No current recurring donations were found.</span>
        </div>
      }
      { scheduled.length > 0 && !editing &&
        <div className="scheduled-list">
          <h3>Scheduled Recurring Donations</h3>
          {
            scheduled.map((donation, index) => {
              return (
                <div key={`scheduled-donation-${index}`} className="scheduled-donation">
                  <div className="amount">
                    ${donation.amount}.00/{donation.frequency}
                  </div>
                  <div className="date">
                    <span>Starting On:</span>&nbsp;
                    {formatDateForDisplay(new Date(donation.startingOn))}
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { scheduled.length === 0 && !editing &&
        <div className="scheduled-list">
          <h3>Scheduled Recurring Donations</h3>
          <span className="none">No scheduled recurring donations were found.</span>
        </div>
      }
      { donations.length > 0 && !editing &&
        <div className="history-list">
          <h3>Donation History</h3>
          {
            donations.map((donation, index) => {
              const succeeded = donation.status === 'complete';
              const card = donation.card;
              return (
                <div key={`donation-${index}`} className="donation">
                  <span className="date">{formatDateForDisplay(new Date(donation.createdOn))}</span>
                  <span className="description">
                    ${donation.amount}.00 {donation.recurringDonationId? 'Monthly Donation' : 'One-Time Donation' }
                    {succeeded && card? ` - ${card.last4} (exp: ${card.expires.month}/${card.expires.year})` : ''}
                    {!succeeded? ' - Transaction Failed' : '' }
                  </span>
                  <div className="notation">
                    <span className={validator.isEmpty(donation.notation)? 'empty' : 'not-empty'}>
                      {validator.isNotEmpty(donation.notation)? `Note: ${donation.notation}` : ''}
                    </span>
                    <Button variant="text" onClick={startNotation(donation)}>
                      {donation.notation? 'Edit refund note' : 'Add refund note'}
                    </Button>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { donations.length === 0 && !editing &&
        <div className="history-list">
          <h3>Donation History</h3>
          <span className="none">No donation transactions were found.</span>
        </div>
      }
      { editing &&
        <DonationNotation email={email} donationId={selectedId} amount={selectedAmount} notation={selectedNotation} onClose={stopNotation} />
      }
    </div>
  );
}

DonationsDisplay.propTypes = {
  email: PropTypes.string,
  donations: PropTypes.array,
  recurring: PropTypes.array,
  scheduled: PropTypes.array,
  onBack: PropTypes.func,
  onUpdate: PropTypes.func
};

export default DonationsDisplay;