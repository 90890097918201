import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';


class UpdateJabraStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      fields: {
        jabraStatus: props.community.jabraStatus || ''
      }
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();
    const self = this;
    this.setState({ submission: true }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  render() {
    const { submission, fields } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateJabraStatus-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

            <FormField fieldName="jabraStatus" label="Jabra Status">
              <Select name="jabraStatus" value={fields.jabraStatus} onChange={this.onFieldChange}>
                <MenuItem value="">Select the status</MenuItem>
                <MenuItem value="mailed">Mailed</MenuItem>
                <MenuItem value="returned">Returned</MenuItem>
                <MenuItem value="not-applicable">Not Applicable</MenuItem>
              </Select>
            </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdateJabraStatus.propTypes = {
  community: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateJabraStatus;