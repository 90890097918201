import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as coursesAPI from '../../../actions/content/courses';
import RegistrationDisplay from '../registration/RegistrationDisplay';
import RecordGeneral from './RecordGeneral';
import RecordProgress from './RecordProgress';
import config from '../../../config/config';


function RecordDetails({ record, profile, onUpdate, onDeletion, coursesAPI }) {
  const [init, setInit] = useState(false);
  const { email } = profile;
  const { courseId, type, status, waitlisted } = record;

  useEffect(() => {
    if(!init) {
      coursesAPI.getCourseById(courseId);
      setInit(true);
    }
  }, [init, email, courseId, coursesAPI]);

  return (
    <div className="TranscriptRecordDetails">
      <RecordGeneral record={record} profile={profile} onUpdate={onUpdate} onDeletion={onDeletion} />
      { type === 'live' && !config.courseComplete.includes(status) &&
        <div className="TranscriptRecordDetails-registration">
          <h3>Live Registration Details</h3>
          <RegistrationDisplay result={record} fullDisplay={false} />
        </div>
      }
      { config.courseNotComplete.includes(status) && !waitlisted &&
        <RecordProgress record={record} profile={profile} onUpdate={onUpdate} />
      }
    </div>
  );
}

RecordDetails.propTypes = {
  record: PropTypes.object,
  profile: PropTypes.object,
  onUpdate: PropTypes.func,
  onDeletion: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordDetails);
