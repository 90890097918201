import React from 'react';
import PropTypes from 'prop-types';
import NavList from './NavList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';


function NavMenu(props) {
  const { isOpen, onClose } = props;
  return (
    <div className="NavMenu">
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
      >
        <NavList onClose={onClose} />
      </SwipeableDrawer>
    </div>
  );
}

NavMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default NavMenu;