import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as profileAPI from '../../../../actions/profile';
import validator from '../../../../utils/validator';
const { isNotEmpty } = validator;


function UpdateResults({ results, onMerge, onClose, profileAPI }) {
  const { oldEmail, newEmail, code, message, failedEmail } = results;
  const data = results.details || results;
  const { messages, completed, sourceMessage, mergeRequired, mergeAllowed } = data;

  const merge = () => {
    profileAPI.updateEmail(oldEmail, newEmail, true);
    onMerge(newEmail);
  };

  return (
    <div className="MemberUpdateResults">
      { failedEmail &&
        <p className="error">{`${code} - ${message}: ${sourceMessage}`}</p>
      }
      { isNotEmpty(messages) && messages.length > 0 &&
        <div className={`messages ${failedEmail? 'error' : 'success'}`}>
          <h3>Messages:</h3>
          { messages.map((message, index) => (<div key={`message-${index}`} className="message">{message}</div>)) }
        </div>
      }
      { isNotEmpty(completed) && completed.length > 0 &&
        <div className="completed">
          <h3>Completed Steps:</h3>
          { completed.map((step, index) => (<div key={`step-${index}`} className="step">{step}</div>)) }
        </div>
      }
      { mergeRequired && mergeAllowed &&
        <p className="merge">
          Would you like to merge these member accounts?
        </p>
      }
      <div className={`MemberUpdateResults-actions ${mergeRequired && mergeAllowed? 'show-merge' : 'hide-merge'}`}>
        { mergeRequired && mergeAllowed &&
          <Button className="merge-button" variant="contained" color="primary" onClick={merge}>
            Merge Accounts
          </Button>
        }
        <Button className="close-button" variant="text" color="default" onClick={onClose}>
          Close
      </Button>
      </div>
    </div>
  );
}

UpdateResults.propTypes = {
  results: PropTypes.object,
  onMerge: PropTypes.func,
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateResults);
