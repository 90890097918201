import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';

class PageMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      time: 0,
      message: '',
      type: ''
    };
  }

  componentDidMount() {
    const self = this;
    this.props.history.listen((location, action) => {
      self.setState({ display: false });
    });
  }

  componentDidUpdate(prevProps) {
    const { pageMessage } = this.props;
    const { pageMessage: prevPageMessage } = prevProps;

    if(pageMessage.message && pageMessage !== prevPageMessage) {
      this.setState({ ...pageMessage }, () => {
        this.showMessage();
      });
    }
  }

  showMessage() {
    const { time } = this.state;
    const self = this;
    const length = 1000 * this.state.time;

    if(time !== 0) {
      setTimeout(function() {
        self.setState({ display: false });
      }, length);
    }

    this.setState({ display: true });
  }

  render() {
    const { display, type, message } = this.state;
    return (
      <Collapse in={display}>
        <div id="PageMessage" className={`${type}`}>
          { message }
        </div>
      </Collapse>
    );
  }
}

function mapStateToProps(state) {
  const { pageMessage } = state.appDisplay;
  return { pageMessage };
}

export default withRouter(connect(mapStateToProps)(PageMessage));