import { COURSE_REG_REQUEST, COURSE_REG_ERR, COURSE_CREATED_REG } from '../../actions/actionTypes';
import * as courseAPI from '../../api/courses/registration';
import errors from '../../config/errors';
import stateConfig from '../../config/state';


const createRegistrationKey = stateConfig.keys.COURSE_REG_NEW;

export function createRegistration(type, data) {
  const { email, slug } = data;
  const objectId = `${slug}.${email}`;
  return async dispatch => {
    dispatch({ type: COURSE_REG_REQUEST, key: createRegistrationKey, objectId });
    try {
      let result = await courseAPI.createRegistration(type, data);
      if(result.data.email) {
        dispatch({ type: COURSE_CREATED_REG, key: createRegistrationKey, objectId, data: { created: true, ...result.data } });
      } else {
        dispatch({ type: COURSE_REG_ERR, key: createRegistrationKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      let duplicate = error.response && error.response.data && error.response.data.code === 'F223';
      dispatch({ type: COURSE_REG_ERR, key: createRegistrationKey, objectId, error: { duplicate, ...errors.RequestFailed } });
    }
  };
}

export function updateRegistrationTrack(data) {
  return async dispatch => {
    try {
      let result = await courseAPI.updateRegistrationTrack(data);
      if(result.data.email) {
        return result.data;
      } else {
        throw Error('Malformed result');
      }
    } catch(error) {
      throw Error(`Track change failed: ${error.message}`);
    }
  };
}

export function removeWaitlistingStatus(data) {
  return async dispatch => {
    try {
      let result = await courseAPI.removeWaitlistingStatus(data);
      if(result.data.email) {
        return result.data;
      } else {
        throw Error('Malformed result');
      }
    } catch(error) {
      throw Error(`Waitlisting removal failed: ${error.message}`);
    }
  };
}
