import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import urls from '../../config/urls';
import config from '../../config/config';

function Dashboard(props) {
  const { data: { access } } = props.user;
  return (
    <div className="Dashboard">
      <div className="section member">
        <h3>Member Profile</h3>
        <ul className="tools-list">
          { access === config.accessRoles.ADMIN &&
            <Fragment>
              <li><Link to={urls.memberRegistration}>Register Member</Link></li>
              <li><Link to={urls.memberManage}>View/Manage Profile</Link></li>
              <li><Link to={urls.memberDonations}>Member Donations</Link></li>
              <li>Member Export</li>
            </Fragment>
          }
          { access !== config.accessRoles.ADMIN &&
            <li>No tools available</li>
          }
        </ul>
      </div>
      <div className="section course">
        <h3>Course Management</h3>
        <ul className="tools-list">
          { access === config.accessRoles.ADMIN &&
            <Fragment>
              <li><Link to={urls.courseRegistration}>Course Registration</Link></li>
              <li><Link to={urls.studentTranscript}>View/Manage Transcript</Link></li>
              <li><Link to={urls.courseMaps}>View Course Maps</Link></li>
            </Fragment>
          }
        </ul>
      </div>
      <div className="section magdala">
        <h3>Magdala Apostolate</h3>
        <ul className="tools-list">
          { access === config.accessRoles.ADMIN &&
            <Fragment>
              <li><Link to={urls.magdalaRegistration}>Register Program Participant</Link></li>
              <li><Link to={urls.magdalaCommunityRegistration}>Register Community</Link></li>
              <li><Link to={urls.magdalaCommunityManage}>View/Manage Community</Link></li>
            </Fragment>
          }
          { access !== config.accessRoles.ADMIN &&
            <li>No tools available</li>
          }
        </ul>
      </div>
      <div className="section reporting">
        <h3>Reporting</h3>
        <ul className="tools-list">
          { access === config.accessRoles.ADMIN &&
            <li><Link to={urls.courseReports}>Course Reports</Link></li>
          }
          { access !== config.accessRoles.ADMIN &&
            <li>No tools available</li>
          }
        </ul>
      </div>
      <div className="section content">
        <h3>Content Management</h3>
        <ul className="tools-list">
          <li><Link to={urls.cacheClear}>Cache Clearing</Link></li>
          { access === config.accessRoles.ADMIN &&
            <li><Link to={urls.manageMarketingTags}>View/Manage Marketing Tags</Link></li>
          }
        </ul>
      </div>
      <div className="section marketing">
        <h3>Communication</h3>
        <ul className="tools-list">
          { access === config.accessRoles.ADMIN &&
            <Fragment>
              <li><Link to={urls.manageEmailCategories}>Manage Email Categories</Link></li>
              <li><Link to={urls.sendNotifications}>Send Push Notification</Link></li>
            </Fragment>
          }
          { access !== config.accessRoles.ADMIN &&
            <li>No tools available</li>
          }
        </ul>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default withRouter(connect(mapStateToProps)(Dashboard));