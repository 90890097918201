import { PROFILE_REQUEST, PROFILE_RETRIEVED, PROFILE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function profilesReducer(state = initialState.profiles, action) {
  const profiles = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.email || action.operation}`;
  
  switch(action.type) {
    case PROFILE_REQUEST:
      clearStateById(profiles.ids, profiles.byId, stateId);
      return profiles;
    case PROFILE_RETRIEVED:
      profiles.ids.push(stateId);
      profiles.byId[stateId] = action.data;
      return profiles;
    case PROFILE_ERR:
      profiles.ids.push(stateId);
      profiles.byId[stateId] = { error: action.error };
      return profiles;
    default:
      return state;
  }
}

export default profilesReducer;