import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import * as profileAPI from '../../../actions/profile';
import ConfirmationDialog from '../../common/dialogs/ConfirmationDialog';
import stateConfig from '../../../config/state';


function MemberDeletionConfirmation({ email, onDeletion, onCancel, updates, profileAPI }) {
  const [confirmation, setConfirmation] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [submission, setSubmission] = useState(false);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROFILE_DELETE}.${email}`;
    if(submission && updates.ids.includes(stateId)) {
      const update = updates.byId[stateId];
      if(update && update.error) {
        onDeletion({ success: false, message: update.error.source || 'The account deletion could not be processed' });
      } else if(update && update.deleted) {
        onDeletion({ success: true });
      }
    }
  }, [email, submission, updates.ids, updates.byId, onDeletion]);

  const onFieldChange = ({ target: { value } }) => {
    setDisabled(value.trim() !== email);
    setConfirmation(value);
  }

  const onClose = shouldContinue => {
    if(shouldContinue) {
      profileAPI.deleteMember(email);
      setSubmission(true);
    } else {
      onCancel();
    }
  };

  return (
    <ConfirmationDialog className="MemberDeletionConfirmation" onClose={onClose} disabled={disabled}>
      <p className="instructions consequences">
        Note that deleting this membership <strong>cannot be reversed</strong>. This action will remove all 
        records for the member including all student transcripts.
      </p>
      <p className="instructions confirmation">
        To confirm that you wish to proceed with this deletion please type the member's email: <strong>{email}</strong>
      </p>
      <Input name="confirmation" value={confirmation} onChange={onFieldChange} placeholder="Confirm the email of the member to be deleted" />
    </ConfirmationDialog>
  );
}

MemberDeletionConfirmation.propTypes = {
  email: PropTypes.string,
  onDeletion: PropTypes.func,
  onCancel: PropTypes.func
};

function mapStateToProps(state) {
  return {
    updates: state.profileUpdates
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberDeletionConfirmation);