import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import * as courseContentAPI from '../../../actions/content/courses';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import stateConfig from '../../../config/state';
import { sortObjArray } from '../../../utils/object';
import validator from '../../../utils/validator';
const { isNotDefined, isDefined, isEmpty } = validator;


const initFields = {
  type: 'live',
  slug: '',
  track: ''
};

const stateId = `${stateConfig.keys.COURSE_LIST}.summary`;
const statuses = ['upcoming', 'open'];

function CourseSelection({ heading, collectTrack, type, profile, changeMember, onComplete, courses, courseContentAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [courseList, setCourseList] = useState(null);
  const [courseDropdown, setCourseDropdown] = useState(null);
  const [fields, setFields] = useState(initFields);
  const [dataError, setDataError] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if(!init) {
      const data = courses.byId[stateId];
      if(isNotDefined(data) || data.error) {
        courseContentAPI.getCourses();
      }
      if(isDefined(type) || !collectTrack) {
        let fObj = { ...fields };
        fObj.type = isDefined(type)? type : fObj.type;
        fObj.track = !collectTrack? 'na' : '';
        setFields(fObj);
      }
      setInit(true);
    }
  }, [init, fields, collectTrack, type, courses, courseContentAPI]);

  useEffect(() => {
    if(init && loading && isNotDefined(courseList) && courses.ids.includes(stateId)) {
      const data = courses.byId[stateId];
      if(isNotDefined(data) || data.error) {
        setDataError(data.error);
      } else {
        const filteredCourses = data.results.filter(course => statuses.includes(course.status));
        const sortedCourses = sortObjArray(filteredCourses, 'title');
        setCourseList(sortedCourses);
      }
      setLoading(false);
    }
  }, [init, loading, courseList, courses.ids, courses.byId]);

  useEffect(() => {
    if(isDefined(courseList)) {
      const dropdownList = courseList.filter(course => course.type === fields.type);
      setCourseDropdown(dropdownList);
    }
  }, [fields, fields.type, courseList]);

  const onFieldChange = event => {
    let { name, value } = event.target;
    let fObj = { ...fields };
    fObj[name] = value;
    if(name === 'type') {
      fObj.slug = '';
    }
    setFields(fObj);
  };

  const showTrackSelection = () => {
    if(isEmpty(fields.slug)) return false;

    const cObj = courseList.find(course => course.slug === fields.slug);
    return cObj.tracks === 'cert-audit';
  };

  const onSubmit = event => {
    event.preventDefault();
    let fObj = { ...fields };
    if(!isEmpty(fObj.slug)) {
      const cObj = courseList.find(course => course.slug === fields.slug);
      if(cObj.tracks !== 'cert-audit') {
        fObj.track = cObj.tracks;
      }
    }

    if(!validate(fObj)) {
      return;
    }
    
    const { type, slug, track } = fObj;
    const course = courseList.find(course => course.slug === slug);
    const { id: courseId, title, subtitle, transcriptCode, courseCode } = course;
    const courseTitle = `${title}${!isEmpty(subtitle)? `: ${subtitle}` : ''}`;
    const data = { 
      type, 
      slug: slug.replace('course-', ''), 
      courseId, 
      track, 
      title, 
      details: { title: courseTitle, transcriptCode, courseCode } 
    };
    onComplete(data);
  };

  const validate = (data) => {
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'type' },
      { rule: validator.rules.IS_DEFINED, prop: 'slug' },
      { rule: validator.rules.IS_DEFINED, prop: 'track' },
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      setFormErrors(validationErrors);
      return false;
    }

    return true;
  };

  const clearPopMessage = () => {
    setDataError(null);
  };

  return (
    <div className="CourseSelection">
      { loading && <LoadingMessage message='Loading...' /> }
      { !loading && isDefined(dataError) &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>An error occurred while attempting to retrieve the current course list.</p>
        </PopMessage>
      }
      { !loading && isDefined(courseDropdown) &&
        <Fragment>
          { isDefined(profile) && 
            <div className="current-selections">
              <p className="field">
                <span className="label">Selected Member:</span>
                <span className="value">
                  {`${profile.firstName} ${profile.lastName}`}
                  <Button variant="text" color="secondary" onClick={changeMember}>
                    Change Member
                  </Button>
                </span>
              </p>
            </div>
          }
          <FormWrapper squared={true} raised={true}>
            <h2>{heading}</h2>
            <Form id='CourseSelection-form' onSubmit={onSubmit} autoComplete="off" submitLabel="Next">

              { isNotDefined(type) &&
                <FormField fieldName="type" label="Course Type"
                  error={validator.message(formErrors['type'], 'course type')}>
                  <Select name="type" value={fields.type} onChange={onFieldChange}
                    error={isDefined(formErrors['type'])}>
                    <MenuItem value="live">Live</MenuItem>
                    <MenuItem value="self-paced">Self-Paced</MenuItem>
                  </Select>
                </FormField>
              }

              <FormField fieldName="slug" label="Course"
                error={validator.message(formErrors['slug'], 'course')}>
                <Select name="slug" value={fields.slug} onChange={onFieldChange}
                  error={isDefined(formErrors['slug'])}>
                  {
                    courseDropdown.map(course => (
                      <MenuItem key={course.slug} value={course.slug}>{course.title}</MenuItem>
                    ))
                  }
                </Select>
              </FormField>
              
              { collectTrack && showTrackSelection() &&
                 <FormField fieldName="track" label="Course Track"
                  error={validator.message(formErrors['track'], 'course track')}>
                  <Select name="track" value={fields.track} onChange={onFieldChange}
                    error={isDefined(formErrors['track'])}>
                    <MenuItem value="certificate">Certificate</MenuItem>
                    <MenuItem value="audit">Audit</MenuItem>
                  </Select>
                </FormField>
              }
            </Form>
          </FormWrapper>
        </Fragment>
      }
    </div>
  );
}

CourseSelection.defaultProps = {
  heading: 'Course Selection',
  collectTrack: true
};

CourseSelection.propTypes = {
  type: PropTypes.string,
  profile: PropTypes.object,
  changeMember: PropTypes.func,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courses: state.courses
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    courseContentAPI: bindActionCreators(courseContentAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSelection);
