import state from '../config/state';
const { authStatus, responseStatus, messageTypes } = state;

const initialState = {
  appDisplay: {
    pageMessage: {
      time: 0,
      message: '',
      type: messageTypes.INFO
    }
  },
  attendance: {
    responseStatus: responseStatus.DEFAULT,
    responseErr: null,
    jobId: null
  },
  courseProgress: {
    ids: [],
    byId: {}
  },
  courseRegistrations: {
    ids: [],
    byId: {}
  },
  courses: {
    ids: [],
    byId: {}
  },
  jobs: {
    exportIds: [],
    exportById: {},
    jobIds: [],
    jobById: {}
  },
  magdalaCommunities: {
    ids: [],
    byId: {}
  },
  magdalaCommunityUpdates: {
    ids: [],
    byId: {}
  },
  magdalaRegistrations: {
    ids: [],
    byId: {}
  },
  marketingTags: {
    responseStatus: responseStatus.DEFAULT,
    responseMessage: null,
    responseCode: null,
    tags: null
  },
  notifications: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    jobId: null,
    emailCategories: null,
    categoryTags: null
  },
  profiles: {
    ids: [],
    byId: {}
  },
  profileUpdates: {
    ids: [],
    byId: {}
  },
  user: {
    responseStatus: authStatus.DEFAULT,
    responseCode: null,
    authenticated: false,
    data: {
      email: '',
      firstName: '',
      lastName: '',
      roles: null,
      access: null
    }
  }
};

export default initialState;