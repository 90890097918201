import axios from 'axios';
import urls from '../../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function createRegistration(type, data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/registration/${type}`, data, { timeout: 25000 });
}

export async function updateRegistrationTrack(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/registration/track`, data, { timeout: 25000 });
}

export async function removeWaitlistingStatus(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/course/registration/waitlisting`, data, { timeout: 25000 });
}
