import NumberFormat from 'react-number-format';

export default function NumberFormatCustom(props) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={true}
      isNumericString={true}
      prefix="$"
    />
  );
};