import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import validator from '../../../utils/validator';


function DisplayAddress(props) {
  const { profile } = props;
  let address = (profile.addresses && profile.addresses.length > 0)? profile.addresses[0] : {};
  let hasAddress = (profile.addresses && profile.addresses.length > 0);
  
  return (
    <Fragment>
      { hasAddress? (
          <Fragment>
            { validator.isNotEmpty(address.addressLine1) && <span>{address.addressLine1}</span> }
            { validator.isNotEmpty(address.addressLine2) && <span>{address.addressLine2}</span> }
            { validator.isNotEmpty(address.addressLine3) && <span>{address.addressLine3}</span> }
            { (address.city || address.state || address.postalCode) &&
              <span>
                { validator.isNotEmpty(address.city) && validator.isNotEmpty(address.state) && `${address.city} ${address.state}` }
                { validator.isNotEmpty(address.postalCode) && `, ${address.postalCode}` }
              </span>
            }
            { validator.isNotEmpty(address.country) && <span>{address.country}</span> }
          </Fragment>
        ) : (
          <p>none listed</p>
        )
      }
    </Fragment>
  );
}

DisplayAddress.propTypes = {
  profile: PropTypes.object
};

export default DisplayAddress;