import React, { Fragment } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class PopMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;
    const { open: prevOpen } = prevProps;
    if(open !== prevOpen) {
      this.setState({ open });
    }
  }

  onClose(event, reason) {
    this.setState({ open: false });
    this.props.onClose();
  }

  render() {
    const { open } = this.state;
    const { type, vertical, horizontal, children, action } = this.props;
    return (
      <Snackbar
        className={`PopMessage ${type}`}
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={open}
        onClose={this.onClose}
        message={
          <Fragment>
            { children }
            { action && <p className="actions">{ action }</p> }
          </Fragment>
        }
        action={[
          <IconButton key="close" onClick={this.onClose}><CloseIcon /></IconButton>,
        ]}
      />
    );
  }
}

PopMessage.defaultProps = {
  type: 'info',
  vertical: 'top',
  horizontal: 'left',
  open: false,
  onClose: () => {},
  action: null
};

export default PopMessage;