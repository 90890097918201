import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon, { IconTypes } from '../../common/Icon';
import config from '../../../config/config';
import { formatDateTimeEST } from '../../../utils/format';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function TranscriptRecords({ transcripts, statuses, onSelection }) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    if(isEmpty(records)) {
      const arr = transcripts.filter(transcript => statuses.includes(transcript.status));
      setRecords(arr);
    }
  }, [transcripts, statuses, records]);

  const renderProgress = record => {
    if(statuses.includes('complete')) return (<Fragment></Fragment>);

    const registration = record.registration;
    if(record.status === 'pending') {
      return (
        <p className="progress">
          <span className="text error">
            { registration && registration.status? registration.status.message : 'Registration error' }
          </span>
        </p>
      );
    }

    return (
      <p className="progress">
        <span className="label">Progress:</span>
        <span className="text">
          {`${record.progress.percent}% (${record.progress.complete} of ${record.progress.steps} steps)`}
        </span>
      </p>
    );
  };

  const renderDate = record => {
    const dt = statuses.includes('complete')? record.completed : record.lastUpdated;
    return (
      <div className="date">
        <span className="label">{statuses.includes('complete')? 'Completed:' : 'Last Updated:'}</span>
        <span className="text">{formatDateTimeEST(dt)}</span>
      </div>
    );
  };

  const hasRecords = !isEmpty(records) && records.length > 0;

  return (
    <div className={`TranscriptRecords ${hasRecords? 'records' : 'no-records'}`}>
      { hasRecords &&
        <Fragment>
          <h3>{statuses.includes('started')? 'Courses In Progress' : 'Completed Courses'}</h3>
          {
            records.map((record, index) => {
              return (
                <div key={`record-${index}`} className="record">
                  <Button variant="text" className="title" onClick={onSelection(record)} color="primary">
                    { statuses.includes('complete') && record.track === 'certificate' && 
                      <Icon type={IconTypes.Certificate} /> 
                    }
                    { record.status === config.courseRecordOnly &&
                      <span>{record.details.title} (Archived)</span>
                    }
                    { record.status !== config.courseRecordOnly &&
                      <span>
                        {record.details.title}
                      </span>
                    }
                  </Button>
                  <div className="description">
                    <span className="label">Type:</span>
                    <span className="text">
                      {record.type === 'live'? 'Live' : 'Self-Paced'} {record.track === 'audit'? 'Audit' : 'Certificate'} Course 
                    </span>
                  </div>
                  <div className="date-details">
                    {renderDate(record)}
                  </div>
                  <div className="details">
                    {renderProgress(record)}
                  </div>
                </div>
              );
            })
          }
        </Fragment>
      }
    </div>
  );
}

TranscriptRecords.propTypes = {
  transcripts: PropTypes.array,
  statuses: PropTypes.array,
  onSelection: PropTypes.func
};

export default TranscriptRecords;
