import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import * as authentication from '../../actions/authentication';
import LoginCredentialsForm from './LoginCredentialsForm';
import Icon, { IconTypes } from '../common/Icon';
import PopMessage from '../common/messages/PopMessage';
import LinkButton from '../common/LinkButton';
import LoadingMessage from '../common/messages/LoadingMessage';
import errors from '../../config/errors';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import { resetViewToTopOfPage } from '../../utils/utils';


class Login extends React.Component {
  constructor(props) {
    super(props);
    resetViewToTopOfPage();

    this.state = {
      loading: true,
      submission: false,
      completeRedirect: null,
      popMessage: null
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidMount() {
    this.props.authentication.pingAuthStatus();
  }

  componentDidUpdate(prevProps) {
    const { responseStatus, responseCode, authenticated } = this.props;
    const { responseStatus: prevResponseStatus } = prevProps;
    const { authStatus } = stateConfig;

    if(responseStatus !== prevResponseStatus) {
      if(responseStatus === authStatus.ERROR) {
        this.setState({ submission: false, loading: false });

        if(responseCode === errors.CredentialFailure.code) {
          this.popErrorMessage('Either the email or password that you entered is incorrect.');
        } else if(responseCode === errors.AuthenticationError.code) {
          this.popErrorMessage('A failure occurred during the login process. If this problem persists please reach out to a staff member.');
        } else {
          this.setState({ submission: false });
        }
      }

      if(authenticated && responseStatus === authStatus.AUTH_COMPLETE) {
        this.setState({ completeRedirect: urls.dashboard });
      }
    }
  }

  popErrorMessage(message) {
    this.setState({ popMessage: { message, error: true } });
  }

  clearPopMessage() {
    this.setState({ popMessage: null });
  }

  handleLogin(data) {
    this.setState({ submission: true });
    this.props.authentication.login(data);
  }

  render() {
    const { loading, submission, completeRedirect, popMessage } = this.state;

    return (
      <div id="Login-container">
        { completeRedirect && <Redirect exact to={completeRedirect} /> }

        <Paper id="Login">
          <Icon avatar={true} type={IconTypes.Lock} />
          <h2>Admin Login</h2>
          
          { loading && <LoadingMessage message="Loading..." /> }

          { popMessage && popMessage.error &&
            <PopMessage type="error" horizontal="center" open={true} onClose={this.clearPopMessage}>
              <p>{popMessage.message}</p>
            </PopMessage>
          }

          <LoginCredentialsForm onSubmit={this.handleLogin} submission={submission} />
        </Paper>

        { !loading &&
          <div id="Login-passwordReset">
            <LinkButton label="Forgot your password?" to={urls.pwdResetRequest} external={true} color="secondary" />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { responseStatus, responseCode, authenticated, data: { email, firstName, lastName } } = state.user;
  return { responseStatus, responseCode, authenticated, email, firstName, lastName };
}

function mapDispatchToProps(dispatch) {
  return { 
    authentication: bindActionCreators(authentication, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);