import React from 'react';
import { Route } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import Login from '../authentication/Login';
import DeniedAccess from '../route/DeniedAccess';
import config from '../../config/config';


const PrivateRouter = ({ component: Component, ...rest }) => (
  <UserContext.Consumer>
    { user => (
      <Route {...rest} render={ props => {
        const { pathname } = props.location;
        if(user.authenticated) {
          const routeAccess = config.routeAccess[pathname];
          if(!routeAccess && user.data.access === config.accessRoles.ADMIN) {
            // only allowed to admins
            return (<Component {...props} />);
          } else if(routeAccess && routeAccess.includes(user.data.access)) {
            // they are permitted this access
            return (<Component {...props} />);
          } else {
            // not authorized
            return (<DeniedAccess />)
          }
        }

        // not authenticated
        return (<Login />);
      }} />
    )}
  </UserContext.Consumer>
);

export default PrivateRouter;