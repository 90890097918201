import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import MemberSearch from '../common/member/MemberSearch';
import CommunityDisplay from './registration/CommunityDisplay';
import CompleteRegistration from './registration/CompleteRegistration';
import urls from '../../config/urls';
import validator from '../../utils/validator';


class MagdalaRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedCommunity: false,
      completed: false,
      profile: null
    };

    this.changeMember = this.changeMember.bind(this);
    this.onSearchComplete = this.onSearchComplete.bind(this);
    this.onCommunityDisplay = this.onCommunityDisplay.bind(this);
    this.onRegistrationComplete = this.onRegistrationComplete.bind(this);
  }

  changeMember() {
    this.setState({ profile: null });
  }

  onSearchComplete(profile) {
    this.setState({ profile });
  }

  onCommunityDisplay() {
    this.setState({ displayedCommunity: true });
  }

  onRegistrationComplete() {
    this.setState({ completed: true });
  }

  render() {
    const { displayedCommunity, completed, profile } = this.state;
    return (
      <div className="MagdalaRegistration">
        { !validator.isDefined(profile) &&
          <MemberSearch onComplete={this.onSearchComplete} />
        }
        { validator.isDefined(profile) && !completed &&
          <Fragment>
            <CommunityDisplay profile={profile} changeMember={this.changeMember} onComplete={this.onCommunityDisplay} />
            { displayedCommunity && 
              <CompleteRegistration profile={profile} onComplete={this.onRegistrationComplete} /> 
            }
          </Fragment>
        }
        { completed &&
          <div className="completed">
            <Link className="dashboard-link" to={urls.dashboard}>Return to the dashboard</Link>
          </div>
        }
      </div>
    );
  }
}

export default withRouter(MagdalaRegistration);