import { PROGRESS_LOAD_START, PROGRESS_LOAD_COMPLETE, PROGRESS_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function courseProgressReducer(state = initialState.courseProgress, action) {
  const progress = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case PROGRESS_LOAD_START:
      clearStateById(progress.ids, progress.byId, stateId);
      return progress;
    case PROGRESS_LOAD_COMPLETE:
      progress.ids.push(stateId);
      progress.byId[stateId] = action.data;
      return progress;
    case PROGRESS_LOAD_ERR:
      progress.ids.push(stateId);
      progress.byId[stateId] = { error: action.error };
      return progress;
    default:
      return state;
  }
}

export default courseProgressReducer;