import { MARKETING_TAGS_REQUEST, MARKETING_TAGS_COMPLETE, MARKETING_TAGS_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function marketingTagsReducer(state = initialState.marketingTags, action) {
  const { type, data, error = {} } = action;
  let responseMessage, responseCode, status = stateConfig.responseStatus;
    
  switch(type) {
    case MARKETING_TAGS_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, tags: null });
    case MARKETING_TAGS_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, tags: data.tags });
    case MARKETING_TAGS_ERR:
      ({ message: responseMessage, code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseMessage, responseCode });
    default:
      return state;
  }
}

export default marketingTagsReducer;