import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdateMainContact extends React.Component {
  constructor(props) {
    super(props);
    const { mainContactName, mainContactEmail } = props.community.communityContact || {};
    this.state = {
      submission: false,
      fields: {
        name: mainContactName || '',
        email: mainContactEmail || ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdateMainContact-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

          <FormField fieldName="name" label="Main Contact Name" 
            error={validator.message(errors['name'], 'name')}>
            <Input name="name" placeholder='Main Contact Name' value={fields.name} 
              onChange={this.onFieldChange} error={validator.isDefined(errors['name'])} />
          </FormField>

          <FormField fieldName="email" label="Main Contact Email"
            error={validator.message(errors['email'], 'email')}>
            <Input name="email" placeholder='Email' value={fields.email}
              onChange={this.onFieldChange} error={validator.isDefined(errors['email'])} />
          </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdateMainContact.propTypes = {
  community: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateMainContact;