import { MARKETING_TAGS_REQUEST, MARKETING_TAGS_COMPLETE, MARKETING_TAGS_ERR } from '../actionTypes';
import * as management from '../../api/content/management';


export function getMarketingTags() {
  return async dispatch => {
    dispatch({ type: MARKETING_TAGS_REQUEST });
    try {
      let result = await management.getMarketingTags();
      dispatch({ type: MARKETING_TAGS_COMPLETE, data: result.data });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: MARKETING_TAGS_ERR, error: err });
    }
  };
}

export function syncMarketingTags() {
  return async dispatch => {
    dispatch({ type: MARKETING_TAGS_REQUEST });
    try {
      let result = await management.syncMarketingTags();
      dispatch({ type: MARKETING_TAGS_COMPLETE, data: result.data });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: MARKETING_TAGS_ERR, error: err });
    }
  };
}
