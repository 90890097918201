import { AUTH_START, AUTH_COMPLETE, AUTH_ERR, AUTH_STATUS_RECEIVED, AUTH_STATUS_ERR  } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function userReducer(state = initialState.user, action) {
  const user = { ...state };
  const { type, error = {} } = action;
  const userData = action.data || {};
  let responseCode, status = stateConfig.authStatus;

  switch(type) {
    case AUTH_START:
      user.responseStatus = status.DEFAULT;
      user.responseCode = null;
      return user;
    case AUTH_STATUS_RECEIVED:
    case AUTH_COMPLETE:
      user.responseStatus = status.AUTH_COMPLETE;
      user.responseCode = null;
      user.authenticated = true;
      user.data = Object.assign({}, user.data, userData);
      return user;
    case AUTH_STATUS_ERR:
    case AUTH_ERR:
      ({ code: responseCode } = error);
      user.responseStatus = status.ERROR;
      user.responseCode = responseCode;
      user.authenticated = false;
      user.data = { ...initialState.user.data };
      return user;
    default:
      return state;
  }
}

export default userReducer;