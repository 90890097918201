import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as jobsAPI from '../../../actions/jobs';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';


function JobStatusMessage({ jobId, type, heading, onStatusUpdate, onComplete, jobs, jobsAPI }) {
  const [init, setInit] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState(null);
  const [processed, setProcessed] = useState(0);
  const [percent, setPercent] = useState(0);
  const [complete, setComplete] = useState(false);

  const checkJobStatus = useCallback(() => {
    const t = setTimeout(() => {
      if(!complete) {
        if(type === 'export') {
          jobsAPI.checkExportStatus(jobId);
        } else {
          jobsAPI.checkJobStatus(jobId);
        }
        checkJobStatus();
      }
    }, 5000);
    setTimeoutRef(t);
  }, [jobId, jobsAPI, type, complete]);

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      checkJobStatus();
      setInit(true);
    }
  }, [init, checkJobStatus]);

  useEffect(() => {
    let data = null;
    if(timeoutRef && type === 'export' && jobs.exportIds) {
      data = jobs.exportById[`${stateConfig.keys.EXPORT}.${jobId}`];
    } else if(timeoutRef && jobs.jobIds) {
      data = jobs.jobById[`${stateConfig.keys.JOBS}.${jobId}`];
    }
    
    if(data) {
      if(!data.jobId) {
        onComplete({ success: false, message: 'Failed to retrieve job status' });
        clearTimeout(timeoutRef);
      } else if(!data.completed && data.status === 'running' && data.progress) {
        setPercent(data.progress.percentage);
        setProcessed(data.progress.processed);
      } else if(data.completed && data.status === 'success') {
        setComplete(true);
        setPercent(data.progress.percentage);
        setProcessed(data.progress.processed);
        onComplete({ success: true, data });
        clearTimeout(timeoutRef);
      } else if(data.status === 'error') {
        let err = data.error || { message: 'This job failed to complete properly. Please contact the system administrator.' };
        onComplete({ success: false, message: `${err.message}${err.details? `: ${err.details}` : ''}` });
        clearTimeout(timeoutRef);
      }

      if(!data.error && !data.completed && data.status) {
        if(onStatusUpdate) {
          onStatusUpdate(data);
        }
      }
    }
  }, [jobId, type, timeoutRef, jobs.jobIds, jobs.jobById, jobs.exportIds, jobs.exportById, onStatusUpdate, onComplete]);

  return (
    <div className="JobStatusMessage">
      <p className="heading-message">{`${heading} (ID: ${jobId})...`}</p>
      <div className="JobStatusMessage-progress">
        <div className="background"></div>
        <div className="progress" style={{width: `${percent}%`}}></div>
        <div className="progress-data">{`${processed} records processed. ${percent}% complete`}</div>
      </div>
    </div>
  );
}

JobStatusMessage.defaultProps = {
  heading: 'Processing job',
  type: 'job'
};

JobStatusMessage.propTypes = {
  jobId: PropTypes.string,
  onStatusUpdate: PropTypes.func,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return {
    jobs: state.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    jobsAPI: bindActionCreators(jobsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusMessage);
