import React from 'react';
import Icon, { IconTypes } from '../common/Icon';


function DeniedAccess(props) {
  return (
    <div id="DeniedAccess">
      <div className="DeniedAccess-aesthetic">
        <Icon type={IconTypes.Warning} size="6x" />
        <div className="DeniedAccess-aesthetic-content">
          <p className="DeniedAccess-403">403</p>
          <p className="DeniedAccess-message">Not Permitted</p>
        </div>
      </div>
      <p className="DeniedAccess-descriptive">
        <strong>Uh Oh!</strong> You do not have access to this feature.
      </p>
    </div>
  );
}

export default DeniedAccess;