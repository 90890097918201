import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as profileAPI from '../../../actions/profile';
import * as appDisplay from '../../../actions/appDisplay';
import Icon, { IconTypes} from '../../common/Icon';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import DisplayAddress from '../../common/member/DisplayAddress';
import MemberEdit from './MemberEdit';
import MemberUpdateResults from './edit/UpdateResults';
import MemberDeletionConfirmation from './MemberDeletionConfirmation';
import stateConfig from '../../../config/state';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import { formatDateTimeForDisplay } from '../../../utils/format';


class MemberDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.profile.email,
      newEmail: null,
      processing: false,
      editing: false,
      deleting: false,
      deleted: false,
      editField: null,
      updateResults: null,
      error: null,
      deletionError: null
    };

    this.onEdit = this.onEdit.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onEmailUpdate = this.onEmailUpdate.bind(this);
    this.onCloseResultsMessages = this.onCloseResultsMessages.bind(this);
    this.activateMember = this.activateMember.bind(this);
    this.onStartDelete = this.onStartDelete.bind(this);
    this.onDeletion = this.onDeletion.bind(this);
    this.onCancelDeletion = this.onCancelDeletion.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.updates;
    const { email, newEmail } = this.state;
    const key = newEmail || email;
    const prevIds = prevProps.updates.ids;
    const update = byId[`${stateConfig.keys.PROFILE_UPDATES}.${key}`];
    
    if(ids !== prevIds && update) {
      if(update.error && !update.error.failedEmail) {
        this.setState({ processing: false, error: update.error });
      } else if(update.error && update.error.failedEmail) {
        this.setState({ updateResults: { ...update.error } });
      } else if(update.activated) {
        resetViewToTopOfPage();
        this.setState({ processing: false });
        this.props.appDisplay.displayPageMessage({
          message: 'Activation Complete!',
          type: stateConfig.messageTypes.SUCCESS,
          time: 5
        });
        this.props.onUpdate();
      } else if(update.updatedPassword) {
        this.props.appDisplay.displayPageMessage({
          message: 'Password Update Complete!',
          type: stateConfig.messageTypes.SUCCESS,
          time: 5
        });
        this.props.onUpdate();
      } else if(update.updatedEmail) {
        this.props.appDisplay.displayPageMessage({
          message: 'Email Update Complete!',
          type: stateConfig.messageTypes.SUCCESS,
          time: 5
        });
        this.setState({ updateResults: { ...update } });
        this.setState({ editing: false, editField: null });
        this.props.onUpdate(update.newEmail);
      } else if(update.updatedProfile) {
        this.props.appDisplay.displayPageMessage({
          message: 'Profile Update Complete!',
          type: stateConfig.messageTypes.SUCCESS,
          time: 5
        });
        this.props.onUpdate();
      }
    }
  }

  onEdit = field => () => {
    this.setState({ editing: true, editField: field });
  }

  onCancelEdit() {
    this.setState({ editing: false, editField: null });
  }

  onEmailUpdate(newEmail) {
    this.setState({ newEmail, updateResults: null });
  }

  onCloseResultsMessages() {
    this.setState({ updateResults: null, newEmail: null, editing: false, editField: null });
  }

  onStartDelete() {
    this.setState({ deleting: true });
  }

  onDeletion({ success, message }) {
    if(success) {
      resetViewToTopOfPage();
      this.setState({ deleting: false, deleted: true });
    } else {
      this.setState({ deleting: false, deletionError: message });
    }
  }

  onCancelDeletion() {
    this.setState({ deleting: false });
  }

  activateMember() {
    const self = this;
    this.setState({ processing: true }, () => {
      self.props.profileAPI.activateMember(this.state.email);
    });
  }

  clearPopMessage() {
    this.setState({ error: null, deletionError: null });
  }

  render() {
    const { processing, editing, deleting, deleted, editField, updateResults, error, deletionError } = this.state;
    const { profile } = this.props;
    const { type, email, salutation, firstName, lastName, legalFirstName, phones, contactId, flags, tracking, createdOn, hasPassword, communityId, donation, previousIdentities } = profile;
    const formattedCreatedOn = formatDateTimeForDisplay(new Date(createdOn));
    const formattedLastLoggedIn = tracking && tracking.lastLoggedIn? formatDateTimeForDisplay(new Date(tracking.lastLoggedIn)) : '';
    const phone = (phones && phones.length > 0)? phones[0] : {};
    const customerIds = donation && donation.customerIds && donation.customerIds.length? donation.customerIds : donation.customerId? [donation.customerId] : [];
    const identities = previousIdentities && previousIdentities.length? previousIdentities : [];

    return (
      <div className="MemberDetails">
        { processing && <div className="center"><LoadingMessage message='Processing...' /></div> }
        { updateResults !== null &&
          <MemberUpdateResults results={updateResults} onMerge={this.onEmailUpdate} onClose={this.onCloseResultsMessages} />
        }
        { !processing && editing &&
          <MemberEdit profile={profile} error={error} field={editField} onCancel={this.onCancelEdit} onEmailUpdate={this.onEmailUpdate} />
        }
        { !processing && !editing && !deleted &&
          <Fragment>
            <div className="back">
              <Button className="back" variant="text" onClick={this.props.onBack}>
                <Icon type={IconTypes.ArrowBack} /> Select a different member
              </Button>
            </div>
            <h2>Member Details</h2>
            <p className="instructions">Linked/clickable fields can be edited.</p>
            <div className="section main">
              <div className="field editable" onClick={this.onEdit('email')}>
                <div className="label">Email</div>
                <div className="value">{email}</div>
              </div>
              <div className="field editable" onClick={this.onEdit('contactId')}>
                <div className="label">Contact ID</div>
                <div className="value">{contactId}</div>
              </div>
              <div className="field editable" onClick={this.onEdit('name')}>
                <div className="label">Name</div>
                <div className="value">{`${salutation? `${salutation} ` : ''}${firstName? `${firstName} ` : ''}${legalFirstName? `${legalFirstName} `: ''}${lastName}`}</div>
              </div>
              <div className="field editable" onClick={this.onEdit('address')}>
                <div className="label">Address</div>
                <div className="value">
                  <DisplayAddress profile={profile} />
                </div>
              </div>
              <div className="field editable" onClick={this.onEdit('phone')}>
                <div className="label">Phone</div>
                <div className="value">{`${phone.number? `${phone.number}` : 'none listed'} ${phone.type? `(${phone.type})` : ''}`}</div>
              </div>
              <div className="field">
                <div className="label">Account Type</div>
                <div className="value">
                  {displayMemberType(type)}
                  { communityId && 
                    <div><Link to={`${urls.magdalaCommunityManage}?communityId=${communityId}`}>View Community</Link></div>
                  }
                </div>
              </div>
              <div className="field">
                <div className="label">Account Status</div>
                <div className="value">{flags.verified? 'Active' : 'Not Activated'}</div>
              </div>
              <div className="field">
                <div className="label">Membership Source</div>
                <div className="value">{tracking && tracking.source? tracking.source : 'Not recorded'}</div>
              </div>
              <div className="field">
                <div className="label">Created Date</div>
                <div className="value">{formattedCreatedOn}</div>
              </div>
              <div className="field">
                <div className="label">Password Status</div>
                <div className="value">{hasPassword? 'Has Password' : 'No Password Set'}</div>
              </div>
              <div className="field">
                <div className="label">Last Logged In</div>
                <div className="value">{tracking && tracking.lastLoggedIn? formattedLastLoggedIn : 'Not recorded'}</div>
              </div>
              <div className="field">
                <div className="label">Stripe Customer IDs</div>
                <div className="value">
                  { customerIds.map((cId, index) => {
                      const display = `${cId}${index === 0? ' (default)' : ''}`;
                      return (<div key={`cid-${index}`} className="customerId">{display}</div>);
                    }) 
                  }
                </div>
              </div>
              <div className="field">
                <div className="label">Previous Identities</div>
                <div className="value">
                  {
                    identities.map((identity, index) => {
                      const changed = identity.changed? new Date(identity.changed) : null;
                      let dateDisplay = changed? formatDateTimeForDisplay(changed) : null;
                      dateDisplay = dateDisplay? dateDisplay.replace(' EST', '') : null;
                      return (
                        <div key={`identity-${index}`} className="identity">
                          {`${identity.email}${dateDisplay? ` - ${dateDisplay}` : '' }`}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div className="actions">
              { !flags.verified &&
                <Button variant="contained" color="secondary" onClick={this.activateMember}>
                  Activate
                </Button>
              }
              <Button variant="outlined" color="default" onClick={this.onEdit('password')}>
                {hasPassword? 'Change Password' : 'Create Password'}
              </Button>
            </div>
            <div className="actions delete">
              <p className="delete-message">
                Warning: member account deletions cannot be reversed.
              </p>
              <Button className="delete" variant="contained" color="default" onClick={this.onStartDelete}>
                Delete Member
              </Button>
            </div>
          </Fragment>
        }
        { deleting &&
          <MemberDeletionConfirmation email={email} onDeletion={this.onDeletion} onCancel={this.onCancelDeletion} />
        }
        { deleted &&
          <div className="member-deleted">
            The membership for <strong>{email}</strong> has been successfully deleted.
          </div>
        }
        { error !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>This update failed: {error.code} - {error.message}</p>
          </PopMessage>
        }
        { deletionError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>{deletionError}</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function displayMemberType(type) {
  switch(type) {
    case 'magdala-member':
      return 'Magdala Membership';
    case 'trial':
      return 'Trial Account';
    default:
      return 'Standard Membership';
  }
}

MemberDetails.propTypes = {
  profile: PropTypes.object,
  onBack: PropTypes.func,
  onUpdate: PropTypes.func
};

function mapStateToProps(state) {
  return {
    updates: state.profileUpdates
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberDetails)
);