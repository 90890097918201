import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

export default function Form(props) {
  return (
    <form id={props.id} className="Form" 
      onSubmit={props.onSubmit} autoComplete={props.autoComplete}>
      {props.children}
      <div className="Form-buttons">
        <Button className="Form-submitButton" type="submit" variant="contained" color="primary"
          disabled={props.submission || props.disabled}>{props.submitLabel}</Button>
        { props.onClickSecondary &&
          <Button className="Form-secondaryButton" color="secondary" onClick={props.onClickSecondary}
            disabled={props.submission}>{props.secondaryButtonLabel}</Button>
        }
        { props.submission &&
          <div className="Form-submitMessage">
            <FontAwesomeIcon icon={faSpinnerThird} spin /> Processing...
          </div>
        }
      </div>
    </form>
  );
}

Form.defaultProps = {
  submission: false,
  disabled: false,
  submitLabel: 'Submit',
  secondaryButtonLabel: 'Cancel'
};

Form.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  onClickSecondary: PropTypes.func,
  autoComplete: PropTypes.string
};
