import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as magdalaAPI from '../../../actions/magdala';
import * as appDisplay from '../../../actions/appDisplay';
import Icon, { IconTypes} from '../../common/Icon';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import ConfirmationDialog from '../../common/dialogs/ConfirmationDialog';
import CommunityMembers from './CommunityMembers';
import CommunityContactDetails from './CommunityContactDetails';
import CommunityEdit from './CommunityEdit';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';
import { resetViewToTopOfPage } from '../../../utils/utils';


class CommunityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      communityId: props.community.communityId,
      processing: false,
      editing: false,
      confirmDeletion: false,
      editField: null,
      fieldRequired: false,
      deleted: false,
      error: null
    };

    this.onEdit = this.onEdit.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.deleteCommunity = this.deleteCommunity.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.communities;
    const prevIds = prevProps.communities.ids;
    const community = byId[`${stateConfig.keys.MAGDALA_COMM}.${this.state.communityId}`];

    if(ids !== prevIds && community) {
      if(community.error) {
        this.setState({ processing: false, error: community.error });
      } else if(community.deleted) {
        this.setState({ processing: false, deleted: true });
      }
    }

    const { ids: updateIds, byId: updatesById } = this.props.updates;
    const prevUpdateIds = prevProps.updates.ids;
    const update = updatesById[`${stateConfig.keys.MAGDALA_COMM_UPDATES}.${this.state.communityId}`];
    
    if(updateIds !== prevUpdateIds && update) {
      if(update.error) {
        this.setState({ processing: false, error: update.error });
      } else {
        this.props.appDisplay.displayPageMessage({
          message: 'Community Update Complete!',
          type: stateConfig.messageTypes.SUCCESS,
          time: 5
        });
        this.props.onUpdate();
      }
    }
  }

  deleteCommunity() {
    this.setState({ confirmDeletion: true });
  }

  onDeleteConfirm(confirmed) {
    const self = this;
    if(confirmed) {
      resetViewToTopOfPage();
      this.setState({ processing: true }, () => {
        self.props.magdalaAPI.deleteCommunity(this.props.community.communityId);
      });
    } else {
      this.setState({ confirmDeletion: false });
    }
  }

  onEdit = (field, required = false) => () => {
    this.setState({ editing: true, editField: field, fieldRequired: required });
  }

  onCancelEdit() {
    this.setState({ editing: false, editField: null, fieldRequired: false });
  }

  clearPopMessage() {
    this.setState({ error: null });
  }

  render() {
    const { community } = this.props;
    const { processing, editing, confirmDeletion, editField, fieldRequired, deleted, error } = this.state;
    let { communityId, communityName, communityContact, firstEnrolled, jabraStatus, notes } = community;
    let displayNotes = validator.isNotEmpty(notes)? notes.replace(/\r?\n|\r/g, '<br />') : notes;
 
    return (
      <div className="CommunityDetails">
        { processing && <div className="center"><LoadingMessage message='Processing...' /></div> }
        { confirmDeletion &&
          <ConfirmationDialog message="Are you sure you want to delete this community?" onClose={this.onDeleteConfirm} />
        }
        { !processing && editing &&
          <CommunityEdit community={community} error={error} field={editField} required={fieldRequired} 
            onCancel={this.onCancelEdit} />
        }
        { !processing && !editing &&
          <Fragment>
            <div className="back">
              <Button className="back" variant="text" onClick={this.props.onBack}>
                <Icon type={IconTypes.ArrowBack} /> Select a different community
              </Button>
            </div>
            { !deleted &&
              <Fragment>
                <h2>Community Details</h2>
                <p className="instructions">Linked/clickable fields can be edited.</p>
                <div className="section main">
                  <div className="field editable" onClick={this.onEdit('communityName', true)}>
                    <div className="label">Name</div>
                    <div className="value">{communityName}</div>
                  </div>
                  <div className="field editable" onClick={this.onEdit('firstEnrolled')}>
                    <div className="label">First Enrolled</div>
                    <div className="value">{firstEnrolled? `${firstEnrolled.semester} ${firstEnrolled.year}` : 'None listed' }</div>
                  </div>
                  <div className="field editable" onClick={this.onEdit('jabraStatus')}>
                    <div className="label">Jabra Status</div>
                    <div className="value">{displayJabraStatus(jabraStatus) || 'None listed'}</div>
                  </div>
                </div>
                <div className="section contact">
                  <CommunityContactDetails data={communityContact} onEdit={this.onEdit} />
                </div>
                <div className="section notes">
                  <h3>Notes</h3>
                  { validator.isNotEmpty(displayNotes)? <p dangerouslySetInnerHTML={{__html: displayNotes}} /> : <p>None</p> }
                </div>
                <div className="section members">
                  <h3>Members</h3>
                  <CommunityMembers communityId={communityId} />
                </div>
                <div className="actions">
                  <Button variant="contained" color="secondary" onClick={this.deleteCommunity}>
                    Delete this community
                  </Button>
                  <Button variant="outlined" color="default" onClick={this.onEdit('notes')}>
                    Edit Notes
                  </Button>
                </div>
              </Fragment>
            }
            { deleted &&
              <p className="deleted-message">This community has been deleted.</p>
            }
          </Fragment>
        }
        { error !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>This update failed: {error.code} - {error.message}</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function displayJabraStatus(status) {
  switch(status) {
    case 'mailed':
      return 'Mailed';
    case 'returned':
      return 'Returned';
    case 'not-applicable':
      return 'Not Applicable';
    default:
      return status;
  }
}

CommunityDetails.propTypes = {
  community: PropTypes.object,
  onBack: PropTypes.func,
  onUpdate: PropTypes.func
};

function mapStateToProps(state) {
  return {
    communities: state.magdalaCommunities,
    updates: state.magdalaCommunityUpdates
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityDetails);