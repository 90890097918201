import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getCommunities() {
  return await axios.get(`${urls.adminHubUrl}/ui/api/magdala/communities`);
}

export async function getCommunity(communityId) {
  return await axios.get(`${urls.adminHubUrl}/ui/api/magdala/communities/${communityId}`);
}

export async function getCommunityMembers(communityId) {
  return await axios.get(`${urls.adminHubUrl}/ui/api/magdala/communities/${communityId}/members`);
}

export async function createCommunity(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/magdala/communities`, data);
}

export async function updateCommunity(communityId, data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/magdala/communities/${communityId}`, data);
}

export async function deleteCommunity(communityId) {
  return await axios.delete(`${urls.adminHubUrl}/ui/api/magdala/communities/${communityId}`);
}

export async function getRegistration(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/magdala/registration/find`, data);
}

export async function updateRegistration(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/magdala/registration`, data);
}

export async function deleteRegistration(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/magdala/registration/delete`, data);
}
