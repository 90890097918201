import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import querystring from 'qs';
import Button from '@material-ui/core/Button';
import * as jobsAPI from '../../actions/jobs';
import PopMessage from '../common/messages/PopMessage';
import LoadingMessage from '../common/messages/LoadingMessage';
import stateConfig from '../../config/state';
import urls from '../../config/urls';


function DownloadReport({ jobs, jobsAPI, location }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [jobId, setJobId] = useState(null);
  const [found, setFound] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!init) {
      const params = querystring.parse(location.search.substring(1));
      setJobId(params.id);
      jobsAPI.checkExportStatus(params.id);
      setInit(true);
    }
  }, [init, jobsAPI, location]);

  useEffect(() => {
    const data = jobs.exportById[`${stateConfig.keys.EXPORT}.${jobId}`];
    if(loading && jobs.exportIds && data) {
      if(!data.jobId) {
        setError('Either this report is no longer available, or the report details failed to be retrieved.');
      } else if(data.status === 'error') {
        let err = data.error || { message: 'This job failed to complete properly. Please contact the system administrator.' };
        setError(`${err.message}${err.details? `: ${err.details}` : ''}`);
      } else {
        setFound(true);
      }
      setLoading(false);
    }
  }, [jobId, loading, jobs.exportIds, jobs.exportById]);

  const downloadReport = () => {
    window.open(`${urls.adminHubUrl}/ui/api/export/${jobId}`, 'StudentTranscriptExport', 'noopener noreferrer');
  };

  const clearPopMessage = () => {
    setError(null);
  }

  return (
    <div className="Report DownloadReport">
      { loading && <LoadingMessage message='Loading...' /> }
      { !loading && found &&
        <Fragment>
          <p className="message">Your report is available for download.</p>
          <Button variant="contained" color="primary" onClick={downloadReport}>
            Download
          </Button>
        </Fragment>
      }
      { error !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{error}</p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    jobs: state.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    jobsAPI: bindActionCreators(jobsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReport);
