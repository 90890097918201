import axios from 'axios';
import querystring from 'qs';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

export async function pingAuthStatus() {
  return await axios.get(`${urls.adminHubUrl}/auth/ping`);
}

export async function login(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.adminHubUrl}/auth/login`, params, defaultConfig);
}