import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as coursesAPI from '../../../actions/content/courses';
import * as progressAPI from '../../../actions/courses/progress';
import Icon, { IconTypes } from '../../common/Icon';
import PopMessage from '../../common/messages/PopMessage';
import LoadingMessage from '../../common/messages/LoadingMessage';
import TranscriptHeading from './TranscriptHeading';
import TranscriptRecords from './TranscriptRecords';
import RecordDetails from './RecordDetails';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function TranscriptContent({ profile, onReset, courses, courseProgress, coursesAPI, progressAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcripts, setTranscripts] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      progressAPI.getCourseTranscripts(profile.email);
      progressAPI.getProgressSummary(profile.email);
      coursesAPI.getCourses();
      setInit(true);
      setTimeout(() => setLoading(true), 250);
    }
  }, [init, profile, progressAPI, coursesAPI]);

  useEffect(() => {
    if(loading && isNotDefined(transcripts)) {
      const email = profile.email;
      const transcriptId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.${email}.all`;
      const progressId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.${email}.progress`;
      const coursesId = `${stateConfig.keys.COURSE_LIST}.summary`;
      const tData = courseProgress.ids.includes(transcriptId)? courseProgress.byId[transcriptId] : null;
      const pData = courseProgress.ids.includes(progressId)? courseProgress.byId[progressId] : null;
      const cData = courses.ids.includes(coursesId)? courses.byId[coursesId] : null;
      if(tData && pData && cData) {
        if(tData.error || pData.error || cData.error) {
          setError({ display: true });
        } else {
          const records = tData.transcripts.map(transcript => {
            let record = { ...transcript, certificate: null, progress: { complete: 0, steps: 0, percent: 0 } };
            let progress = pData.progress? pData.progress[record.courseId] : null;
            if(progress) {
              record.progress.complete = progress.complete;
            }
            let course = cData.results.find(result => result.id === record.courseId);
            if(course) {
              record.supportedTracks = course.tracks;
              record.certificate = course.certificate;
              record.progress.steps = record.track === 'certificate'? course.certificateSteps : course.auditSteps;
            }
            record.progress.percent = record.progress.steps > 0? parseInt((record.progress.complete / record.progress.steps) * 100) : 0;
            return record;
          });
          let record = null;
          if(isDefined(selectedRecord)) {
            record = records.find(item => item.slug === selectedRecord.slug && item.status === selectedRecord.status);
          }
          setTranscripts(records);
          setSelectedRecord(record);
          setLoading(false);
        }
      }
    }
  }, [loading, profile, transcripts, selectedRecord, courseProgress.ids, courseProgress.byId, courses.ids, courses.byId]);

  const selectRecord = record => () => {
    setSelectedRecord(record);
  };

  const clearSelectedRecord = () => {
    setSelectedRecord(null);
  };

  const clearPopMessage = () => {
    setError({ display: false });
    setDeleteError({ display: false });
  };

  const onUpdate = () => {
    setTranscripts(null);
    progressAPI.getCourseTranscripts(profile.email);
    progressAPI.getProgressSummary(profile.email);
    setTimeout(() => setLoading(true), 250);
  };

  const onDeletion = () => {
    setSelectedRecord(null);
    onUpdate();
  };

  return (
    <div className="Transcript-container">
      { isNotDefined(transcripts) && isNotDefined(error) &&
        <LoadingMessage message="Loading..." />
      }
      { isDefined(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load this resource.</p>
        </PopMessage>
      }
      { isDefined(deleteError) && deleteError.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>An error occurred while attempting to delete the selected course record.</p>
        </PopMessage>
      }
      { isDefined(transcripts) &&
        <div className={`Transcript-content ${isDefined(selectedRecord)? 'record' : 'full'}`}>
          <h1>Student Transcript</h1>
          { isNotDefined(selectedRecord) &&
            <div className="return">
              <Button variant="text" onClick={onReset} color="primary">
                <Icon type={IconTypes.ArrowBack} /> Back to student search
              </Button>
            </div>
          }
          { isDefined(selectedRecord) &&
            <div className="return">
              <Button variant="text" onClick={clearSelectedRecord} color="primary">
                <Icon type={IconTypes.ArrowBack} /> Back to full transcript
              </Button>
            </div>
          }
          <TranscriptHeading profile={profile} transcripts={transcripts} />
          { transcripts.length === 0 &&
            <p className="no-transcript">This student has not registered for any courses.</p>
          }
          { isNotDefined(selectedRecord) &&
            <div className={`Transcript-recordList ${transcripts.length === 0? 'no-records' : 'has-records'}`}>
              <TranscriptRecords 
                profile={profile} 
                transcripts={transcripts} 
                statuses={config.courseNotComplete} 
                onSelection={selectRecord} 
              />
              <TranscriptRecords 
                profile={profile} 
                transcripts={transcripts} 
                statuses={config.courseComplete} 
                onSelection={selectRecord} 
              />
            </div>
          }
          { isDefined(selectedRecord) &&
            <RecordDetails record={selectedRecord} profile={profile} onUpdate={onUpdate} onDeletion={onDeletion} />
          }
        </div>
      }
    </div>
  );
}

TranscriptContent.propTypes = {
  profile: PropTypes.object,
  onReset: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TranscriptContent)
);