import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import validator from '../../utils/validator';


class LoginCredentialsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        password: ''
      },
      errors: {}
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleFieldChange(event) {
    const fields = Object.assign({}, this.state.fields);
    fields[event.target.name] = event.target.value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();
    if(!this.validate()) {
      return;
    }

    const authData = Object.assign({}, this.state.fields);
    this.props.onSubmit(authData);
  }

  validate() {
    const data = this.state.fields;
    const validationErrors = validator.validate(data, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.PASSWORD_CHECK, prop: 'password', empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'password' }
    ]);

    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false
    }

    return true;
  }

  render() {
    const { submission } = this.props;
    const { fields, errors } = this.state;

    return (
      <Form id="LoginCredentialsForm" onSubmit={this.onSubmit} submitLabel="Log In" submission={submission}>
        <FormField className="first" fieldName="email" label="Email *"
          error={validator.message(errors['email'], 'email')}>
          <Input name="email" placeholder='Email' value={fields.email} 
            onChange={this.handleFieldChange} error={validator.isDefined(errors['email'])} />
        </FormField>

        <FormField fieldName="password" label="Password *"
          error={validator.message(errors['password'], 'password')}>
          <Input type="password" name="password" placeholder='Password' onChange={this.handleFieldChange} 
            error={validator.isDefined(errors['password'])} />
        </FormField>
      </Form>
    );
  }
}

LoginCredentialsForm.defaultProps = {
  submission: false
};

LoginCredentialsForm.propTypes = {
  onSubmit: PropTypes.func
};

export default LoginCredentialsForm;