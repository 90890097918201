import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;


export async function checkJobStatus(jobId) {
  return await axios.get(`${urls.adminHubUrl}/ui/api/jobs/${jobId}/status`);
}

export async function retrieveJobData(jobId) {
  return await axios.get(`${urls.adminHubUrl}/ui/api/jobs/${jobId}`);
}

export async function checkExportStatus(jobId) {
  return await axios.get(`${urls.adminHubUrl}/ui/api/export/${jobId}/status`);
}

export async function startCourseProgressExport(courseFilter, statusFilter, trackFilter) {
  const filters = { courseFilter, statusFilter, trackFilter };
  return await axios.post(`${urls.adminHubUrl}/ui/api/export/courses/progress`, filters);
}

export async function startStudentTranscriptExport() {
  return await axios.get(`${urls.adminHubUrl}/ui/api/export/courses/transcripts`);
}
