import randomstring from 'randomstring';

export function random(length = 32) {
  return randomstring.generate(length);
}

export function randomInt(min, max) {
  const floor = max - min + 1;
  return Math.floor(Math.random() * Math.floor(floor)) + min;
}

export function truncate(str = '', maxlength = 100) {
  if(str.length <= maxlength) return str;
  let trimmed = str.substr(0, maxlength);
  return trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' '))) + '...';
}

export function resetViewToTopOfPage() {
  window.scrollTo(0, 0);
}