import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as profileAPI from '../../actions/profile';
import * as appDisplay from '../../actions/appDisplay';
import MemberSearch from '../common/member/MemberSearch';
import DonationsDisplay from './donations/DonationsDisplay';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import stateConfig from '../../config/state';


function MemberDonations({ profiles, profileAPI }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [donations, setDonations] = useState(null);
  const [recurring, setRecurring] = useState(null);
  const [scheduled, setScheduled] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const data = profiles.byId[`${stateConfig.keys.PROFILE_DONATIONS}.${email}`];
    if(loading && profiles.ids && data) {
      if(data.error) {
        setError('Failed to load member donation information.');
      } else {
        setDonations(data.donations || []);
        setRecurring(data.recurring || []);
        setScheduled(data.scheduled || []);
        setLoaded(true);
      }
      setLoading(false);
    }
  }, [loading, email, profiles.ids, profiles.byId]);

  const onSearchComplete = profile => {
    const { email } = profile;
    setEmail(email);
    profileAPI.getDonationHistory(email);
    setLoading(true);
  };

  const clearPopMessage = () => {
    setError(null);
    setSuccess(null);
  };

  const displayResultMessage = result => {
    if(result.success) {
      setSuccess(result.message);
      setLoaded(false);
      setDonations(null);
      setRecurring(null);
      setScheduled(null);
      profileAPI.getDonationHistory(email);
      setLoading(true);
    } else {
      setError(result.message);
    }
  };

  const newMemberSearch = () => {
    setLoaded(false);
    setDonations(null);
    setRecurring(null);
    setScheduled(null);
    setEmail(null);
  };

  return (
    <div className="MemberDonations">
      { loading && <LoadingMessage message='Loading...' /> }

      { !loading && !loaded &&
        <MemberSearch onComplete={onSearchComplete} />
      }

      { loaded &&
        <DonationsDisplay email={email} donations={donations} recurring={recurring} scheduled={scheduled} 
          onBack={newMemberSearch} onUpdate={displayResultMessage} />
      }

      { success !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.INFO}>
          <p>{success}</p>
        </PopMessage>
      }
      { error !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{error}</p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profiles: state.profiles
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberDonations);