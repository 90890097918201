import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as coursesAPI from '../../../actions/content/courses';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import PopMessage from '../../common/messages/PopMessage';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function CourseMaps({ courses, coursesAPI }) {
  const [init, setInit] = useState(false);
  const [error, setError] = useState(null);
  const [maps, setMaps] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if(!init) {
      coursesAPI.getCourseMaps();
      setInit(true);
    }
  }, [init, coursesAPI]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.COURSE_MAPS}.all`;
    if(isNotDefined(maps) && isNotDefined(error) && courses.ids.includes(stateId)) {
      const data = courses.byId[stateId];
      if(data && data.error) {
        setError({ display: true });
      } else {
        setMaps(data.maps);
      }
    }
  }, [maps, error, courses.ids, courses.byId]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  const closePopup = () => {
    setSelected(null);
  };

  return (
    <div className="CourseMaps">
      <div className="CourseMaps-content">
        <h1>Course Maps</h1>
        { isDefined(error) && error.display &&
          <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>The course maps could not be loaded.</p>
          </PopMessage>
        }
        { isDefined(maps) && isNotDefined(error) &&
          maps.map(map => {
            return (
              <div key={map.courseId} className="map-summary">
                <div className="field">
                  <span>Course Slug:</span>
                  <p>{map.slug}</p>
                </div>
                <div className="field">
                  <span>Course Parts:</span>
                  <p>{`${map.lessons.length} Lessons, ${map.exams.length} Exams`}</p>
                </div>
                <div className="field">
                  <span>Webinar ID:</span>
                  <p>{map.webinarId? map.webinarId : 'None'}</p>
                </div>
                <div className="field">
                  <span>Certificate Tag:</span>
                  <p>{map.tags && map.tags.certificateMarketingTag? map.tags.certificateMarketingTag : 'None'}</p>
                </div>
                <div className="field">
                  <span>Audit Tag:</span>
                  <p>{map.tags && map.tags.auditMarketingTag? map.tags.auditMarketingTag : 'None'}</p>
                </div>
                <div className="field">
                  <span>Registered Tag:</span>
                  <p>{map.tags && map.tags.registeredTag? map.tags.registeredTag : 'None'}</p>
                </div>
                <div className="field">
                  <span>Registration Trigger:</span>
                  <p>{map.tags && map.tags.registrationTriggerTag? map.tags.registrationTriggerTag : 'None'}</p>
                </div>
                <div className="field">
                  <span>Applied Trigger:</span>
                  <p>{map.tags && map.tags.appliedTriggerTag? map.tags.appliedTriggerTag : 'None'}</p>
                </div>
                <div className="more">
                  <Button className="keep" variant="text" onClick={() => setSelected(map)}>
                    View Data
                  </Button>
                </div>
              </div>
            );
          })
        }
        { isDefined(selected) &&
          <StandardSystemPopup 
            className="CourseMaps-popup"
            open={isDefined(selected)} 
            title="Map Data" 
            onClose={closePopup}
          >
            <pre>
              {JSON.stringify(selected, undefined, 4)}
            </pre>
          </StandardSystemPopup>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    courses: state.courses
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseMaps);
