import { COURSE_LOAD_START, COURSE_LOAD_COMPLETE, COURSE_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function coursesReducer(state = initialState.courses, action) {
  const courses = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case COURSE_LOAD_START:
      clearStateById(courses.ids, courses.byId, stateId);
      return courses;
    case COURSE_LOAD_COMPLETE:
      courses.ids.push(stateId);
      courses.byId[stateId] = action.data;
      return courses;
    case COURSE_LOAD_ERR:
      courses.ids.push(stateId);
      courses.byId[stateId] = { error: action.error };
      return courses;
    default:
      return state;
  }
}

export default coursesReducer;