import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function sendPushNotification(pushData) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/notifications`, pushData, { timeout: 300000 }); // allow max time of 5m
}

export async function schedulePushNotification(pushData) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/notifications/scheduled`, pushData);
}

export async function getEmailCategories() {
  return await axios.get(`${urls.adminHubUrl}/ui/api/notifications/email/categories`);
}

export async function updateEmailCategories(data) {
  return await axios.post(`${urls.adminHubUrl}/ui/api/notifications/email/categories`, { categories: data });
}

export async function getEmailCategoryTags() {
  return await axios.get(`${urls.adminHubUrl}/ui/api/notifications/email/tags`);
}
