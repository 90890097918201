
// APP DISPLAY ACTIONS
export const PAGE_MESSAGE_PUBLISHED = 'PAGE_MESSAGE_PUBLISHED';

// AUTH ACTIONS
export const AUTH_START = 'AUTH_START';
export const AUTH_COMPLETE = 'AUTH_COMPLETE';
export const AUTH_ERR = 'AUTH_ERR';
export const AUTH_STATUS_RECEIVED = 'AUTH_STATUS_RECEIVED';
export const AUTH_STATUS_ERR = 'AUTH_STATUS_ERR';

// ATTENDANCE ACTIONS
export const ATTENDANCE_REQUEST = 'ATTENDANCE_REQUEST';
export const ATTENDANCE_IMPORTED = 'ATTENDANCE_IMPORTED';
export const ATTENDANCE_ERR = 'ATTENDANCE_ERR';

// CONTENT ACTIONS - COURSES/TOPICS/ASSESSMENTS
export const COURSE_LOAD_START = 'COURSE_LOAD_START';
export const COURSE_LOAD_COMPLETE = 'COURSE_LOAD_COMPLETE';
export const COURSE_LOAD_ERR = 'COURSE_LOAD_ERR';

// CONTENT MANAGEMENT - MARKETING TAGS
export const MARKETING_TAGS_REQUEST = 'MARKETING_TAGS_REQUEST';
export const MARKETING_TAGS_COMPLETE = 'MARKETING_TAGS_COMPLETE';
export const MARKETING_TAGS_ERR = 'MARKETING_TAGS_ERR';

// COURSE PROGRESS
export const PROGRESS_LOAD_START = 'PROGRESS_LOAD_START';
export const PROGRESS_LOAD_COMPLETE = 'PROGRESS_LOAD_COMPLETE';
export const PROGRESS_LOAD_ERR = 'PROGRESS_LOAD_ERR';

// COURSE - REGISTRATION
export const COURSE_REG_REQUEST = 'COURSE_REG_REQUEST';
export const COURSE_RETRIEVED_REG = 'COURSE_RETRIEVED_REG';
export const COURSE_CREATED_REG = 'COURSE_CREATED_REG';
export const COURSE_DELETED_REG = 'COURSE_DELETED_REG';
export const COURSE_UPDATED_REG = 'COURSE_UPDATED_REG';
export const COURSE_REG_ERR = 'COURSE_REG_ERR';

// JOBS - EXPORT
export const EXPORT_REQUEST = 'EXPORT_REQUEST';
export const EXPORT_STARTED = 'EXPORT_STARTED';
export const EXPORT_ERR = 'EXPORT_ERR';
export const EXPORT_STATUS_REQUEST = 'EXPORT_STATUS_REQUEST';
export const EXPORT_STATUS_RETRIEVED = 'EXPORT_STATUS_RETRIEVED';
export const EXPORT_STATUS_ERR = 'EXPORT_STATUS_ERR';

// JOBS - GENERAL
export const JOB_REQUEST = 'JOB_REQUEST';
export const JOB_ERR = 'JOB_ERR';
export const JOB_STATUS_RETRIEVED = 'JOB_STATUS_RETRIEVED';
export const JOB_DATA_RETRIEVED = 'JOB_DATA_RETRIEVED';

// MAGDALA - COMMUNITIES
export const MAGDALA_COMM_REQUEST = 'MAGDALA_COMM_REQUEST';
export const MAGDALA_RETRIEVED_COMM = 'MAGDALA_RETRIEVED_COMM';
export const MAGDALA_UPDATED_COMM = 'MAGDALA_UPDATED_COMM';
export const MAGDALA_COMM_ERR = 'MAGDALA_COMM_ERR';

// MAGDALA - COMMUNITY UPDATES
export const MAGDALA_COMM_UPDATE_REQUEST = 'MAGDALA_COMM_UPDATE_REQUEST';
export const MAGDALA_COMM_UPDATED = 'MAGDALA_COMM_UPDATED';
export const MAGDALA_COMM_UPDATE_ERR = 'MAGDALA_COMM_UPDATE_ERR';

// MAGDALA - REGISTRATION
export const MAGDALA_REG_REQUEST = 'MAGDALA_REG_REQUEST';
export const MAGDALA_RETRIEVED_REG = 'MAGDALA_RETRIEVED_REG';
export const MAGDALA_UPDATED_REG = 'MAGDALA_UPDATED_REG';
export const MAGDALA_REG_ERR = 'MAGDALA_REG_ERR';

// MEMBER - PROFILE
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_RETRIEVED = 'PROFILE_RETRIEVED';
export const PROFILE_ERR = 'PROFILE_ERR';

// MEMBER - PROFILE UPDATES
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PROFILE_UPDATE_ERR = 'PROFILE_UPDATE_ERR';
export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST';
export const PROFILE_DELETED = 'PROFILE_DELETED';
export const PROFILE_DELETE_ERR = 'PROFILE_DELETE_ERR';

// NOTIFICATION ACTIONS
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_SENT = 'NOTIFICATION_SENT';
export const NOTIFICATION_ERR = 'NOTIFICATION_ERR';
export const NOTIFICATION_EMAIL_CATG_REQUEST= 'NOTIFICATION_EMAIL_CATG_REQUEST';
export const NOTIFICATION_EMAIL_CATG_RETRIEVED = 'NOTIFICATION_EMAIL_CATG_RETRIEVED';
export const NOTIFICATION_EMAIL_CATG_UPDATED= 'NOTIFICATION_EMAIL_CATG_UPDATED';
export const NOTIFICATION_EMAIL_CATG_ERR = 'NOTIFICATION_EMAIL_CATG_ERR';
export const NOTIFICATION_CATG_TAGS_REQUEST = 'NOTIFICATION_CATG_TAGS_REQUEST';
export const NOTIFICATION_CATG_TAGS_RETRIEVED = 'NOTIFICATION_CATG_TAGS_RETRIEVED';
export const NOTIFICATION_CATG_TAGS_ERR = 'NOTIFICATION_CATG_TAGS_ERR';