import { MAGDALA_COMM_REQUEST, MAGDALA_RETRIEVED_COMM, MAGDALA_UPDATED_COMM,
         MAGDALA_REG_REQUEST, MAGDALA_RETRIEVED_REG, MAGDALA_UPDATED_REG,
         MAGDALA_COMM_ERR, MAGDALA_REG_ERR, MAGDALA_COMM_UPDATE_REQUEST,
         MAGDALA_COMM_UPDATED, MAGDALA_COMM_UPDATE_ERR } from '../actions/actionTypes';
import * as magdala from '../api/magdala';
import errors from '../config/errors';
import stateConfig from '../config/state';


const registrationKey = stateConfig.keys.MAGDALA_REG;
const commListKey = stateConfig.keys.MAGDALA_COMMLIST;
const communityKey = stateConfig.keys.MAGDALA_COMM;
const updatesKey = stateConfig.keys.MAGDALA_COMM_UPDATES;
const mbrListKey = stateConfig.keys.MAGDALA_MBRLIST;


export function getCommunities() {
  const objectId = 'all';
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_REQUEST, key: commListKey, objectId });
    try {
      let result = await magdala.getCommunities();
      if(result.data.communities) {
        let data = result.data.communities;
        dispatch({ type: MAGDALA_RETRIEVED_COMM, key: commListKey, objectId, data });
      } else {
        dispatch({ type: MAGDALA_COMM_ERR, key: commListKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_COMM_ERR, key: commListKey, objectId, error: errors.RequestFailed });
    }
  };
}

export function getCommunity(communityId) {
  const objectId = communityId;
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_REQUEST, key: communityKey, objectId });
    try {
      let result = await magdala.getCommunity(communityId);
      if(result.data.communityId) {
        dispatch({ type: MAGDALA_RETRIEVED_COMM, key: communityKey, objectId, data: result.data });
      } else {
        dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: errors.RequestFailed });
    }
  };
}

export function getCommunityMembers(communityId) {
  const objectId = communityId;
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_REQUEST, key: mbrListKey, objectId });
    try {
      let result = await magdala.getCommunityMembers(communityId);
      if(result.data.members) {
        let data = result.data.members;
        dispatch({ type: MAGDALA_RETRIEVED_COMM, key: mbrListKey, objectId, data });
      } else {
        dispatch({ type: MAGDALA_COMM_ERR, key: mbrListKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_COMM_ERR, key: mbrListKey, objectId, error: errors.RequestFailed });
    }
  };
}

export function createCommunity(requestId, data) {
  const objectId = requestId;
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_REQUEST, key: communityKey, objectId });
    try {
      let result = await magdala.createCommunity(data);
      if(result.data.success) {
        const data = Object.assign({ communityId: result.data.communityId }, { created: true });
        dispatch({ type: MAGDALA_UPDATED_COMM, key: communityKey, objectId, data });
      } else {
        dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: { ...errors.RequestFailed } });
    }
  };
}

export function updateCommunity(communityId, data) {
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_UPDATE_REQUEST, key: updatesKey, communityId });
    try {
      let result = await magdala.updateCommunity(communityId, data);
      if(result.data.success) {
        dispatch({ type: MAGDALA_COMM_UPDATED, key: updatesKey, communityId, data: { updated: true } });
      } else {
        dispatch({ type: MAGDALA_COMM_UPDATE_ERR, key: updatesKey, communityId, error: errors.RequestFailed });
      }
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : errors.RequestFailed;
      dispatch({ type: MAGDALA_COMM_UPDATE_ERR, key: updatesKey, communityId, error: err });
    }
  };
}

export function deleteCommunity(communityId) {
  const objectId = communityId;
  return async dispatch => {
    dispatch({ type: MAGDALA_COMM_REQUEST, key: communityKey, objectId });
    try {
      let result = await magdala.deleteCommunity(communityId);
      if(result.data.success) {
        dispatch({ type: MAGDALA_UPDATED_COMM, key: communityKey, objectId, data: { deleted: true } });
      } else {
        dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_COMM_ERR, key: communityKey, objectId, error: errors.RequestFailed });
    }
  };
}

export function getRegistration(data) {
  const { email } = data;
  return async dispatch => {
    dispatch({ type: MAGDALA_REG_REQUEST, key: registrationKey, email });
    try {
      let result = await magdala.getRegistration(data);
      if(result.data.email) {
        dispatch({ type: MAGDALA_RETRIEVED_REG, key: registrationKey, email, data: result.data });
      } else {
        dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: errors.RequestFailed });
    }
  };
}

export function updateRegistration(data) {
  const { email } = data;
  return async dispatch => {
    dispatch({ type: MAGDALA_REG_REQUEST, key: registrationKey, email });
    try {
      let result = await magdala.updateRegistration(data);
      if(result.data.success) {
        dispatch({ type: MAGDALA_UPDATED_REG, key: registrationKey, email, data: { updated: true } });
      } else {
        dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: { ...errors.RequestFailed } });
    }
  };
}

export function deleteRegistration(data) {
  const { email } = data;
  return async dispatch => {
    dispatch({ type: MAGDALA_REG_REQUEST, key: registrationKey, email });
    try {
      let result = await magdala.deleteRegistration(data);
      if(result.data.success) {
        dispatch({ type: MAGDALA_UPDATED_REG, key: registrationKey, email, data: { deleted: true } });
      } else {
        dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_REG_ERR, key: registrationKey, email, error: errors.RequestFailed });
    }
  };
}

