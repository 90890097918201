import React, { useState } from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as contentManagementAPI from '../../api/content/management';
import FormWrapper from '../common/form/FormWrapper';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import PopMessage from '../common/messages/PopMessage';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import validator from '../../utils/validator';
const { cacheOptions } = urls;
const { isDefined } = validator;

const fieldsInit = {
  content: '0',
  specifier: ''
};

function CacheClear(props) {
  const [fields, setFields] = useState(fieldsInit);
  const [selected, setSelected] = useState(cacheOptions[0]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const onFieldChange = event => {
    let { name, value } = event.target;
    let fObj = { ...fields };
    fObj[name] = value;

    if(name === 'content') {
      setSelected(cacheOptions[parseInt(value)]);
      fObj.specifier = '';
    }
    setFields(fObj);
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    let path = selected.url;
    if(isDefined(selected.field)) {
      path = path.replace(`:${selected.field[1]}`, fields.specifier);
    }
    
    try {
      const response = await contentManagementAPI.clearCachePath(path);
      if(response.data && response.data.success) {
        setSuccess({ display: true });
      } else {
        setError({ display: true });
      }
    } catch(e) {
      setError({ display: true });
    }
  };

  const clearPopMessage = () => {
    setSuccess({ display: false });
    setError({ display: false });
  }

  return (
    <div className="CacheClear">
      <FormWrapper squared={true} raised={true}>
        <h2>Cache Clearing</h2>
        <Form id='CacheClear-form' onSubmit={onSubmit} autoComplete="off"
          submitLabel="Clear Cache">

          <FormField fieldName="content" label="Content">
            <Select name="content" value={fields.content} onChange={onFieldChange}>
              {
                cacheOptions.map((option, index) => {
                  return (<MenuItem key={index} value={index}>{option.display}</MenuItem>);
                })
              }
            </Select>
          </FormField>
          
          { selected && isDefined(selected.field) &&
            <FormField fieldName="specifier" label={selected.field[0]}>
              <Input name="specifier" value={fields.specifier} onChange={onFieldChange} />
            </FormField>
          }
        </Form>
      </FormWrapper>
      { isDefined(success) && success.display &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.INFO}>
          <p>Cache successfully cleared.</p>
        </PopMessage>
      }
      { isDefined(error) && error.display &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>Failed to clear the cache for the selected content.</p>
        </PopMessage>
      }
    </div>
  );
}

export default CacheClear;