import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import * as profileAPI from '../../../actions/profile';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import NumberFormatCustom from '../../common/form/NumberFormatCustom';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';


function DonationNotation({ email, donationId, amount, notation, onClose, updates, profileAPI }) {
  const [amountValue, setAmountValue] = useState(amount);
  const [notationValue, setNotationValue] = useState(notation || '');
  const [unformattedAmount, setUnformattedAmount] = useState(parseInt(amount));
  const [submission, setSubmission] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const update = updates.byId[`${stateConfig.keys.PROFILE_DONATIONS}.${email}`];
    if(submission && updates.ids && update) {
      if(update.error) {
        onClose({ success: false, message: 'Notation failed to save.' });
      } else {
        onClose({ success: true, message: 'Notation successfully saved!' });
      }
    }
  }, [email, submission, updates.ids, updates.byId, onClose]);

  const onFieldChange = ({ target: { name, value } }) => {
    if(name === 'amount') {
      setAmountValue(value);
    } else {
      setNotationValue(value);
    }
  };

  const onAmountBlur = (event) => {
    if(amountValue.includes('.')) {
      let formattedAmount = `${amountValue.split('.')[0]}.00`;
      setAmountValue(formattedAmount);
      setUnformattedAmount(parseInt(formattedAmount));
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    setErrors({});
    setSubmission(true);
    profileAPI.notateDonation(email, donationId, unformattedAmount, notationValue);
  };

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'amount', against: validator.patterns.CURRENCY_NO_CENTS, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'amount' }
    ];

    let validationErrors = validator.validate({ amount: unformattedAmount }, rules);
    if(validationErrors) {
      setErrors(validationErrors);
    } else if(parseInt(unformattedAmount) === 0) {
      validationErrors = { amount: 'The amount cannot be zero.' };
      setErrors(validationErrors);
    }

    return validationErrors? false : true;
  };

  const onCancel = () => {
    onClose({ cancelled: true });
  };

  return (
    <div className="DonationNotation">
      <FormWrapper squared={true} raised={true}>
        <Form id='DonationNotation-form' onSubmit={onSubmit} onClickSecondary={onCancel}
          submitLabel="Submit" submission={submission} autoComplete="off">

          <FormField fieldName="amount" error={validator.message(errors['amount'], 'amount')}>
            <TextField
              label="Amount (in dollars)"
              name="amount" 
              value={amountValue}
              onChange={onFieldChange}
              onBlur={onAmountBlur}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              InputLabelProps={{
                shrink: true
              }}
              error={validator.isDefined(errors['amount'])}
            />
          </FormField>

          <FormField fieldName="notation" label="Notation">
            <Input name="notation" placeholder='Type your note' value={notationValue} onChange={onFieldChange} />
          </FormField>

        </Form>
      </FormWrapper>
    </div>
  );
}

DonationNotation.propTypes = {
  email: PropTypes.string,
  donationId: PropTypes.string,
  amount: PropTypes.string,
  notation: PropTypes.string,
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  return {
    updates: state.profileUpdates
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationNotation);