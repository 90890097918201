import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as progressAPI from '../../../actions/courses/progress';
import LoadingMessage from '../../common/messages/LoadingMessage';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { formatDateTimeEST } from '../../../utils/format';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function RecordAssessments({ record, profile, courses, courseProgress, progressAPI }) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState(null);
  const { email } = profile;
  const { courseId, status, assessments } = record;

  const closePopup = () => {
    setVisible(false);
    setError(false);
  };

  const showAssessments = () => {
    if(isNotDefined(items) && config.courseArchived.includes(status)) {
      let arr = isDefined(assessments)? assessments : [];
      setItems(arr);
    } else if(isNotDefined(items)) {
      progressAPI.getAssessmentSummary(email, courseId);
      setLoading(true);
    }
    setVisible(true);
  };

  useEffect(() => {
    if(loading && isNotDefined(items)) {
      const pId = `${stateConfig.keys.PROGRESS_ASSESSMENTS}.${email}.${courseId}`;
      const cId = `${stateConfig.keys.COURSE_LIST}.${courseId}`;
      const pData = courseProgress.ids.includes(pId)? courseProgress.byId[pId] : null;
      const cData = courses.ids.includes(cId)? courses.byId[cId] : null;
      if(pData && cData) {
        if(pData.error || cData.error) {
          setError(true);
          setLoading(false);
        } else {
          // build a list of all assessments associated with the course
          let assessments = cData.lessons.reduce((arr, lesson) => {
            const arr2 = lesson.parts.filter(part => part.type === 'assessment');
            return arr.concat(arr2);
          }, []);
          assessments = assessments.concat(cData.exams);
          // reduce down into a map of assessment IDs to titles
          assessments = assessments.reduce((obj, assessment) => {
            obj[assessment.id] = assessment.title;
            return obj;
          }, {});
          // enrich assessment history w/ titles
          let rArr = pData.assessments.map(item => { 
            const title = assessments[item.assessmentId];
            return { ...item, title };
          });
          setItems(rArr);
          setLoading(false);
        }
      }
    }
  }, [loading, items, email, courseId, courseProgress.ids, courseProgress.byId, courses.ids, courses.byId]);

  return (
    <div className="TranscriptRecordAssessments">
      <div className="field assessments">
        <span>Assessments:</span>
        <div>
          <Button variant="text" onClick={showAssessments} color="primary">
            Show Assessments
          </Button>
        </div>
      </div>
      { visible &&
        <StandardSystemPopup 
          className="TranscriptRecordAssessments-popup"
          open={visible} 
          title="Assessments" 
          onClose={closePopup}
        >
          <div className="assessment-list">
            { loading && !error &&
              <LoadingMessage message="Loading..." />
            }
            { !loading && error &&
              <div className="error">The assessments could not be loaded.</div>
            }
            { !loading && !error && (isNotDefined(items) || items.length === 0) &&
              <p className="no-records">There are no assessment records to display.</p>
            }
            { !loading && !error && isDefined(items) && items.length > 0 &&
              items.map(item => {
                return (
                  <div className="assessment-record">
                    <span className="item-title">{item.title}</span>
                    <span className="score">{item.score? `${item.score}%` : 'Saved' }</span>
                    <span className="date">{formatDateTimeEST(item.lastUpdated)}</span>
                  </div>
                );
              })
            }
          </div>
        </StandardSystemPopup>
      }
    </div>
  );
}

RecordAssessments.propTypes = {
  record: PropTypes.object,
  profile: PropTypes.object
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordAssessments);
