import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as magdalaAPI from '../../../actions/magdala';
import EditAddress from '../../common/member/EditAddress';
import EditPhone from '../../common/member/EditPhone';
import UpdateField from './edit/UpdateField';
import UpdateEnrolled from './edit/UpdateEnrolled';
import UpdateJabraStatus from './edit/UpdateJabraStatus';
import UpdateMainContact from './edit/UpdateMainContact';
import UpdateNotes from './edit/UpdateNotes';


class CommunityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.performUpdate = this.performUpdate.bind(this);
  }

  performUpdate(data) {
    const { community, field } = this.props;
    switch(field) {
      default:
        this.props.magdalaAPI.updateCommunity(community.communityId, {
          field,
          data
        });
    }
  }

  render() {
    const { community, error, field, required, onCancel } = this.props;
    const address = community.communityContact && community.communityContact.address? community.communityContact.address : {};
    let phone = community.communityContact && community.communityContact.phone? community.communityContact.phone : {};
    phone.type = 'work';
    
    return (
      <div className="CommunityEdit">
        { field !== 'firstEnrolled' && field !== 'jabraStatus' && field !== 'mainContact' && field !== 'address' && field !== 'phone' && field !== 'notes' &&
          <UpdateField field={field} required={required} community={community} error={error} 
            onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'firstEnrolled' &&
          <UpdateEnrolled community={community} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'jabraStatus' &&
          <UpdateJabraStatus community={community} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'mainContact' &&
          <UpdateMainContact community={community} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'address' &&
          <EditAddress profile={{ addresses: [address] }} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'phone' &&
          <EditPhone profile={{ phones: [phone] }} error={error} onSubmit={this.performUpdate} onCancel={onCancel} displayType={false} />
        }
        { field === 'notes' &&
          <UpdateNotes community={community} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
      </div>
    );
  }
}

CommunityEdit.propTypes = {
  community: PropTypes.object,
  error: PropTypes.object,
  field: PropTypes.string,
  required: PropTypes.bool,
  onCancel: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEdit);