import { NOTIFICATION_REQUEST, NOTIFICATION_SENT, NOTIFICATION_EMAIL_CATG_REQUEST, NOTIFICATION_EMAIL_CATG_RETRIEVED,
         NOTIFICATION_EMAIL_CATG_UPDATED, NOTIFICATION_CATG_TAGS_REQUEST, NOTIFICATION_CATG_TAGS_RETRIEVED, 
         NOTIFICATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function notificationsReducer(state = initialState.notifications, action) {
  const { type, error = {} } = action;
  let responseCode, status = stateConfig.responseStatus;
    
  switch(type) {
    case NOTIFICATION_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, jobId: null });
    case NOTIFICATION_SENT:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, jobId: action.data.jobId });
    case NOTIFICATION_EMAIL_CATG_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, emailCategories: null });
    case NOTIFICATION_EMAIL_CATG_RETRIEVED:
    case NOTIFICATION_EMAIL_CATG_UPDATED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, emailCategories: action.data.categories });
    case NOTIFICATION_CATG_TAGS_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, categoryTags: null });
    case NOTIFICATION_CATG_TAGS_RETRIEVED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, categoryTags: action.data.tags });
    case NOTIFICATION_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default notificationsReducer;