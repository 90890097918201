import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as profileAPI from '../../../actions/profile';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import PopMessage from '../../common/messages/PopMessage';
import MemberSelection from './MemberSelection';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';


class MemberSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      stateId: stateConfig.keys.PROFILES,
      fields: {
        searchType: 'email',
        email: '',
        customerId: '',
        firstName: '',
        lastName: '',
        religiousName: ''
      },
      users: null,
      userResultsLimited: false,
      searchError: null,
      formErrors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.profileSearch = this.profileSearch.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCancelSelection = this.onCancelSelection.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.profiles;
    const prevIds = prevProps.profiles.ids;
    const profile = byId[`${this.state.stateId}.search`];
    
    if(ids !== prevIds && profile) {
      if(profile.error) {
        this.setState({ submission: false, searchError: profile.error });
      } else if(profile.email) {
        this.props.onComplete(profile);
      } else if(profile.users && profile.users.length === 1) {
        this.props.profileAPI.memberSearch('email', { email: profile.users[0].email });
      } else if(profile.users) {
        this.setState({ submission: false, users: profile.users, userResultsLimited: profile.limit });
      }
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = name === 'email'? value.toLowerCase().trim() : value;
    this.setState({ fields });
  }

  profileSearch(event) {
    event.preventDefault();
    if(!this.validate()) {
      return;
    }

    const { searchType, email, customerId, firstName, lastName, religiousName } = this.state.fields;
    let data = null;
    if(searchType === 'email') {
      data = { email };
    } else if(searchType === 'customerId') {
      data = { customerId: customerId.trim() };
    } else if(searchType === 'religiousName') {
      data = { religiousName: religiousName.trim(), firstName: firstName.trim(), lastName: lastName.trim() };
    } else {
      data = { firstName: firstName.trim(), lastName: lastName.trim() };
    }
    this.props.profileAPI.memberSearch(searchType, data);
  }

  validate() {
    const data = this.state.fields;
    let rules = null;
    if(data.searchType === 'email') {
      rules = [
        { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
        { rule: validator.rules.IS_DEFINED, prop: 'email' },
      ];
    } else if(data.searchType === 'customerId') {
      rules = [
        { rule: validator.rules.IS_DEFINED, prop: 'customerId' },
      ];
    } else if(data.searchType === 'religiousName') {
      rules = [
        { rule: validator.rules.MATCHES_PATTERN, prop: 'religiousName', against: validator.patterns.NAME, empty: true },
        { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
        { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true }
      ];
    } else {
      rules = [
        { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
        { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true }
      ];
    }
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ formErrors: validationErrors });
      return false;
    }

    return true;
  }

  onSelect(email) {
    this.props.profileAPI.memberSearch('email', { email });
  }

  onCancelSelection() {
    this.setState({
      fields: {
        searchType: 'email',
        email: '',
        customerId: '',
        firstName: '',
        lastName: '',
        religiousName: ''
      },
      users: null,
      userResultsLimited: false,
      searchError: null,
      formErrors: {}
    });
  }

  clearPopMessage() {
    this.setState({ searchError: null });
  }

  render() {
    const { submission, fields, users, userResultsLimited, formErrors, searchError } = this.state;

    return (
      <div className="MemberSearch">
        { validator.isEmpty(users) &&
          <FormWrapper squared={true} raised={true}>
            <h2>Member Search</h2>
            <Form id='MemberSearch-form' onSubmit={this.profileSearch} autoComplete="off"
              submitLabel="Search" submission={submission}>

              <Select name="searchType" value={fields.searchType} onChange={this.onFieldChange}>
                <MenuItem key="email" value="email">Email</MenuItem>
                <MenuItem key="customerId" value="customerId">Stripe Customer ID</MenuItem>
                <MenuItem key="name" value="name">Name</MenuItem>
                <MenuItem key="religiousName" value="religiousName">Religious Name / Magdala</MenuItem>
              </Select>
              
              { fields.searchType === 'email' &&
                <div className="email">
                  <FormField fieldName="email" label="Member Email"
                    error={validator.message(formErrors['email'], 'email')}>
                    <Input name="email" value={fields.email} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['email'])} />
                  </FormField>
                </div>
              }
              
              { fields.searchType === 'customerId' &&
                <div className="customerId">
                  <FormField fieldName="customerId" label="Customer ID"
                    error={validator.message(formErrors['customerId'], 'customer ID')}>
                    <Input name="customerId" value={fields.customerId} onChange={this.onFieldChange}
                      placeholder="Alpha-numeric value starting with 'cus_'" 
                      error={validator.isDefined(formErrors['customerId'])} />
                  </FormField>
                </div>
              }

              { fields.searchType === 'name' &&
                <div className="name">
                  <FormField fieldName="firstName" label="First Name"
                    error={validator.message(formErrors['firstName'], 'first name')}>
                    <Input name="firstName" value={fields.firstName} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['firstName'])} />
                  </FormField>
                  <FormField fieldName="lastName" label="Last Name"
                    error={validator.message(formErrors['lastName'], 'last name')}>
                    <Input name="lastName" value={fields.lastName} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['lastName'])} />
                  </FormField>
                </div>
              }

              { fields.searchType === 'religiousName' &&
                <div className="name">
                  <FormField fieldName="religiousName" label="Religious Name"
                    error={validator.message(formErrors['religiousName'], 'religious name')}>
                    <Input name="religiousName" value={fields.religiousName} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['religiousName'])} />
                  </FormField>
                  <FormField fieldName="firstName" label="First Name"
                    error={validator.message(formErrors['firstName'], 'first name')}>
                    <Input name="firstName" value={fields.firstName} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['firstName'])} />
                  </FormField>
                  <FormField fieldName="lastName" label="Last Name"
                    error={validator.message(formErrors['lastName'], 'last name')}>
                    <Input name="lastName" value={fields.lastName} onChange={this.onFieldChange}
                      error={validator.isDefined(formErrors['lastName'])} />
                  </FormField>
                </div>
              }
            </Form>
          </FormWrapper>
        }
        { validator.isNotEmpty(users) &&
          <MemberSelection users={users} onSelect={this.onSelect} limited={userResultsLimited} onCancel={this.onCancelSelection} />
        }
        { validator.isDefined(searchError) &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>
              No member was located with this search criteria
            </p>
          </PopMessage>
        }
      </div>
    );
  }
}

MemberSearch.propTypes = {
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    profiles: state.profiles
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberSearch);
