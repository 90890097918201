import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as jobsAPI from '../../../actions/jobs';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import JobStatusMessage from '../../common/messages/JobStatusMessage';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';


function StudentTranscriptReport({ jobs, jobsAPI }) {
  const [init, setInit] = useState(false);
  const [starting, setStarting] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      jobsAPI.startStudentTranscriptExport();
      setInit(true);
    }
  }, [init, jobsAPI]);

  useEffect(() => {
    const data = jobs.exportById[`${stateConfig.keys.EXPORT}.courseTranscripts`];
    if(starting && jobs.exportIds && data) {
      if(data && data.error) {
        setError('Failed to start student transcript export');
      } else {
        setJobId(data.jobId);
        setStarting(false);
        setProcessing(true);
      }
    }
  }, [jobId, starting, jobs.exportIds, jobs.exportById]);

  const onComplete = result => {
    setProcessing(false);
    if(result.success) {
      setSuccess('The student transcript export has successfully completed!');
      setResult(result.data);
    } else {
      setError(result.message);
    }
  };

  const downloadReport = () => {
    window.open(`${urls.adminHubUrl}/ui/api/export/${jobId}`, 'StudentTranscriptExport', 'noopener noreferrer');
  };

  const clearPopMessage = () => {
    setError(null);
    setSuccess(null);
    setStarting(false);
  };

  return (
    <div className="Report StudentTranscriptReport">
      { starting && !processing &&
        <div className="center"><LoadingMessage message='Starting export...' /></div>
      }
      { !starting && processing && jobId &&
        <JobStatusMessage 
          jobId={jobId} 
          type="export"
          heading="Processing student transcript report"
          onComplete={onComplete}  
        />
      }
      { result !== null &&
        <div className="download">
          <p className="message">{`${result.progress.processed} records processed. 100% complete.`}</p>
          <Button variant="contained" color="primary" onClick={downloadReport}>
            Download
          </Button>
        </div>
      }
      { success !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.INFO}>
          <p>{success}</p>
        </PopMessage>
      }
      { error !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{error}</p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    jobs: state.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    jobsAPI: bindActionCreators(jobsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentTranscriptReport);