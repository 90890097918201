import axios from 'axios';
import urls from '../../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const managementPath = '/ui/api/content/management';

export async function clearCachePath(path) {
  return await axios.post(`${urls.adminHubUrl}${managementPath}/cache`, { path });
}

export async function getMarketingTags() {
  return await axios.get(`${urls.adminHubUrl}${managementPath}/marketing/tags`);
}

export async function syncMarketingTags() {
  return await axios.post(`${urls.adminHubUrl}${managementPath}/marketing/tags`);
}