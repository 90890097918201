import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

export default function LoadingMessage(props) {
  return (
    <div className={`LoadingMessage ${props.size}`}>
      <FontAwesomeIcon icon={faSpinnerThird} spin /> <span className="message">{props.message || 'Loading...'}</span>
    </div>
  );
}

LoadingMessage.defaultProps = {
  size: "small"
};

LoadingMessage.propTypes = {
  message: PropTypes.string
};