import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function EditEmailCategory({ category, tags, submission, onSave, onCancel }) {
  const defaultFields = {
    order: 1,
    tagId: tags[0].tagId,
    title: '',
    description: ''
  };

  const [fields, setFields] = useState(Object.assign(defaultFields, isNotEmpty(category)? category : {}));
  const [errors, setErrors] = useState({});
  const isAdd = isEmpty(category);

  const onFieldChange = ({ target: { name, value } }) => {
    const data = { ...fields };
    data[name] = value;
    setFields(data);
  };

  const onSubmit = event => {
    event.preventDefault();
    if(!validate()) {
      onSave(false, 'Some of the values you provided were invalid');
      return false;
    }

    onSave(true, fields);
  };

  const validate = () => {
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'order' },
      { rule: validator.rules.IS_INTEGER, prop: 'order' },
      { rule: validator.rules.IS_DEFINED, prop: 'title' },
      { rule: validator.rules.IS_DEFINED, prop: 'description' }
    ];

    if(isAdd) {
      rules.push({ rule: validator.rules.IS_DEFINED, prop: 'tagId' }); 
    }

    const validationErrors = validator.validate(fields, rules);
    if(validationErrors) {
      setErrors(validationErrors);
      return false;
    }

    return true;
  };

  return (
    <div id="EditEmailCategory" className="panel">
      <FormWrapper squared={true} raised={true}>
        <h2>{isAdd? 'Add' : 'Edit'} Email Category</h2>
        <Form id='EditEmailCategory-form' onSubmit={onSubmit} autoComplete="off" submission={submission}
          submitLabel="Save" secondaryButtonLabel="Cancel" onClickSecondary={onCancel}>
          
          <FormField className="first" fieldName="order" label="Order"
            error={validator.message(errors['order'], 'order')}>
            <Input name="order" placeholder="Order (e.g., 1, 2, ...)" value={fields.order} onChange={onFieldChange}
              error={validator.isDefined(errors['order'])} />
          </FormField>
          
          <FormField fieldName="tagId" label="Category Tag">
            <Select name="tagId" value={fields.tagId} onChange={onFieldChange}>
              {
                tags.map(tag => {
                  const { tagId, tagName } = tag;
                  return (
                    <MenuItem key={tagId} value={tagId}>{tagName}</MenuItem>
                  );
                })
              }
            </Select>
          </FormField>

          <FormField fieldName="title" label="Title"
            error={validator.message(errors['title'], 'title')}>
            <Input name="title" placeholder="Title" value={fields.title} onChange={onFieldChange}
              error={validator.isDefined(errors['title'])} />
          </FormField>

          <FormField fieldName="description" label="Description"
            error={validator.message(errors['description'], 'description')}>
            <Input name="description" placeholder="Description" value={fields.description} onChange={onFieldChange}
              error={validator.isDefined(errors['description'])} multiline={true} />
          </FormField>
          
        </Form>
      </FormWrapper>
    </div>
  );
}

EditEmailCategory.propTypes = {
  category: PropTypes.object,
  tags: PropTypes.array,
  submission: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default EditEmailCategory;