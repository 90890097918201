import { JOB_REQUEST, JOB_ERR, JOB_STATUS_RETRIEVED, JOB_DATA_RETRIEVED, EXPORT_REQUEST, 
         EXPORT_STARTED, EXPORT_ERR, EXPORT_STATUS_REQUEST, EXPORT_STATUS_RETRIEVED, 
         EXPORT_STATUS_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function jobsReducer(state = initialState.jobs, action) {
  const jobs = {
    exportIds: state.exportIds.slice(),
    exportById: { ...state.exportById },
    jobIds: state.jobIds.slice(),
    jobById: { ...state.jobById }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case JOB_REQUEST:
      clearStateById(jobs.jobIds, jobs.jobById, stateId);
      return jobs;
    case EXPORT_REQUEST:
    case EXPORT_STATUS_REQUEST:
      clearStateById(jobs.exportIds, jobs.exportById, stateId);
      return jobs;
    case JOB_STATUS_RETRIEVED:
    case JOB_DATA_RETRIEVED:
      jobs.jobIds.push(stateId);
      jobs.jobById[stateId] = action.data;
      return jobs;
    case EXPORT_STARTED:
    case EXPORT_STATUS_RETRIEVED:
      jobs.exportIds.push(stateId);
      jobs.exportById[stateId] = action.data;
      return jobs;
    case JOB_ERR:
      jobs.jobIds.push(stateId);
      jobs.jobById[stateId] = { error: action.error };
      return jobs;
    case EXPORT_ERR:
    case EXPORT_STATUS_ERR:
      jobs.exportIds.push(stateId);
      jobs.exportById[stateId] = { error: action.error };
      return jobs;
    default:
      return state;
  }
}

export default jobsReducer;