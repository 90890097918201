import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as notificationsAPI from '../../actions/notifications';
import StandardSystemPopup from '../layout/popup/StandardSystemPopup';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import EditEmailCategory from './email/EditEmailCategory';
import config from '../../config/config';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function ManageEmailCategories({ notifications, notificationsAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletionWarning, setDeletionWarning] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [success, setSuccess] = useState({ display: false });
  const [error, setError] = useState({ display: false });
  let initialize = config.emptyFn;

  useEffect(() => {
    if(!init) {
      initialize();
      setInit(true);
    }
  }, [init, initialize]);

  useEffect(() => {
    if(loading && isEmpty(categories)) {
      if(notifications.responseStatus === stateConfig.responseStatus.COMPLETE && isNotEmpty(notifications.emailCategories)) {
        setCategories(prepareCategories(notifications.emailCategories));
        notificationsAPI.getEmailCategoryTags();
      } else if(notifications.responseStatus === stateConfig.responseStatus.ERROR) {
        setError({ display: true, message: 'Failed to load email categories' });
        setLoading(false);
      }
    }
  }, [loading, categories, notifications, notificationsAPI]);

  useEffect(() => {
    if(loading && isNotEmpty(categories) && isEmpty(tags)) {
      if(notifications.responseStatus === stateConfig.responseStatus.COMPLETE && isNotEmpty(notifications.categoryTags)) {
        setTags(notifications.categoryTags);
        setLoading(false);
      } else if(notifications.responseStatus === stateConfig.responseStatus.ERROR) {
        setError({ display: true, message: 'Failed to load email category tags' });
        setLoading(false);
      }
    }
  }, [loading, categories, tags, notifications, notificationsAPI]);

  useEffect(() => {
    if((updating || deleting) && submitting) {
      if(notifications.responseStatus === stateConfig.responseStatus.COMPLETE && isNotEmpty(notifications.emailCategories)) {
        setCategories(prepareCategories(notifications.emailCategories));
        setSuccess({
          display: true,
          message: deleting? 
            'Your deletion is complete. Please note that this action does not delete category tags.' :
            'You changes have been successfully saved.'
        });
        setSubmitting(false);
        setUpdating(false);
        setDeleting(false);
      } else if(notifications.responseStatus === stateConfig.responseStatus.ERROR) {
        setError({ display: true, message: 'Failed to save the requested changes' });
        setSubmitting(false);
      }
    }
  }, [updating, deleting, submitting, notifications, notificationsAPI]);

  initialize = () => {
    notificationsAPI.getEmailCategories();
    setLoading(true);
  };

  const prepareCategories = categories => {
    return categories.sort((a, b) => a.order > b.order? 1 : -1);
  };

  const onBeginUpdate = data => () => {
    setSelectedCategory(data);
    setUpdating(true);
  }

  const onSaveChange = (success, data) => {
    const isAdd = isEmpty(selectedCategory);
    if(!success) {
      setError({ display: true, message: data });
      return;
    } else if(isAdd && categories.find(category => category.tagId === data.tagId)) {
      setError({ display: true, message: 'You are attempting to add a new category with a tag that has already been used.' });
      return;
    } else if(!isAdd && data.tagId !== selectedCategory.tagId && categories.find(category => category.tagId === data.tagId)) {
      setError({ display: true, message: 'You are attempting to update the category with a tag that has already been used.' });
      return;
    }

    setSubmitting(true);

    // prepare the data
    const update = { ...data };
    update.order = parseInt(update.order);
    
    // update the category list
    let newCategories = [ ...categories ];
    if(isAdd) { // adding a new category
      newCategories.push(update);
    } else { // updating existing category
      let index = categories.findIndex(category => category.tagId === update.tagId);
      newCategories[index] = update;
    }

    // submit the updated categories
    notificationsAPI.updateEmailCategories(newCategories);
  };

  const onDelete = data => () => {
    setSelectedCategory(data);
    setDeletionWarning(true);
  };

  const onCancelDelete = () => {
    setSelectedCategory(null);
    setDeletionWarning(false);
  };

  const onConfirmDelete = () => {
    setDeletionWarning(false);
    setSubmitting(true);
    setDeleting(true);
    
    // update the category list
    let newCategories = [ ...categories ];
    let index = categories.findIndex(category => category.tagId === selectedCategory.tagId);
    newCategories.splice(index, 1);

    // submit the updated categories
    notificationsAPI.updateEmailCategories(newCategories);
  };

  const clearPopMessage = () => {
    setSuccess({ display: false });
    setError({ display: false });
  };

  return (
    <div className="ManageEmailCategories">
      <div className="ManageEmailCategories-container">
        { loading && !submitting && !deleting && <LoadingMessage message="Loading..." /> }
        { submitting && !deleting && <LoadingMessage message="Saving..." /> }
        { deleting && <LoadingMessage message="Deleting..." /> }

        { success.display &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.INFO}>
            <p>{success.message}</p>
          </PopMessage>
        }

        { error.display &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>{error.message}</p>
          </PopMessage>
        }
        
        <div className="ManageEmailCategories-content">
          { !updating && isNotEmpty(categories) &&
            <Fragment>
              <div className="heading">
                <h2>Manage Email Categories</h2>
                <Button variant="outlined" onClick={onBeginUpdate(null)} color="primary">
                  Add New
                </Button>
              </div>
              { (categories.length === 0) &&
                <p className="no-categories">No email categories have been defined</p>
              }
              { (categories.length > 0) &&
                <div className="ManageEmailCategories-list">
                  {
                    categories.map(category => {
                      const { order, tagId, title, description } = category;
                      return (
                        <div key={`category-${tagId}`} className="ManageEmailCategories-category">
                          <div className="category-heading">
                            <h3>{title}</h3>
                            <p className="order">Order: {order}</p>
                          </div>
                          <div className="description">
                            <p>{description}</p>
                            <div className="actions">
                              <Button className="edit" variant="text" onClick={onBeginUpdate(category)} color="primary">
                                Edit
                              </Button>
                              <Button className="delete" variant="text" onClick={onDelete(category)} color="secondary">
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              }
            </Fragment>
          }

          { updating &&
            <EditEmailCategory 
              category={selectedCategory}
              tags={tags}
              submission={submitting}
              onSave={onSaveChange}
              onCancel={() => setUpdating(false)}
            />
          }
        </div>

        { deletionWarning &&
          <StandardSystemPopup 
            className="ManageEmailCategories-popup"
            open={deletionWarning} 
            title="Warning" 
            onClose={onCancelDelete}
            actions={(
              <Fragment>
                <Button className="cancel" variant="text" onClick={onCancelDelete}>
                  Cancel
                </Button>
                <Button className="continue" variant="text" color="secondary" onClick={onConfirmDelete}>
                  Continue
                </Button>
              </Fragment>
            )}
          >
            <Fragment>
              <p className="ManageEmailCategories-warning">
                Are you sure that you wish to delete this email category?
              </p>
            </Fragment>
          </StandardSystemPopup>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    notifications: state.notifications
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    notificationsAPI: bindActionCreators(notificationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmailCategories);