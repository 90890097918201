import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as contentManagementAPI from '../../actions/content/management';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';
const { isDefined } = validator;

function ManageMarketingTags({ marketingTags, contentManagementAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sync, setSync] = useState(false);
  const [tags, setTags] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!init) {
      contentManagementAPI.getMarketingTags();
      setInit(true);
    }
  }, [init, contentManagementAPI]);

  useEffect(() => {
    if(loading && tags === null) {
      const { responseStatus, responseMessage, tags: tagData } = marketingTags;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setLoading(false);
        setError({ display: true, message: responseMessage });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        let sTags = tagData.slice();
        sTags.sort((a, b) => parseInt(b.tagId) - parseInt(a.tagId));
        setTags(sTags);
        setLoading(false);
      }
    }
  }, [loading, tags, marketingTags]);

  useEffect(() => {
    if(sync) {
      const { responseStatus, responseMessage, tags: tagData } = marketingTags;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setSync(false);
        setError({ display: true, message: responseMessage });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        let sTags = tagData.slice();
        sTags.sort((a, b) => parseInt(b.tagId) - parseInt(a.tagId));
        setTags(sTags);
        setSync(false);
        setSuccess({ display: true });
      }
    }
  }, [sync, tags, marketingTags]);

  const syncTags = () => {
    contentManagementAPI.syncMarketingTags();
    setSync(true);
  };

  const clearPopMessage = () => {
    setSuccess({ display: false });
    setError({ display: false });
  }

  return (
    <div className="ManageMarketingTags">
      { (loading || sync) && <LoadingMessage message='Loading...' /> }
      { !loading && 
        <Fragment>
          <h2>Marketing Tags</h2>
          <Button 
            className="ManageMarketingTags-button" 
            variant="contained" 
            color="primary" 
            disabled={sync}
            onClick={syncTags}
          >
            Sync Tags
          </Button>
        </Fragment>
      }
      <div className='ManageMarketingTags-list'>
        { !loading && !sync &&
          <Fragment>
            <p>Note: Tags are sorted so that the newest tags are at the top.</p>
            {
              tags.map(tag => {
                const { tagId, tagName } = tag;
                return (
                  <div key={`tag-${tagId}`} className="tag">
                    <div className="tagId">{tagId}</div>
                    <div className="tagName">{tagName}</div>
                  </div>
                )
              })
            }
          </Fragment>
        }
      </div> 
      { isDefined(success) && success.display &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.INFO}>
          <p>Tags successfully synchronized</p>
        </PopMessage>
      }
      { isDefined(error) && error.display &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{error.message}</p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    marketingTags: state.marketingTags
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    contentManagementAPI: bindActionCreators(contentManagementAPI, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMarketingTags);