import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormWrapper from '../form/FormWrapper';
import Form from '../form/Form';
import FormField from '../form/FormField';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import validator from '../../../utils/validator';


class UpdatePhone extends React.Component {
  constructor(props) {
    super(props);

    const { profile } = props;
    const phone = (profile.phones && profile.phones.length > 0)? profile.phones[0] : {};
    this.state = {
      hasPhone: (profile.phones && profile.phones.length > 0),
      submission: false,
      confirmDeletion: false,
      fields: {
        type: phone.type || '',
        number: phone.number || ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit(this.state.fields);
    });
  }

  onDelete() {
    this.setState({ confirmDeletion: true });
  }

  onDeleteConfirm(confirmed) {
    if(confirmed) {
      const self = this;
      this.setState({ submission: true, errors: {} }, () => {
        self.props.onSubmit(null);
      });
    } else {
      this.setState({ confirmDeletion: false });
    }
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.IS_DEFINED, prop: 'type' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'number', against: validator.patterns.PHONE, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'number' }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { hasPhone, submission, confirmDeletion, fields, errors } = this.state;
    const { displayType, onCancel } = this.props;
    
    return (
      <Fragment>
        { confirmDeletion &&
          <ConfirmationDialog message="Are you sure you want to delete this phone?" onClose={this.onDeleteConfirm} />
        }
        <FormWrapper squared={true} raised={true}>
          <Form id='UpdatePhone-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
            submitLabel="Update" submission={submission} autoComplete="off">
            
            { displayType &&
              <FormField fieldName="type" label="Type"
                error={validator.message(errors['type'], 'type')}>
                <Select name="type" value={fields.type} onChange={this.onFieldChange} error={validator.isDefined(errors['type'])}>
                  <MenuItem value=""><em>Select phone type</em></MenuItem>
                  <MenuItem value="home">Home</MenuItem>
                  <MenuItem value="work">Work</MenuItem>
                  <MenuItem value="mobile">Mobile</MenuItem>
                </Select>
              </FormField>
            }

            <FormField className="number" fieldName="number" label="Phone Number"
              error={validator.message(errors['number'], 'phone number')}>
              <Input name="number" placeholder="Phone Number" value={fields.number} onChange={this.onFieldChange}
                error={validator.isDefined(errors['number'])} />
            </FormField>

          </Form>
        </FormWrapper>
        { hasPhone &&
          <div className="delete">
            <Button className="back" variant="text" color="secondary" onClick={this.onDelete}>
              Delete this phone
            </Button>
          </div>
        }
      </Fragment>
    );
  }
}

UpdatePhone.defaultProps = {
  displayType: true
}

UpdatePhone.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdatePhone;