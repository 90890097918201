
const state = {
  authStatus: {
    DEFAULT: 'DEFAULT',
    ERROR: 'ERROR',
    WAITING: 'WAITING',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    AUTH_COMPLETE: 'AUTH_COMPLETE',
    SSO_COMPLETE: 'SSO_COMPLETE',
    AUTH_CODE_SENT: 'AUTH_CODE_SENT',
    RESET_LINK_SENT: 'RESET_LINK_SENT',
    RESET_COMPLETE: 'RESET_COMPLETE'
  },
  
  keys: {
    COURSE_LIST: 'courses',
    COURSE_MAPS: 'courseMaps',
    COURSE_REG: 'registration',
    COURSE_REG_DELETE: 'deleteRegistration',
    COURSE_REG_NEW: 'newRegistration',
    COURSE_REG_UPDATE: 'updateRegistration',
    EXPORT: 'export',
    JOBS: 'jobs',
    MAGDALA_COMMLIST: 'magdalaCommList',
    MAGDALA_COMM: 'magdalaCommunity',
    MAGDALA_COMM_UPDATES: 'magdalaCommunityUpdates',
    MAGDALA_MBRLIST: 'magdalaCommMembers',
    MAGDALA_REG: 'magdalaReg',
    PROFILES: 'profiles',
    PROFILE_UPDATES: 'profileUpdates',
    PROFILE_DONATIONS: 'profileDonations',
    PROFILE_DELETE: 'profileDelete',
    PROGRESS_TRANSCRIPTS: 'transcripts',
    PROGRESS_ASSESSMENTS: 'assessments'
  },

  messageTypes: {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
  },

  responseStatus: {
    DEFAULT: 'DEFAULT',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR'
  }
};

export default state;