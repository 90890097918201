import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as magdalaAPI from '../../../actions/magdala';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import FormField from '../../common/form/FormField';
import stateConfig from '../../../config/state';
import { sortObjArray } from '../../../utils/object';
import validator from '../../../utils/validator';


class CommunitySelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stateId: `${stateConfig.keys.MAGDALA_COMMLIST}.all`,
      communities: null,
      communityId: '',
      formError: null,
      communitiesError: null
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidMount() {
    this.props.magdalaAPI.getCommunities();
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.communities;
    const prevIds = prevProps.communities.ids;
    const communities = byId[this.state.stateId];

    if(ids !== prevIds && communities) {
      if(communities.error) {
        this.setState({ loading: false, communitiesError: communities.error });
      } else {
        const sortedCommunities = sortObjArray(communities, 'communityName');
        this.setState({ loading: false, communities: sortedCommunities });
      }
    }
  }

  onFieldChange(event) {
    this.setState({ communityId: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    const self = this;
    const { communityId } = this.state;

    if(validator.isEmpty(communityId)) {
      this.setState({ formError: 'You must select a community' });
      return;
    }

    this.setState({ formError: null }, () => {
      self.props.onComplete(communityId);
    });
  }

  clearPopMessage() {
    this.setState({ communitiesError: null });
  }

  render() {
    const { title, submitLabel } = this.props;
    const { loading, communities, communityId, formError, communitiesError } = this.state;
    return (
      <div className="CommunitySelection">
        { loading && <LoadingMessage message='Loading communities...' /> }
        { !loading &&
          <FormWrapper squared={true} raised={true}>
            <h2>{title}</h2>
            <Form id='CommunitySelection-form' onSubmit={this.onSubmit} submitLabel={submitLabel} autoComplete="off">
              <FormField fieldName="communityId" label="Community" error={formError}>
                <Select name="communityId" value={communityId} onChange={this.onFieldChange}
                  error={validator.isDefined(formError)}>
                  {
                    communities.map(community => (
                      <MenuItem key={community.communityId} value={community.communityId}>{community.communityName}</MenuItem>
                    ))
                  }
                </Select>
              </FormField>
            </Form>
          </FormWrapper>
        }
        { communitiesError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>The list of Magdala Apostolate communities could not be loaded.</p>
          </PopMessage>
        }
      </div>
    );
  }
}

CommunitySelection.propTypes = {
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    communities: state.magdalaCommunities
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySelection);