import { PAGE_MESSAGE_PUBLISHED } from '../actions/actionTypes';
import initialState from './initialState';

function appDisplayReducer(state = initialState.appDisplay, action) {
  const appDisplay = Object.assign({}, state);

  switch(action.type) {
    case PAGE_MESSAGE_PUBLISHED:
      appDisplay.pageMessage = action.data;
      return appDisplay;
    default:
      return state;
  }
}

export default appDisplayReducer;