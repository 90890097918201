const baseUrl = process.env.PUBLIC_URL;
const adminHubUrl = (process.env.NODE_ENV === 'development')? process.env.REACT_APP_ADMIN_HUB_ENDPOINT : `https://${window.location.hostname}`;
const hubUrl = (process.env.NODE_ENV === 'production' && window.location.hostname.includes('icctest.work'))? process.env.REACT_APP_HUB_ENDPOINT_STAGING : process.env.REACT_APP_HUB_ENDPOINT;
const contentSiteUrl = process.env.REACT_APP_CONTENT_BASE;
const contentAPIPath = '/ui/api/content';

const urls = {
  baseUrl,

  // API URLs
  adminHubUrl,
  adminLogin: `${adminHubUrl}/auth/login`,
  adminLogout: `${adminHubUrl}/auth/logout`,
  adminHubUIApiRoot: `${adminHubUrl}/ui/api`,
  hubUIApiRoot: `${hubUrl}/ui/api`,

  // WP Site URLs
  contentSiteUrl,

  // App URLs
  root: `${baseUrl}/`,
  monitor: `${baseUrl}/monitor`,
  contentAdmin: `${baseUrl}/sso/content`,
  courseAdmin: `${baseUrl}/sso/course`,
  cacheClear: `${baseUrl}/content/cache`,
  dashboard: `${baseUrl}/dashboard`,
  courseAttendance: `${baseUrl}/course/attendance`,
  courseMaps: `${baseUrl}/course/maps`,
  courseRegistration: `${baseUrl}/course/registration`,
  magdalaRegistration: `${baseUrl}/magdala/registration`,
  magdalaCommunityRegistration: `${baseUrl}/magdala/community/registration`,
  magdalaCommunityManage: `${baseUrl}/magdala/community/manage`,
  manageMarketingTags: `${baseUrl}/content/marketing/tags`,
  memberManage: `${baseUrl}/member/manage`,
  memberDonations: `${baseUrl}/member/donations`,
  memberRegistration: `${baseUrl}/member/registration`,
  manageEmailCategories: `${baseUrl}/notifications/email/categories`,
  sendNotifications: `${baseUrl}/notifications/send`,
  studentTranscript: `${baseUrl}/student/transcript`,
  reportDownload: `${baseUrl}/report/download`,
  courseReports: `${baseUrl}/report/course`,
  courseProgressReport: `${baseUrl}/report/course/progress`,
  courseTranscriptReport: `${baseUrl}/report/course/transcript`,

  // App URLs - Authentication
  login: `${baseUrl}/login`,
  logout: `${baseUrl}/logout`,
  pwdResetRequest: `${hubUrl}/login/reset/request`,

  reportOptions: {
    course: [
      { display: 'Course Progress', url: `${baseUrl}/report/course/progress` },
      { display: 'Student Transcript', url: `${baseUrl}/report/course/transcript` }
    ]
  },

  cacheOptions: [
    { display: 'Article', url: `${contentAPIPath}/articles/:slug`, field: ['Slug', 'slug'] },

    { display: 'Course', url: `${contentAPIPath}/courses/slug/:slug`, field: ['Slug', 'slug'] },
    { display: 'Courses', url: `${contentAPIPath}/courses`, field: null },
    { display: 'Course - Live Status', url: `${contentAPIPath}/courses/slug/:slug/live`, field: ['Slug', 'slug'] },
    { display: 'Course - Topic', url: `${contentAPIPath}/courses/topics/:topicId`, field: ['Content ID', 'topicId'] },
    { display: 'Course - Assessment', url: `${contentAPIPath}/courses/assessments/:assessmentId`, field: ['Content ID', 'assessmentId'] },
    
    { display: 'Event', url: `${contentAPIPath}/events/slug/:slug`, field: ['Slug', 'slug'] },
    { display: 'Event - Live Status', url: `${contentAPIPath}/events/slug/:slug/live`, field: ['Slug', 'slug'] },
    { display: 'Event - List', url: `${contentAPIPath}/events/list/:slug`, field: ['Slug', 'slug'] },
    { display: 'Event - Categories/Tags', url: `${contentAPIPath}/events/taxonomy`, field: null },

    { display: 'Instructor', url: `${contentAPIPath}/instructors/:instructorId`, field: ['Instructor ID', 'instructorId'] },
    { display: 'Instructors', url: `${contentAPIPath}/instructors`, field: null },

    { display: 'Page', url: `${contentAPIPath}/pages/:slug`, field: ['Slug', 'slug'] },

    { display: 'Radio', url: `${contentAPIPath}/radio`, field: null },
    { display: 'Radio - Episode', url: `${contentAPIPath}/radio/slug/:slug`, field: ['Slug', 'slug'] },
    { display: 'Radio - Settings', url: `${contentAPIPath}/radio/settings`, field: null },

    { display: 'Reflection', url: `${contentAPIPath}/reflections/slug/:slug`, field: ['Slug', 'slug'] },
    { display: 'Reflections - Latin', url: `${contentAPIPath}/reflections/latin`, field: null },
    { display: 'Reflections - Byzantine', url: `${contentAPIPath}/reflections/byzantine`, field: null },

    { display: 'Snippet', url: `${contentAPIPath}/snippets/:slug`, field: ['Slug', 'slug'] },

    { display: 'System - Alert', url: `${contentAPIPath}/system/alert`, field: null },
    { display: 'System - Ads', url: `${contentAPIPath}/system/ads`, field: null },
    { display: 'System - Annual Report', url: `${contentAPIPath}/system/annual-report`, field: null },
    { display: 'System - Magdala Content', url: `${contentAPIPath}/system/magdala`, field: null },
    { display: 'System - Mobile Config', url: `${contentAPIPath}/system/mobile/config`, field: null },
    { display: 'System - Web Config', url: `${contentAPIPath}/system/web/config`, field: null },
    { display: 'System - Feature Banner', url: `${contentAPIPath}/system/feature/banner`, field: null },
    { display: 'System - Video Promo', url: `${contentAPIPath}/system/feature/video-promo`, field: null },
    { display: 'System - Popup', url: `${contentAPIPath}/system/popup`, field: null },
    { display: 'System - Privacy Policy', url: `${contentAPIPath}/system/policies/privacy`, field: null },

    { display: 'View - Browse', url: `${contentAPIPath}/events/browse`, field: null },
    { display: 'View - Courses', url: `${contentAPIPath}/events/courses`, field: null },
    { display: 'View - Live Events', url: `${contentAPIPath}/events`, field: null },
  ]
};

export default urls;
