import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import urls from '../../../config/urls';


function NavList(props) {
  const { onClose, user } = props;
  const { data: { email, firstName, lastName } } = user;
  return (
    <div 
      className="NavList"
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}>
      
      <div className="NavList-user">
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => window.location = urls.adminLogout}
        >
          Log out
        </Button>
        <p className="name">{firstName} {lastName}</p>
        <p className="email">{email}</p>
      </div>

      <Divider />

      <div className="NavList-links">
        <ul>
          <li className="menu-item"><Link to={urls.dashboard}>Dashboard</Link></li>
          {/*
          <li className="menu-item"><Link to={urls.contentAdmin}>Content Admin</Link></li>
          <li className="menu-item"><Link to={urls.courseAdmin}>Course Admin</Link></li>
          */}
        </ul>
      </div>

    </div>
  );
}

NavList.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default withRouter(connect(mapStateToProps)(NavList));