import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Icon, { IconTypes } from '../../common/Icon';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function RegistrationDisplay({ result, fullDisplay }) {
  const [copied, setCopied] = useState(false);
  const { type, status, track, details, registration, email, salutation, firstName, lastName } = result;

  const copyJoinLink = async () => {
    await navigator.clipboard.writeText(registration.webinarLink);
    setCopied(true);
  };
  
  return (
    <div className="CourseRegistrationDisplay">
      { fullDisplay &&
        <Fragment>
          <h2>Registration for: <span>{details.title}</span></h2>
          <div className="field">
            <span>Email:</span>
            <p>{email}</p>
          </div>
          <div className="field">
            <span>Salutation:</span>
            <p>{salutation}</p>
          </div>
          <div className="field">
            <span>Name:</span>
            <p>{firstName} {lastName}</p>
          </div>
          <div className="field">
            <span>Track:</span>
            <p>{track === 'audit'? 'Audit' : 'Certificate'}</p>
          </div>
        </Fragment>
      }
      { isNotDefined(registration) &&
        <div className="field">
          <span>Registration Status:</span>
          <p>{status === 'registered' && type !== 'live'? 'Registered' : 'Registration error, no registration details found. Delete this registration and re-attempt.'}</p>
        </div>
      }
      { isDefined(registration) &&
        <div className="field">
          <span>Registration Status:</span>
          <p>{registration.status.code === '0000'? 'Registered' : `Registration error - ${registration.status.message}`}</p>
        </div>
      }
      { isDefined(registration) && isDefined(registration.status) && registration.status.code !== '0000' &&
        <div className="field">
          <span>Steps Completed:</span>
          <ul>
            {
              registration.status.stepsCompleted.map((step, index) => (
                <li key={`step-${index}`}>{step}</li>
              ))
            }
          </ul>
        </div>
      }
      { isDefined(registration) && isDefined(registration.webinarLink) &&
        <div className="field">
          <span>Join Link:</span>
          <div className="value">
            <a href={registration.webinarLink} target="_blank" rel="noopener noreferrer">
              {registration.webinarLink}
            </a>
            <div className="copy" onClick={copyJoinLink}>
              <div className="copy-button">
                <Icon type={IconTypes.Copy} /> { copied? 'Copied' : 'Copy Link' }
              </div>
            </div>
          </div>
        </div>
      }
      { fullDisplay &&
        <div className="actions">
          <Link to={urls.dashboard}>Back to the Dashboard</Link>
        </div>
      }
    </div>
  );
}

RegistrationDisplay.defaultProps = {
  actions: false,
  fullDisplay: true
};

RegistrationDisplay.propTypes = {
  result: PropTypes.object
};

export default withRouter(RegistrationDisplay);
