import stateConfig from '../config/state';

class TrackedState {
  constructor() {
    this.ids = [];
    this.byId = {};
  }

  track(key, objectId = '') {
    const stateId = objectId? `${key}.${objectId}` : key;
    clearStateById(this.ids, this.byId, stateId);
    this.ids.push(stateId);
    this.byId[stateId] = { time: Date.now() };
  }

  isFresh(key, objectId = '') {
    const stateId = objectId? `${key}.${objectId}` : key;
    if(this.ids.includes(stateId)) {
      const timeout = stateConfig[key] || stateConfig.defaultCacheTimeout;
      if(Date.now() - this.byId[stateId].time < timeout) {
        return true;
      }

      clearStateById(this.ids, this.byId, stateId);
    }

    return false;
  }
}

export function clearStateById(idList, state, stateId) {
  const currentIndex = idList.findIndex(id => id === stateId);
  if(currentIndex !== -1) {
    idList.splice(currentIndex, 1);
    delete state[stateId];
  }
};

const tracked = new TrackedState();
Object.freeze(tracked);

const state = {
  trackedState: tracked
};

export default state;
