import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import * as profileAPI from '../../actions/profile';
import * as appDisplay from '../../actions/appDisplay';
import FormWrapper from '../common/form/FormWrapper';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import PopMessage from '../common/messages/PopMessage';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import validator from '../../utils/validator';


class MemberRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: false,
      fields: {
        email: '',
        salutation: '',
        firstName: '',
        lastName: ''
      },
      errors: {},
      registrationError: null
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { email } = this.state.fields;
    const { ids, byId } = this.props.updates;
    const prevIds = prevProps.updates.ids;
    const update = byId[`${stateConfig.keys.PROFILE_UPDATES}.${email}`];

    if(ids !== prevIds && update) {
      if(update.error) {
        this.setState({ submission: false, registrationError: update.error });
      } else {
        this.props.history.push(`${urls.memberManage}?email=${email}&newMember=true`);
      }
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.profileAPI.registerMember(this.state.fields);
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  clearPopMessage() {
    this.setState({ registrationError: null });
  }

  render() {
    const { submission, fields, errors, registrationError } = this.state;
    return (
      <div className="MemberRegistration">
        <FormWrapper squared={true} raised={true}>
          <h2>Register Member</h2>
          <Form id='MemberRegistration-form' onSubmit={this.onSubmit} submitLabel="Register" 
            submission={submission} autoComplete="off">

              <FormField fieldName="email" label="Email*"
                error={validator.message(errors['email'], 'email')}>
                <Input name="email" placeholder='Email' value={fields.email}
                  onChange={this.onFieldChange} error={validator.isDefined(errors['email'])} />
              </FormField>

              <FormField fieldName="salutation" label="Salutation" 
                error={validator.message(errors['salutation'], 'salutation')}>
                <Input name="salutation" placeholder='Salutation' value={fields.salutation} 
                  onChange={this.onFieldChange} error={validator.isDefined(errors['salutation'])} />
              </FormField>

              <FormField fieldName="firstName" label="First Name" 
                error={validator.message(errors['firstName'], 'first name')}>
                <Input name="firstName" placeholder='First Name' value={fields.firstName}
                  onChange={this.onFieldChange} error={validator.isDefined(errors['firstName'])} />
              </FormField>
              
              <FormField fieldName="lastName" label="Last Name" 
                error={validator.message(errors['lastName'], 'last name')}>
                <Input name="lastName" placeholder='Last Name' value={fields.lastName}
                  onChange={this.onFieldChange} error={validator.isDefined(errors['lastName'])} />
              </FormField>

          </Form>
        </FormWrapper>
        { registrationError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>This registration failed: {registrationError.code} - {registrationError.message}</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    updates: state.profileUpdates
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberRegistration)
);