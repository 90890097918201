import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as magdalaAPI from '../../../actions/magdala';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import ConfirmationDialog from '../../common/dialogs/ConfirmationDialog';
import stateConfig from '../../../config/state';


class CommunityMembers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      confirmDeletion: false,
      members: null,
      selectedEmail: '',
      error: null,
      registrationError: null
    };

    this.deleteCommunityMember = this.deleteCommunityMember.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
  }

  componentDidMount() {
    this.props.magdalaAPI.getCommunityMembers(this.props.communityId);
  }

  componentDidUpdate(prevProps) {
    const self = this;
    const { ids, byId } = this.props.communities;
    const prevIds = prevProps.communities.ids;
    const members = byId[`${stateConfig.keys.MAGDALA_MBRLIST}.${this.props.communityId}`];

    if(ids !== prevIds && members) {
      if(members.error) {
        this.setState({ loading: false, error: members.error });
      } else {
        this.setState({ loading: false, members });
      }
    }

    const { ids: regIds, byId: regById } = this.props.registrations;
    const prevRegIds = prevProps.registrations.ids;
    const registration = regById[`${stateConfig.keys.MAGDALA_REG}.${this.state.selectedEmail}`];

    if(regIds !== prevRegIds && registration) {
      if(registration.error) {
        this.setState({ registrationError: registration.error });
      } else if(registration.deleted) {
        this.setState({ loading: true, members: null }, () => {
          self.props.magdalaAPI.getCommunityMembers(this.props.communityId);
        });
      }
    }
  }

  deleteCommunityMember = email => () => {
    this.setState({ confirmDeletion: true, selectedEmail: email });
  }

  onDeleteConfirm(confirmed) {
    if(confirmed) {
      this.props.magdalaAPI.deleteRegistration({ email: this.state.selectedEmail });
    } else {
      this.setState({ confirmDeletion: false });
    }
  }

  render() {
    const { loading, confirmDeletion, members, error, registrationError } = this.state;
    return (
      <div className="CommunityMembers">
        { loading && <LoadingMessage message='Loading community members...' /> }
        { confirmDeletion &&
          <ConfirmationDialog message="Are you sure you want to remove this community member?" onClose={this.onDeleteConfirm} />
        }
        { members !== null && members.length === 0 &&
          <div>None</div>
        }
        { members !== null && members.length > 0 &&
          <ul>
            {
              members.map((member, index) => {
                const { email, salutation, firstName, lastName } = member;
                return (
                  <li key={`member-${index}`}>
                    <span className="name">{`${salutation}${` ${firstName}`} ${lastName}`}</span>
                    <span className="action">
                      <Button variant="text" color="secondary" onClick={this.deleteCommunityMember(email)}>
                        Delete
                      </Button>
                    </span>
                  </li>
                );
              })
            }
          </ul>
        }
        { error !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage} type={stateConfig.messageTypes.ERROR}>
            <p>The community members could not be loaded.</p>
          </PopMessage>
        }
        { registrationError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage} type={stateConfig.messageTypes.ERROR}>
            <p>The selected community member could not be deleted.</p>
          </PopMessage>
        }
      </div>
    );
  }
}


CommunityMembers.propTypes = {
  communityId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    communities: state.magdalaCommunities,
    registrations: state.magdalaRegistrations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityMembers);