import { combineReducers } from 'redux';
import appDisplay from './appDisplay';
import attendance from './attendance';
import courseProgress from './courseProgress';
import courseRegistrations from './courseRegistrations';
import courses from './courses';
import jobs from './jobs';
import magdalaCommunities from './magdalaCommunities';
import magdalaCommunityUpdates from './magdalaCommunityUpdates';
import magdalaRegistrations from './magdalaRegistrations';
import marketingTags from './marketingTags';
import notifications from './notifications';
import profiles from './profiles';
import profileUpdates from './profileUpdates';
import user from './user';

const rootReducer = combineReducers({
  appDisplay,
  attendance,
  courseProgress,
  courseRegistrations,
  courses,
  jobs,
  magdalaCommunities,
  magdalaCommunityUpdates,
  magdalaRegistrations,
  marketingTags,
  notifications,
  profiles,
  profileUpdates,
  user
});

export default rootReducer;