import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import * as magdalaAPI from '../../actions/magdala';
import FormWrapper from '../common/form/FormWrapper';
import Form from '../common/form/Form';
import FormField from '../common/form/FormField';
import PopMessage from '../common/messages/PopMessage';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import validator from '../../utils/validator';
import { random } from '../../utils/utils';


class CommunityRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      communityName: '',
      requestId: null,
      formError: null,
      communitiesError: null
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.registerCommunity = this.registerCommunity.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.communities;
    const prevIds = prevProps.communities.ids;
    const community = byId[`${stateConfig.keys.MAGDALA_COMM}.${this.state.requestId}`];

    if(ids !== prevIds && community) {
      if(community.error) {
        this.setState({ submission: false, communitiesError: community.error });
      } else {
        this.props.history.push(`${urls.magdalaCommunityManage}?communityId=${community.communityId}&newCommunity=true`);
      }
    }
  }

  onFieldChange(event) {
    this.setState({ communityName: event.target.value });
  }

  registerCommunity(event) {
    event.preventDefault();
    const self = this;
    const { communityName } = this.state;

    if(validator.isEmpty(communityName)) {
      this.setState({ formError: 'You must enter the community name' });
      return;
    }

    const requestId = random();
    this.setState({ submission: true, requestId, formError: null }, () => {
      self.props.magdalaAPI.createCommunity(requestId, { communityName });
    });
  }

  clearPopMessage() {
    this.setState({ communitiesError: null });
  }

  render() {
    const { submission, communityName, formError, communitiesError } = this.state;
    return (
      <div className="CommunityRegistration">
        <FormWrapper squared={true} raised={true}>
          <h2>Register Community</h2>
          <Form id='CommunityRegistration-form' onSubmit={this.registerCommunity} autoComplete="off"
            submitLabel="Register" submission={submission}>
            <FormField fieldName="communityName" label="Community Name" error={formError}>
              <Input name="communityName" value={communityName} onChange={this.onFieldChange}
                error={validator.isDefined(formError)} />
            </FormField>
          </Form>
        </FormWrapper>
        { communitiesError !== null &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>The registration failed with the following message: {communitiesError.code} - {communitiesError.message}</p>
          </PopMessage>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    communities: state.magdalaCommunities
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunityRegistration)
);
