import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as profileAPI from '../../../actions/profile';
import UpdateContact from './edit/UpdateContact';
import UpdateEmail from './edit/UpdateEmail';
import UpdateName from './edit/UpdateName';
import UpdatePassword from './edit/UpdatePassword';
import EditAddress from '../../common/member/EditAddress';
import EditPhone from '../../common/member/EditPhone';


class MemberEdit extends React.Component {
  constructor(props) {
    super(props);
    this.performUpdate = this.performUpdate.bind(this);
  }

  performUpdate(data) {
    const { profile, field } = this.props;
    switch(field) {
      case 'email':
        this.props.profileAPI.updateEmail(data.email, data.newEmail, data.merge);
        this.props.onEmailUpdate(data.newEmail);
        break;
      case 'password':
        this.props.profileAPI.updatePassword(data.email, data.password);
        break;
      default:
        this.props.profileAPI.updateProfile({
          field,
          email: profile.email,
          data
        });
    }
  }

  render() {
    const { profile, error, field, onCancel } = this.props;
    
    return (
      <div className="MemberEdit">
        { field === 'email' &&
          <UpdateEmail profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'contactId' &&
          <UpdateContact profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'name' &&
          <UpdateName profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'address' &&
          <EditAddress profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'phone' &&
          <EditPhone profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
        { field === 'password' &&
          <UpdatePassword profile={profile} error={error} onSubmit={this.performUpdate} onCancel={onCancel} />
        }
      </div>
    );
  }
}

MemberEdit.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  field: PropTypes.string,
  onCancel: PropTypes.func,
  onEmailUpdate: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberEdit);