import React from 'react';
import PropTypes from 'prop-types';
import validator from '../../../utils/validator';
import { formatPhone } from '../../../utils/format';


function CommunityContactDetails(props) {
  const { onEdit } = props;
  let { superior, formationDirectress, mainContactName, mainContactEmail, address, phone } = props.data || {};

  return (
    <div className="CommunityContactDetails">
      <h3>Contact Information</h3>
      <div className="field editable" onClick={onEdit('superior')}>
        <div className="label">Superior</div>
        <div className="value">{superior || 'None listed'}</div>
      </div>
      <div className="field editable" onClick={onEdit('formationDirectress')}>
        <div className="label">Formation Directress</div>
        <div className="value">{formationDirectress || 'None listed'}</div>
      </div>
      <div className="field editable" onClick={onEdit('mainContact')}>
        <div className="label">Main Contact</div>
        <div className="value">{(mainContactName || mainContactEmail)? `${mainContactName? mainContactName : 'No name listed'} - ${mainContactEmail? mainContactEmail : 'No email listed'}` : 'None listed'}</div>
      </div>
      <div className="field editable" onClick={onEdit('phone')}>
        <div className="label">Phone</div>
        <div className="value">{phone && phone.number? formatPhone(phone.number) : 'None listed'}</div>
      </div>
      { address? (
          <div className="field address editable" onClick={onEdit('address')}>
            <div className="label">Address</div>
            <div className="value">
              { validator.isNotEmpty(address.addressLine1) && <span>{address.addressLine1}</span> }
              { validator.isNotEmpty(address.addressLine2) && <span>{address.addressLine2}</span> }
              { validator.isNotEmpty(address.addressLine3) && <span>{address.addressLine3}</span> }
              { (address.city || address.state || address.postalCode) &&
                <span>
                  { validator.isNotEmpty(address.city) && validator.isNotEmpty(address.state) && `${address.city} ${address.state}` }
                  { validator.isNotEmpty(address.postalCode) && `, ${address.postalCode}` }
                </span>
              }
              { validator.isNotEmpty(address.country) && <span>{address.country}</span> }
            </div>
          </div>
        ) : (
          <div className="field address editable" onClick={onEdit('address')}>
            <div className="label">Address</div>
            <div className="value">None listed</div>
          </div>
        )
      }
    </div>
  );
}

CommunityContactDetails.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func
};

export default CommunityContactDetails;