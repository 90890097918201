import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormWrapper from '../../../common/form/FormWrapper';
import Form from '../../../common/form/Form';
import FormField from '../../../common/form/FormField';
import validator from '../../../../utils/validator';


class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: false,
      fields: {
        password: '',
        passwordConfirmation: ''
      },
      errors: {}
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if(error && error !== prevError) {
      this.setState({ submission: false });
    }
  }

  onFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmit(event) {
    event.preventDefault();

    const self = this;
    const { email } = this.props.profile;
    const { password } = this.state.fields;
    if(!this.validate()) {
      return;
    }

    this.setState({ submission: true, errors: {} }, () => {
      self.props.onSubmit({ email, password });
    });
  }

  validate() {
    const data = this.state.fields;
    let rules = [
      { rule: validator.rules.PASSWORD_CHECK, prop: 'password', empty: true },
      { rule: validator.rules.PASSWORD_MATCH, prop: 'passwordConfirmation', against: data.password }
    ];
    
    const validationErrors = validator.validate(data, rules);
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false;
    }

    return true;
  }

  render() {
    const { submission, fields, errors } = this.state;
    const { onCancel } = this.props;
    
    return (
      <FormWrapper squared={true} raised={true}>
        <Form id='UpdatePassword-form' onSubmit={this.onSubmit} onClickSecondary={onCancel}
          submitLabel="Update" submission={submission} autoComplete="off">

          <FormField fieldName="password" label="Password"
            error={validator.message(errors['password'], 'password')}>
            <Input type="password" name="password" value={fields.password} 
              onChange={this.onFieldChange} error={validator.isDefined(errors['password'])} />
          </FormField>

          <FormField fieldName="passwordConfirmation" label="Confirm Password"
            error={validator.message(errors['passwordConfirmation'], 'password confirmation')}>
            <Input type="password" name="passwordConfirmation" value={fields.passwordConfirmation} 
              onChange={this.onFieldChange} error={validator.isDefined(errors['passwordConfirmation'])} />
          </FormField>

        </Form>
      </FormWrapper>
    );
  }
}

UpdatePassword.propTypes = {
  profile: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdatePassword;