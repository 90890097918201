import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as coursesAPI from '../../../actions/content/courses';
import * as jobsAPI from '../../../actions/jobs';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import JobStatusMessage from '../../common/messages/JobStatusMessage';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import validator from '../../../utils/validator';
const { isNotDefined } = validator;

const statuses = [
  { value: 'pending', display: 'Pending' },
  { value: 'registered', display: 'Registered' },
  { value: 'started', display: 'Started' },
  { value: 'complete', display: 'Complete' },
  { value: 'archived', display: 'Archived' }
];

const tracks = [
  { value: 'audit', display: 'Audit' },
  { value: 'certificate', display: 'Certificate' }
];


function CourseProgressReport({ courses: coursesState, jobs, coursesAPI, jobsAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [starting, setStarting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [courses, setCourses] = useState([]);
  const [courseFilters, setCourseFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [trackFilters, setTrackFilters] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!init) {
      coursesAPI.getCourses();
      setInit(true);
    }
  }, [init, coursesAPI]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.COURSE_LIST}.summary`;
    if(courses.length === 0 && isNotDefined(error) && coursesState.ids.includes(stateId)) {
      const data = coursesState.byId[stateId];
      if(data && data.error) {
        setError('Failed to load report filter options');
      } else {
        setCourses(data.results.filter(course => course.status === 'open').reverse());
      }
      setLoading(false);
    }
  }, [courses, error, coursesState.ids, coursesState.byId]);

  useEffect(() => {
    const data = jobs.exportById[`${stateConfig.keys.EXPORT}.courseProgress`];
    if(starting && jobs.exportIds && data) {
      if(data && data.error) {
        setError('Failed to start course progress export');
      } else {
        setJobId(data.jobId);
        setStarting(false);
        setProcessing(true);
      }
    }
  }, [jobId, starting, jobs.exportIds, jobs.exportById]);

  const updateFilters = (type, filter) => () => {
    let arr = null, fn = null;
    switch(type) {
      case 'course':
        arr = courseFilters.slice();
        fn = setCourseFilters;
        break;
      case 'status':
        arr = statusFilters.slice();
        fn = setStatusFilters;
        break;
      case 'track':
        arr = trackFilters.slice();
        fn = setTrackFilters;
        break;
      default:
        // do nothing
        return;
    }

    const index = arr.findIndex(item => item.value === filter.value);
    if(index === -1) {
      arr.push(filter);
    } else {
      arr.splice(index, 1);
    }
    fn(arr);
  };

  const listFilters = arr => {
    const display = arr.map(filter => filter.display);
    return display.join('<br />');
  };

  const startExport = () => {
    setStarting(true);
    const cFilters = courseFilters.length === 0? 'all' : courseFilters.map(filter => filter.value);
    const sFilters = statusFilters.length === 0? 'all' : statusFilters.map(filter => filter.value);
    const tFilters = trackFilters.length === 0? 'all' : trackFilters.map(filter => filter.value);
    jobsAPI.startCourseProgressExport(cFilters, sFilters, tFilters);
  };

  const onComplete = result => {
    setProcessing(false);
    if(result.success) {
      setSuccess('The course progress export has successfully completed!');
      setResult(result.data);
    } else {
      setError(result.message);
    }
  };

  const downloadReport = () => {
    window.open(`${urls.adminHubUrl}/ui/api/export/${jobId}`, 'CourseProgressExport', 'noopener noreferrer');
  };

  const clearPopMessage = () => {
    setError(null);
    setStarting(false);
  };

  return (
    <div className="Report CourseProgressReport">
      { loading &&
        <div className="center"><LoadingMessage message='Loading...' /></div>
      }
      { starting && !processing &&
        <div className="center"><LoadingMessage message='Starting export...' /></div>
      }
      { !loading && !starting && !processing && result === null &&
        <div className="CourseProgressReport-start">
          <h2>Course Progress Report</h2>
          <p className="message">
            Click on filters from the lists below if you wish to narrow your report results.
          </p>
          <div className="filters">
            <div className="course-filters">
              <h4>Courses:</h4>
              <div dangerouslySetInnerHTML={{__html: courseFilters.length === 0? '<span>All</span>' : listFilters(courseFilters)}} />
            </div>
            <div className="status-filters">
              <h4>Statuses:</h4>
              <div dangerouslySetInnerHTML={{__html: statusFilters.length === 0? 'All' : listFilters(statusFilters)}} />
            </div>
            <div className="track-filters">
              <h4>Tracks:</h4>
              <div dangerouslySetInnerHTML={{__html: trackFilters.length === 0? 'All' : listFilters(trackFilters)}} />
            </div>
          </div>
          <div className="filter-lists">
            <h3>Course Filters:</h3>
            <div className="course-list">
              {
                courses.map((course, index) => {
                  const { id: courseId, title, magdala } = course;
                  const filter = { display: title, value: courseId };
                  return (
                    <div 
                      key={`course-${index}`} 
                      className={`course-list-item ${courseFilters.find(item => item.value === courseId)? 'selected' : ''}`} 
                      onClick={updateFilters('course', filter)}
                    >
                      {magdala? `MA: ${title}` : title}
                    </div>
                  );
                })
              }
            </div>
            <div className="two-cols">
              <div className="status-list">
                <h3>Status Filters:</h3>
                {
                  statuses.map((status, index) => (
                    <div 
                      key={`status-${index}`}  
                      className={`status-list-item ${statusFilters.find(item => item.value === status.value)? 'selected' : ''}`}
                      onClick={updateFilters('status', status)}
                    >
                      {status.display}
                    </div>
                  ))
                }
              </div>
              <div className="track-list">
                <h3>Track Filters:</h3>
                {
                  tracks.map((track, index) => (
                    <div 
                      key={`track-${index}`} 
                      className={`track-list-item ${trackFilters.find(item => item.value === track.value)? 'selected' : ''}`}
                      onClick={updateFilters('track', track)}
                    >
                      {track.display}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="action">
            <Button variant="contained" color="primary" onClick={startExport}>
              Start Export
            </Button>
          </div>
        </div>
      }
      { !starting && processing && jobId &&
        <JobStatusMessage 
          jobId={jobId} 
          type="export"
          heading="Processing course progress report"
          onComplete={onComplete}   
        />
      }
      { result !== null &&
        <div className="download">
          <p className="message">{`${result.progress.processed} records processed. 100% complete.`}</p>
          <Button variant="contained" color="primary" onClick={downloadReport}>
            Download
          </Button>
        </div>
      }
      { success !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.INFO}>
          <p>{success}</p>
        </PopMessage>
      }
      { error !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{error}</p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    jobs: state.jobs
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    jobsAPI: bindActionCreators(jobsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseProgressReport);