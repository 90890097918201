import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import validator from '../../../utils/validator';


function ConfirmationDialog(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = confirm => () => {
    setOpen(false);
    props.onClose(confirm);
  };

  return (
    <div>
      <Dialog className={`ConfirmationDialog ${props.className}`} open={open} onClose={handleClose}>
        <DialogTitle className="title">Please Confirm</DialogTitle>
        <DialogContent>
          { props.message &&
            <DialogContentText className="content">
              {props.message}
            </DialogContentText>
          }
          { validator.isEmpty(props.message) &&
            <div className="content">
              {props.children}
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose(true)} color="default" disabled={props.disabled}>
            Continue
          </Button>
          <Button onClick={handleClose(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmationDialog.defaultProps = {
  disabled: false,
  className: ''
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func
};

export default ConfirmationDialog;