import moment from 'moment-timezone';

export function formatDateTimeEST(datetimeStr, provideDateObj = false) {
  let dt = moment(datetimeStr).tz('America/New_York');
  if(dt.isValid()) {
    const dtStr = dt.format('MM/DD/YYYY hh:mm A');
    return provideDateObj? { date: dt.toDate(), str: `${dtStr} EST` } : `${dtStr} EST`;
  } else {
    // trying to deal with a hard to recreate issue where moment sometimes throws 'invalid date' message
    // putting in this backup code in case moment is not working in some browsers
    dt = new Date(datetimeStr);
    if(dt instanceof Date && !isNaN(dt)) {
      const localeStr = dt.toLocaleString('en-US', {timeZone: 'America/New_York'});
      const formattedDate = localeStr.substr(0, 10).replace(',', '');
      const dateArr = localeStr.split(' ');
      const timeArr = dateArr[1].split(':');
      return `${formattedDate} ${timeArr[0]}:${timeArr[1]} ${dateArr[2]} EST`;
    }
  }
  return ''; // all date parsing attempts failed, something is wrong with this date
}

export function formatDateTimeForDisplay(date) {
  let month = `0${date.getMonth()+1}`;
  month = month.slice(-2);
  let day = `0${date.getDate()}`;
  day = day.slice(-2);
  const formattedDate = `${month}/${day}/${date.getFullYear()}`;

  // format according US locale
  const localeTS = date.toLocaleTimeString('en-US');
  const timeAndClockFormat = localeTS.split(' ');
  const timeIncrements = timeAndClockFormat[0].split(':');
  const formattedTime = `${timeIncrements[0]}:${timeIncrements[1]} ${timeAndClockFormat[1]} EST`;

  return `${formattedDate} ${formattedTime}`;
}

export function formatDateForDisplay(date) {
  let month = `0${date.getMonth()+1}`;
  month = month.substring(month.length-2);
  let day = `0${date.getDate()}`;
  day = day.substring(day.length-2);
  return `${month}/${day}/${date.getFullYear()}`;
}

export function formatPhone(number) {
  if(number.length === 10) {
    return `${number.substring(0, 3)}-${number.substring(3, 6)}-${number.substring(6)}`;
  } else if(number.length === 11) {
    return `${number.substring(0, 1)}-${number.substring(1, 4)}-${number.substring(4, 7)}-${number.substring(7)}`;
  } else {
    return number;
  }
}