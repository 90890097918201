import axios from 'axios';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const coursesPath = '/content/courses';

export async function getCourses() {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}`, { timeout: config.wpTimeout });
}

export async function getCourseById(courseId) {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}/${courseId}`, { timeout: config.wpTimeout });
}

export async function getCourseMaps() {
  return await axios.get(`${urls.adminHubUrl}/ui/api/course/content/maps`);
}