
const accessRoles = {
  ADMIN: 'admin',
  MAGDALA_ADMIN: 'magdala-admin'
};

const allRoles = [accessRoles.ADMIN, accessRoles.MAGDALA_ADMIN];

const config = {
  notificationMessageLimit: 300,
  wpTimeout: 30000,
  emptyFn: () => {},

  courseNotComplete: ['pending', 'registered', 'started'],
  courseComplete: ['complete', 'archived', 'archived-recorded'],
  courseArchived: ['archived', 'archived-recorded'],
  courseRegistered: 'registered',
  courseRecordOnly: 'archived-recorded',
  courseStatus: {
    'pending': 'Pending Registration, Error', 
    'registered': 'Registered, Not Started', 
    'started': 'In Progress', 
    'complete': 'Completed', 
    'archived': 'Archived', 
    'archived-recorded': 'Archived, Record Only'
  },

  pdf: {
    defaultTextColor: '#102B4E',
    defaultFont: 'Helvetica',
    landscape: [11, 8.5],
    certificatePage1: {
      dateLocation: [2.35, 6.4],
      nameLocationFn: (nameWidth) => [5.5-(nameWidth/2), 3.55]
    }
  },

  accessRoles,
  routeAccess: { // note: anything not listed here will only be available to ADMIN role
    '/dashboard': allRoles,
    '/course/attendance': allRoles
  }
};

export default config;