
const countries = {
  data: [
    { value: "United States", display: "United States"},
    { value: "United Kingdom", display: "United Kingdom"},
    { value: "Canada", display: "Canada"},
    { value: "Australia", display: "Australia"},
    { value: "", display: "--------------"},
    { value: "Afghanistan", display: "Afghanistan"},
    { value: "Albania", display: "Albania"},
    { value: "Algeria", display: "Algeria"},
    { value: "American Samoa", display: "American Samoa"},
    { value: "Andorra", display: "Andorra"},
    { value: "Angola", display: "Angola"},
    { value: "Anguilla", display: "Anguilla"},
    { value: "Antarctica", display: "Antarctica"},
    { value: "Antigua and Barbuda", display: "Antigua and Barbuda"},
    { value: "Argentina", display: "Argentina"},
    { value: "Armenia", display: "Armenia"},
    { value: "Aruba", display: "Aruba"},
    { value: "Austria", display: "Austria"},
    { value: "Åland Islands", display: "Åland Islands"},
    { value: "Azerbaijan", display: "Azerbaijan"},
    { value: "Bahamas", display: "Bahamas"},
    { value: "Bahrain", display: "Bahrain"},
    { value: "Bangladesh", display: "Bangladesh"},
    { value: "Barbados", display: "Barbados"},
    { value: "Belarus", display: "Belarus"},
    { value: "Belgium", display: "Belgium"},
    { value: "Belize", display: "Belize"},
    { value: "Benin", display: "Benin"},
    { value: "Bermuda", display: "Bermuda"},
    { value: "Bhutan", display: "Bhutan"},
    { value: "Bolivia", display: "Bolivia"},
    { value: "Bosnia and Herzegovina", display: "Bosnia and Herzegovina"},
    { value: "Botswana", display: "Botswana"},
    { value: "Bouvet Island", display: "Bouvet Island"},
    { value: "Brazil", display: "Brazil"},
    { value: "British Indian Ocean Territory", display: "British Indian Ocean Territory"},
    { value: "Brunei Darussalam", display: "Brunei Darussalam"},
    { value: "Bulgaria", display: "Bulgaria"},
    { value: "Burkina Faso", display: "Burkina Faso"},
    { value: "Burundi", display: "Burundi"},
    { value: "Cambodia", display: "Cambodia"},
    { value: "Cameroon", display: "Cameroon"},
    { value: "Cape Verde", display: "Cape Verde"},
    { value: "Cayman Islands", display: "Cayman Islands"},
    { value: "Central African Republic", display: "Central African Republic"},
    { value: "Chad", display: "Chad"},
    { value: "Chile", display: "Chile"},
    { value: "China", display: "China"},
    { value: "Christmas Island", display: "Christmas Island"},
    { value: "Cocos (Keeling) Islands", display: "Cocos (Keeling) Islands"},
    { value: "Colombia", display: "Colombia"},
    { value: "Comoros", display: "Comoros"},
    { value: "Congo", display: "Congo"},
    { value: "Democratic Republic Of Congo", display: "Democratic Republic Of Congo"},
    { value: "Cook Islands", display: "Cook Islands"},
    { value: "Costa Rica", display: "Costa Rica"},
    { value: "Croatia", display: "Croatia"},
    { value: "Cuba", display: "Cuba"},
    { value: "Cyprus", display: "Cyprus"},
    { value: "Czech Republic", display: "Czech Republic"},
    { value: "Côte D'Ivoire", display: "Côte D'Ivoire"},
    { value: "Denmark", display: "Denmark"},
    { value: "Djibouti", display: "Djibouti"},
    { value: "Dominica", display: "Dominica"},
    { value: "Dominican Republic", display: "Dominican Republic"},
    { value: "Ecuador", display: "Ecuador"},
    { value: "Egypt", display: "Egypt"},
    { value: "El Salvador", display: "El Salvador"},
    { value: "Equatorial Guinea", display: "Equatorial Guinea"},
    { value: "Eritrea", display: "Eritrea"},
    { value: "Estonia", display: "Estonia"},
    { value: "Ethiopia", display: "Ethiopia"},
    { value: "Falkland Islands", display: "Falkland Islands"},
    { value: "Faroe Islands", display: "Faroe Islands"},
    { value: "Fiji", display: "Fiji"},
    { value: "Finland", display: "Finland"},
    { value: "France", display: "France"},
    { value: "French Guiana", display: "French Guiana"},
    { value: "French Polynesia", display: "French Polynesia"},
    { value: "French Southern Territories", display: "French Southern Territories"},
    { value: "Gabon", display: "Gabon"},
    { value: "Gambia", display: "Gambia"},
    { value: "Georgia", display: "Georgia"},
    { value: "Germany", display: "Germany"},
    { value: "Ghana", display: "Ghana"},
    { value: "Gibraltar", display: "Gibraltar"},
    { value: "Greece", display: "Greece"},
    { value: "Greenland", display: "Greenland"},
    { value: "Grenada", display: "Grenada"},
    { value: "Guadeloupe", display: "Guadeloupe"},
    { value: "Guam", display: "Guam"},
    { value: "Guatemala", display: "Guatemala"},
    { value: "Guernsey", display: "Guernsey"},
    { value: "Guinea", display: "Guinea"},
    { value: "Guinea-Bissau", display: "Guinea-Bissau"},
    { value: "Guyana", display: "Guyana"},
    { value: "Haiti", display: "Haiti"},
    { value: "Heard and McDonald Islands", display: "Heard and McDonald Islands"},
    { value: "Holy See (Vatican City State)", display: "Holy See (Vatican City State)"},
    { value: "Honduras", display: "Honduras"},
    { value: "Hong Kong", display: "Hong Kong"},
    { value: "Hungary", display: "Hungary"},
    { value: "Iceland", display: "Iceland"},
    { value: "India", display: "India"},
    { value: "Indonesia", display: "Indonesia"},
    { value: "Iran", display: "Iran"},
    { value: "Iraq", display: "Iraq"},
    { value: "Ireland", display: "Ireland"},
    { value: "Isle of Man", display: "Isle of Man"},
    { value: "Israel", display: "Israel"},
    { value: "Italy", display: "Italy"},
    { value: "Jamaica", display: "Jamaica"},
    { value: "Japan", display: "Japan"},
    { value: "Jersey", display: "Jersey"},
    { value: "Jordan", display: "Jordan"},
    { value: "Kazakhstan", display: "Kazakhstan"},
    { value: "Kenya", display: "Kenya"},
    { value: "Kiribati", display: "Kiribati"},
    { value: "North Korea", display: "North Korea"},
    { value: "South Korea", display: "South Korea"},
    { value: "Kuwait", display: "Kuwait"},
    { value: "Kyrgyzstan", display: "Kyrgyzstan"},
    { value: "Laos", display: "Laos"},
    { value: "Latvia", display: "Latvia"},
    { value: "Lebanon", display: "Lebanon"},
    { value: "Lesotho", display: "Lesotho"},
    { value: "Liberia", display: "Liberia"},
    { value: "Libya", display: "Libya"},
    { value: "Liechtenstein", display: "Liechtenstein"},
    { value: "Lithuania", display: "Lithuania"},
    { value: "Luxembourg", display: "Luxembourg"},
    { value: "Macao", display: "Macao"},
    { value: "Republic of Macedonia", display: "Republic of Macedonia"},
    { value: "Madagascar", display: "Madagascar"},
    { value: "Malawi", display: "Malawi"},
    { value: "Malaysia", display: "Malaysia"},
    { value: "Maldives", display: "Maldives"},
    { value: "Mali", display: "Mali"},
    { value: "Malta", display: "Malta"},
    { value: "Marshall Islands", display: "Marshall Islands"},
    { value: "Martinique", display: "Martinique"},
    { value: "Mauritania", display: "Mauritania"},
    { value: "Mauritius", display: "Mauritius"},
    { value: "Mayotte", display: "Mayotte"},
    { value: "Mexico", display: "Mexico"},
    { value: "Federated States of Micronesia", display: "Federated States of Micronesia"},
    { value: "Moldova", display: "Moldova"},
    { value: "Monaco", display: "Monaco"},
    { value: "Mongolia", display: "Mongolia"},
    { value: "Montenegro", display: "Montenegro"},
    { value: "Montserrat", display: "Montserrat"},
    { value: "Morocco", display: "Morocco"},
    { value: "Mozambique", display: "Mozambique"},
    { value: "Myanmar", display: "Myanmar"},
    { value: "Namibia", display: "Namibia"},
    { value: "Nauru", display: "Nauru"},
    { value: "Nepal", display: "Nepal"},
    { value: "Netherlands", display: "Netherlands"},
    { value: "Netherlands Antilles", display: "Netherlands Antilles"},
    { value: "New Caledonia", display: "New Caledonia"},
    { value: "New Zealand", display: "New Zealand"},
    { value: "Nicaragua", display: "Nicaragua"},
    { value: "Niger", display: "Niger"},
    { value: "Nigeria", display: "Nigeria"},
    { value: "Niue", display: "Niue"},
    { value: "Norfolk Island", display: "Norfolk Island"},
    { value: "Northern Mariana Islands", display: "Northern Mariana Islands"},
    { value: "Norway", display: "Norway"},
    { value: "Oman", display: "Oman"},
    { value: "Pakistan", display: "Pakistan"},
    { value: "Palau", display: "Palau"},
    { value: "Palestine", display: "Palestine"},
    { value: "Panama", display: "Panama"},
    { value: "Papua New Guinea", display: "Papua New Guinea"},
    { value: "Paraguay", display: "Paraguay"},
    { value: "Peru", display: "Peru"},
    { value: "Philippines", display: "Philippines"},
    { value: "Pitcairn", display: "Pitcairn"},
    { value: "Poland", display: "Poland"},
    { value: "Portugal", display: "Portugal"},
    { value: "Puerto Rico", display: "Puerto Rico"},
    { value: "Qatar", display: "Qatar"},
    { value: "Romania", display: "Romania"},
    { value: "Russian Federation", display: "Russian Federation"},
    { value: "Rwanda", display: "Rwanda"},
    { value: "Réunion", display: "Réunion"},
    { value: "St. Barthélemy", display: "St. Barthélemy"},
    { value: "St. Helena, Ascension and Tristan Da Cunha", display: "St. Helena, Ascension and Tristan Da Cunha"},
    { value: "St. Kitts And Nevis", display: "St. Kitts And Nevis"},
    { value: "St. Lucia", display: "St. Lucia"},
    { value: "St. Martin", display: "St. Martin"},
    { value: "St. Pierre And Miquelon", display: "St. Pierre And Miquelon"},
    { value: "St. Vincent And The Grenedines", display: "St. Vincent And The Grenedines"},
    { value: "Samoa", display: "Samoa"},
    { value: "San Marino", display: "San Marino"},
    { value: "Sao Tome and Principe", display: "Sao Tome and Principe"},
    { value: "Saudi Arabia", display: "Saudi Arabia"},
    { value: "Senegal", display: "Senegal"},
    { value: "Serbia", display: "Serbia"},
    { value: "Seychelles", display: "Seychelles"},
    { value: "Sierra Leone", display: "Sierra Leone"},
    { value: "Singapore", display: "Singapore"},
    { value: "Slovakia", display: "Slovakia"},
    { value: "Slovenia", display: "Slovenia"},
    { value: "Solomon Islands", display: "Solomon Islands"},
    { value: "Somalia", display: "Somalia"},
    { value: "South Africa", display: "South Africa"},
    { value: "South Georgia and the South Sandwich Islands", display: "South Georgia and the South Sandwich Islands"},
    { value: "Spain", display: "Spain"},
    { value: "Sri Lanka", display: "Sri Lanka"},
    { value: "Sudan", display: "Sudan"},
    { value: "Suriname", display: "Suriname"},
    { value: "Svalbard And Jan Mayen", display: "Svalbard And Jan Mayen"},
    { value: "Swaziland", display: "Swaziland"},
    { value: "Sweden", display: "Sweden"},
    { value: "Switzerland", display: "Switzerland"},
    { value: "Syrian Arab Republic", display: "Syrian Arab Republic"},
    { value: "Taiwan", display: "Taiwan"},
    { value: "Tajikistan", display: "Tajikistan"},
    { value: "Tanzania", display: "Tanzania"},
    { value: "Thailand", display: "Thailand"},
    { value: "Timor-Leste", display: "Timor-Leste"},
    { value: "Togo", display: "Togo"},
    { value: "Tokelau", display: "Tokelau"},
    { value: "Tonga", display: "Tonga"},
    { value: "Trinidad and Tobago", display: "Trinidad and Tobago"},
    { value: "Tunisia", display: "Tunisia"},
    { value: "Turkey", display: "Turkey"},
    { value: "Turkmenistan", display: "Turkmenistan"},
    { value: "Turks and Caicos Islands", display: "Turks and Caicos Islands"},
    { value: "Tuvalu", display: "Tuvalu"},
    { value: "Uganda", display: "Uganda"},
    { value: "Ukraine", display: "Ukraine"},
    { value: "United Arab Emirates", display: "United Arab Emirates"},
    { value: "US Minor Outlying Islands", display: "US Minor Outlying Islands"},
    { value: "Uruguay", display: "Uruguay"},
    { value: "Uzbekistan", display: "Uzbekistan"},
    { value: "Vanuatu", display: "Vanuatu"},
    { value: "Venezuela", display: "Venezuela"},
    { value: "Viet Nam", display: "Viet Nam"},
    { value: "Virgin Islands, British", display: "Virgin Islands, British"},
    { value: "Virgin Islands, U.S.", display: "Virgin Islands, U.S."},
    { value: "Wallis and Futuna", display: "Wallis and Futuna"},
    { value: "Western Sahara", display: "Western Sahara"},
    { value: "Yemen", display: "Yemen"},
    { value: "Zambia", display: "Zambia"},
    { value: "Zimbabwe", display: "Zimbabwe"}
  ]
};

export default countries;
